import React, { ButtonHTMLAttributes, ForwardedRef, MouseEvent } from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_Icon, DA_IconNames } from "../Icon/Icon.component";
import S from "./ButtonLink.module.scss";

export interface DA_ButtonLinkProps
    extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "onClick" | "disabled"> {
    title: string;
    icon?: DA_IconNames;
    onClick?: (event?: MouseEvent<HTMLButtonElement>) => void;
    href?: string;
    className?: string;
    noUnderline?: boolean;
    disabled?: boolean;
}

export const DA_ButtonLink = React.forwardRef(
    (props: DA_ButtonLinkProps, ref: ForwardedRef<HTMLButtonElement>) => {
        const { className, title, icon, onClick, noUnderline, disabled, ...rest } = props;

        return (
            <button
                className={cn(S.buttonLink, noUnderline || S.underlined, className)}
                ref={ref}
                onClick={onClick}
                disabled={disabled}
                {...rest}
            >
                {icon && <DA_Icon name={icon} className={S.icon} />}
                <span className={S.title}>{title}</span>
            </button>
        );
    }
);

DA_ButtonLink.displayName = "DA_ButtonLink";
