import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { B2B_SITE_ORIGIN } from "@danishagro/shared/src/constants/urlRoot.constants";
import { useAppData } from "../../../../../contexts/appData.context";
import { useSiteHeader } from "../../../../../contexts/siteHeader.context";
import { getRelativeRootOnLocalhost } from "../../../../../helpers/getRelativeUrlOnLocalhost.helper";

export const useFarmInTimeButton = () => {
    const { cvrAndCustomerNumbers, customerNumber } = useAppData();
    const { pathname } = useLocation();

    const { showMiniCart, showAccountPicker } = useSiteHeader();

    // Mappers

    const hasOpenMenu = useMemo(
        (): boolean => showMiniCart || showAccountPicker,
        [showMiniCart, showAccountPicker]
    );

    const isFarmInTimePage = useMemo(
        (): boolean => pathname.indexOf("bestillingsliste") >= 0,
        [pathname]
    );

    return {
        showFarmInTime: cvrAndCustomerNumbers && customerNumber,
        farmInTimeLink: `${getRelativeRootOnLocalhost(B2B_SITE_ORIGIN)}/bestillingsliste`,
        isFarmInTimeMenuActive: isFarmInTimePage && !hasOpenMenu,
    };
};
