import React, { useImperativeHandle, useState } from "react";
import Select, { components, GroupBase, MenuListProps, OptionProps } from "react-select";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { SingleValueProps } from "react-select";
import { useScreen } from "@danishagro/shared/src/contexts/screen.context";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { getSelectStyles } from "../../../helpers/getSelectStyles.helper";
import { DA_DropdownArrow } from "../DropdownArrow/DropdownArrow.component";
import { DA_SelectOption, DA_SelectProps } from "./Select.props";
import { useConvertValue } from "./hooks/useConvertValue.hook";
import { useParsedOptions } from "./hooks/useParsedOptions.hook";
import { useSelectedOption } from "./hooks/useSelectedOption.hook";
import S from "./Select.module.scss";

export const DA_Select = <T,>({
    options,
    value,
    onChange,
    getValue,
    getLabel,
    getExtra,
    prefix,
    postfix,
    methodsRef,
    disableOptionStyling,
    ...restProps
}: DA_SelectProps<T>) => {
    const { isMobile } = useScreen();
    const [isOpen, setIsOpen] = useState(false);
    const { getDictionaryString } = useTranslations();
    const convertValue = useConvertValue({ options, getLabel, getValue });
    const parsedOptions = useParsedOptions({ options, getLabel, getValue, getExtra });
    const selectedOption = useSelectedOption({ parsedOptions, value });
    const hasGroupedOptions =
        typeof (parsedOptions as unknown as GroupBase<T>)[0]?.options !== "undefined";

    const option = (data: OptionProps<DA_SelectOption>) => (
        <components.Option {...data}>
            <div>{data.data.label}</div>
            {data.data.extra ? (
                React.isValidElement(data.data.extra) ? (
                    data.data.extra
                ) : (
                    <div className={S.extraText}>{data.data.extra}</div>
                )
            ) : null}
        </components.Option>
    );

    const SingleValue = (props: SingleValueProps<DA_SelectOption>) => (
        <components.SingleValue {...props} className={S.singleValue}>
            {restProps.inlineLabel ? (
                <div className={S.helperWrapper}>
                    <span>{restProps.selectName}: </span>
                    <span className={S.helperValue}>{props.children}</span>
                </div>
            ) : (
                props.children
            )}
        </components.SingleValue>
    );

    const menuList = (props: MenuListProps) => (
        <components.MenuList className={disableOptionStyling && S.disableOptionStyling} {...props}>
            {React.isValidElement(prefix) ? prefix : null}

            {props.children}

            {React.isValidElement(postfix) ? postfix : null}
        </components.MenuList>
    );

    useImperativeHandle(methodsRef, () => ({ close: () => setIsOpen(false) }), []);

    return (
        <div
            className={cn(
                S.wrapper,
                restProps.disabled && S.disabled,
                restProps.fullWidth && S.fullWidth,
                restProps.className
            )}
        >
            <Select<DA_SelectOption>
                inputId={restProps.id}
                name={restProps.name}
                maxMenuHeight={restProps.maxMenuHeight ?? 300}
                aria-label={restProps.ariaLabel}
                defaultValue={convertValue(restProps.defaultValue)}
                tabIndex={restProps.tabIndex}
                isDisabled={restProps.disabled}
                styles={getSelectStyles({
                    ...restProps,
                    isMobile: isMobile,
                    isGrouped: hasGroupedOptions,
                })}
                menuPortalTarget={
                    restProps.menuPortalTarget === null
                        ? null
                        : restProps.menuPortalTarget || document.body
                }
                menuPlacement="auto"
                isSearchable={restProps.isSearchable || false}
                placeholder={restProps.placeholder}
                value={selectedOption}
                options={parsedOptions}
                formatGroupLabel={({ label }) => <div className={S.groupLabel}>{label}</div>}
                isOptionDisabled={(option) => option.disabled}
                getOptionLabel={({ label }) =>
                    (React.isValidElement(label) ? label : `${label}`) as string
                }
                getOptionValue={({ value }) => `${value}`}
                components={{
                    SingleValue,
                    DropdownIndicator: (data) => (
                        <>
                            {restProps.inlineLabel && (
                                <span className={S.variantText}>
                                    {`${restProps.variantCount} ${getDictionaryString("variants")}`}
                                </span>
                            )}

                            <DA_DropdownArrow {...data} small={restProps.small} />
                        </>
                    ),
                    IndicatorSeparator: () => null,
                    Option: option,
                    MenuList: menuList,
                }}
                menuIsOpen={isOpen}
                onMenuOpen={() => setIsOpen(true)}
                onMenuClose={() => setIsOpen(false)}
                onChange={(newSelection) =>
                    onChange(
                        // @ts-ignore
                        hasGroupedOptions
                            ? (options as GroupBase<DA_SelectOption>[])
                                  .find((group) =>
                                      group.options.find(
                                          (option) =>
                                              // @ts-ignore
                                              (getValue ? getValue(option as T) : option.value) ===
                                              newSelection.value
                                      )
                                  )
                                  .options.find(
                                      (option) =>
                                          // @ts-ignore
                                          (getValue ? getValue(option as T) : option.value) ===
                                          newSelection.value
                                  )
                            : (options as DA_SelectOption[]).find(
                                  (option) =>
                                      // @ts-ignore
                                      (getValue ? getValue(option as T) : option.value) ===
                                      newSelection.value
                              )
                    )
                }
            />
        </div>
    );
};
