import {
    GET_B2B_SITE_ORIGIN,
    GET_CMS_SITE_ORIGIN,
    GET_MYFARM_SITE_ORIGIN,
    GET_ECOM_SITE_ORIGIN,
} from "@danishagro/shared/src/helpers/urlRoot.helper";

/** SITE ORIGINS  */

export const MYFARM_SITE_ORIGIN = GET_MYFARM_SITE_ORIGIN();
export const B2B_SITE_ORIGIN = GET_B2B_SITE_ORIGIN();
export const CMS_SITE_ORIGIN = GET_CMS_SITE_ORIGIN();
export const ECOM_SITE_ORIGIN = GET_ECOM_SITE_ORIGIN();

/** API ROOTS */

export const API_GATEWAY_ROOT = (
    globalThis.apiGatewayUrl
        ? globalThis.apiGatewayUrl
        : location.hostname === "localhost" || location.hostname === "127.0.0.1"
        ? "https://myfarm-api-dev.danishagro.com/api"
        : ""
).replace(/\/$/g, "");

// OLD B2B_API_ROOT
// https://app-b2bshop-api-dev.azurewebsites.net

// NEW B2B_API_ROOT
// https://app-apigateway-dev.azurewebsites.net

export const B2B_API_ROOT = (
    globalThis.apiGatewayUrl
        ? globalThis.apiGatewayUrl
        : location.hostname === "localhost" || location.hostname === "127.0.0.1"
        ? "https://app-apigateway-dev.azurewebsites.net/api"
        : ""
).replace(/\/$/g, "");

export const CMS_API_ROOT = (
    globalThis.cmsApiUrl
        ? globalThis.cmsApiUrl
        : location.hostname === "localhost" || location.hostname === "127.0.0.1"
        ? "https://webshop-danishagro-dk.euwest01.umbraco.io/"
        : ""
).replace(/\/$/g, "");

export const CMS_CDN_ROOT = (globalThis.cmsCdnUrl ? globalThis.cmsCdnUrl : "").replace(/\/$/g, "");

export const ECOM_API_ROOT = (
    globalThis.b2bUmbracoUrl
        ? globalThis.b2bUmbracoUrl
        : location.hostname === "localhost" || location.hostname === "127.0.0.1"
        ? "https://webshop-danishagro-dk.euwest01.umbraco.io/"
        : ""
).replace(/\/$/g, "");
