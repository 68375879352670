import { useMemo } from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import S from "./usePadding.module.scss";

export const usePadding = (isCompact: boolean, addCompactPadding?: boolean) => {
    const paddingClass = useMemo(() => {
        if (isCompact === true && addCompactPadding === false) return undefined;

        const isCompactClass = isCompact && S.blockPaddingCompact;
        return cn(S.blockPadding, isCompactClass);
    }, [isCompact, addCompactPadding]);

    return {
        paddingClass,
    };
};
