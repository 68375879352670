import React from "react";
import { getAbsoluteUrl } from "@helpers/getAbsoluteUrl.helper";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import { DA_Title } from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { ImageViewer } from "../../../../../../components/ImageViewer/ImageViewer.component";
import { CardProps } from "./Card.interface";
// eslint-disable-next-line css-modules/no-unused-class
import S from "./Card.module.scss";

export const Card = (props: CardProps) => {
    const { getDictionaryString } = useTranslations();

    return (
        <div className={S.item}>
            {/** Image */}
            {props.thumbnail ? (
                <div className={S.image}>
                    <ImageViewer
                        className={S.image}
                        src={getAbsoluteUrl(props.thumbnail?.url)}
                        aspectRatioX={1}
                        aspectRatioY={1}
                    />
                </div>
            ) : null}

            <div className={S.textWrapper}>
                {/** Title */}
                <div>
                    <DA_Title h3 noMargin>
                        {props.title}
                    </DA_Title>
                </div>
                {/** Text */}
                {props.introductory ? (
                    <div className={S.introductory}>
                        <DA_Text noPadding html={props.introductory} />
                    </div>
                ) : null}

                <div className={S.addressWrapper}>
                    {/** Date*/}
                    {props.startDate ? (
                        <span>
                            <DA_Icon name={DA_IconNames.Calendar} className={S.icon} />
                            {props.startDate}
                        </span>
                    ) : null}

                    {/** Time*/}
                    {props.time ? (
                        <span>
                            <DA_Icon name={DA_IconNames.Clock} className={S.icon} />
                            {props.time}
                        </span>
                    ) : null}

                    {/** address*/}
                    {props.address || props.zipcode || props.city ? (
                        <span>
                            <DA_Icon name={DA_IconNames.Pin} className={S.icon} />
                            {props.address}, {props.zipcode} {props.city}
                        </span>
                    ) : null}
                </div>

                {/** Button */}
                {/*// Events open in new tab*/}
                <div>
                    {props.hasContent ? (
                        <DA_Button
                            title={getDictionaryString("General.Read")}
                            href={props.url}
                            target="_blank"
                        />
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default Card;
