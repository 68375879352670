import React, { useEffect, useRef, useState } from "react";
import { DA_Container } from "@danishagro/shared/src/components/atoms/Container/Container.component";
import { DA_Modal } from "@danishagro/shared/src/components/molecules/Modal/Modal.component";
import { ModalSize, useModal } from "@danishagro/shared/src/contexts/modal.context";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import S from "./headerMessage.module.scss";

type Banner = {
    id: number;
    headline: string;
    content: string;
    onClick?: () => void;
};

type HeaderMessageProps = {
    activeStickyBanners: Banner[];
    showModal?: (node: React.ReactNode, options?: object) => void;
};

export const DA_HeaderMessage = ({ activeStickyBanners }: HeaderMessageProps) => {
    const { showModal, closeModal } = useModal();
    const { getDictionaryString } = useTranslations();
    const textRefs = useRef([]);
    const [overflowingBanners, setOverflowingBanners] = useState([]);

    // Function to check if the content is overflowing its container
    const isOverflowing = (element) => {
        return element.scrollWidth > element.clientWidth;
    };

    // Effect to determine which banners are overflowing
    useEffect(() => {
        const overflowingBannerIds = textRefs.current.reduce((acc, textRef, index) => {
            if (textRef && isOverflowing(textRef)) {
                acc.push(activeStickyBanners[index].id);
                textRef.classList.add(S.isOverflowing);
            }
            return acc;
        }, []);

        setOverflowingBanners(overflowingBannerIds);
    }, [activeStickyBanners]);

    if (!activeStickyBanners || activeStickyBanners.length === 0) return null;

    const handleKeyPress = (event: React.KeyboardEvent, banner: Banner) => {
        if (event.key === "Enter" || event.key === " ") {
            handleBannerClick(banner);
        }
    };

    const handleBannerClick = (banner: Banner) => {
        showModal(
            <DA_Modal title={banner.headline}>
                <span
                    className={S.messageTextModal}
                    dangerouslySetInnerHTML={{
                        __html: banner.content,
                    }}
                />
                <DA_Button
                    className={S.closeModalBtn}
                    title={getDictionaryString("OK")}
                    onClick={() => {
                        closeModal();
                    }}
                />
            </DA_Modal>,
            {
                size: ModalSize.MD,
            }
        );
    };

    return (
        <>
            {activeStickyBanners.map((banner, index) => (
                <div className={S.desktopMessage} key={banner.id}>
                    <DA_Container noVerticalPadding>
                        <div className={S.desktopMessageInfo}>
                            <span className={S.messageTitle}>{banner.headline}</span>
                            <span
                                ref={(el) => (textRefs.current[index] = el)}
                                className={S.messageText}
                                dangerouslySetInnerHTML={{
                                    __html: banner.content,
                                }}
                            />
                            {overflowingBanners.includes(banner.id) && (
                                <div
                                    className={S.readMoreContainer}
                                    onClick={() => handleBannerClick(banner)}
                                    onKeyDown={(e) => handleKeyPress(e, banner)}
                                    role="button"
                                    tabIndex={0}
                                >
                                    {/* Replace with your actual icon component or element */}
                                    <DA_Icon
                                        name={DA_IconNames.ExternalLink}
                                        className={S.readMoreIcon}
                                        useSvgDimensions
                                    />
                                    <span>{getDictionaryString("message.readMore")}</span>
                                </div>
                            )}
                        </div>
                    </DA_Container>
                </div>
            ))}
        </>
    );
};
