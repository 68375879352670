import React from "react";
import { DropdownIndicatorProps } from "react-select";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_Icon, DA_IconNames } from "../Icon/Icon.component";
import S from "./DropdownArrow.module.scss";

export interface DA_DropdownArrowProps extends Partial<DropdownIndicatorProps> {
    small?: boolean;
    expanded?: boolean;
}

export const DA_DropdownArrow = (props: DA_DropdownArrowProps) => (
    <div
        className={cn(
            S.wrapper,
            props.small && S.small,
            props.isDisabled && S.disabled,
            (props.expanded || props.selectProps?.menuIsOpen) && S.expanded
        )}
    >
        <div className={S.arrowBox}>
            <DA_Icon name={DA_IconNames.ChevronSmallDown} className={S.arrow} />
        </div>
    </div>
);
