import React from "react";
import reactStringReplace from "react-string-replace";
import { DA_BasicLink } from "../../../../atoms/BasicLink/BasicLink.component";
import { CMS_SITE_ORIGIN } from "../../../../../constants/urlRoot.constants";
import { DA_NavigationPageSimple } from "../../../../../interfaces/navigationPage.interface";
import S from "./SearchPage.module.scss";

export const _SearchPage = ({
    highlightedString,
    onResultClicked,
    ...props
}: DA_NavigationPageSimple & { highlightedString: string; onResultClicked: () => void }) => {
    return (
        <DA_BasicLink
            onClick={onResultClicked}
            href={`${CMS_SITE_ORIGIN}${props.url}`}
            target="_self"
            className={S.link}
        >
            <div className={S.data}>
                <div className={S.details}>
                    {reactStringReplace(
                        props.name,
                        new RegExp(`(${highlightedString})`, "ig"),
                        (match, index) => (
                            <b key={index}>{match}</b>
                        )
                    )}
                </div>
            </div>
        </DA_BasicLink>
    );
};
