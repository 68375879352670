import React, { useCallback, useEffect, useState } from "react";
import {
    ProductSearchBuilder,
    SelectedProductPropertiesSettings,
    Settings,
    ProductCategorySearchResponse,
    ProductCategorySearchBuilder,
    ProductSearchResponse,
    Searcher,
    UserFactory,
    ProductResult,
    Recommender,
    Tracker,
    ProductFacetResult,
} from "@relewise/client";
import { RELEWISE } from "@danishagro/shared/src/constants/relewise.constants";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { twoLetterIsoLanguage } from "@danishagro/shared/src/helpers/culture.helper";
import { DA_NewProductSimple } from "@danishagro/shared/src/interfaces/productv2.interface";
import { usePage } from "@danishagro/shared/src/contexts/page.context";
import { useSearchParams } from "react-router-dom";

export class Dataset {
    datasetId: string;
    apiKey: string;
    language: string;
    currencyCode: string;
    displayName?: string;
    serverUrl?: string;

    constructor(
        datasetId?: string,
        apiKey?: string,
        language?: string,
        currencyCode?: string,
        displayName?: string,
        serverUrl?: string
    ) {
        this.datasetId = datasetId ?? "";
        this.displayName = displayName;
        this.apiKey = apiKey ?? "";
        this.language = language ?? "";
        this.currencyCode = currencyCode ?? "";
        this.serverUrl = serverUrl;
    }
}

interface RelewiseHook {
    getSelectedDataset: () => Dataset;
    getSearcher: () => Searcher;
    getTracker: () => Tracker;
    getRecommender: () => Recommender;
    getProductSettings: () => SelectedProductPropertiesSettings;
    getDefaultSettings: () => Settings;
    isConfigured: () => boolean;
    fetchProducts: (
        currentPage?: number,
        currentPageSize?: number
    ) => Promise<ProductSearchResponse>;
    getCategory: (categoryId: string) => Promise<ProductCategorySearchResponse>;
    categoryId: string;
    setCategoryId: (categoryId: string) => void;
    page: number;
    pageCount: number;
    setPage: (newPage: number) => void;
    pageSize: number;
    initialDefaultPageSize: number;
    initialQuickOrderPageSize: number;
    setPageSize: (pageSize: number) => void;
    facetGroups: string[];
    setFacetGroups: (facetGroups: string[]) => void;
    selectedFacets: Record<string, string[]>;
    setSelectedFacets: (facetValues: Record<string, string[]>) => void;
    removeFilter: (facetKey: string, facetValue: string) => void;
    resetFilters: () => void;
    relewiseUser: () => UserFactory;
    allowTracking: () => boolean;

    sortingOptions: string[];
    selectedSortingOption: string;
    setSelectedSortingOption: (sortingOption: string) => void;

    products: DA_NewProductSimple[];
    totalProductCount: number;

    facets: ProductFacetResult;

    handleProductResponse: (reponse: ProductSearchResponse, aggregateProduct?: boolean) => void;
    mapProduct: (product: ProductResult, currentCategoryId?: string) => DA_NewProductSimple;
    mapProductSearchResponse: (
        productSearchResponse: ProductSearchResponse,
        currentCategoryId: string
    ) => DA_NewProductSimple[];
}

const RelewiseContext = React.createContext<RelewiseHook>({
    getSelectedDataset: () => null,
    getSearcher: () => null,
    getTracker: () => null,
    getRecommender: () => null,
    getProductSettings: () => null,
    getDefaultSettings: () => null,
    isConfigured: () => null,
    fetchProducts: () => null,
    getCategory: () => null,
    page: null,
    pageCount: null,
    setPage: () => null,
    categoryId: null,
    setCategoryId: () => null,
    pageSize: null,
    initialDefaultPageSize: null,
    initialQuickOrderPageSize: null,
    setPageSize: () => null,
    facetGroups: null,
    setFacetGroups: () => null,
    selectedFacets: null,
    setSelectedFacets: () => null,
    removeFilter: () => null,
    resetFilters: () => null,
    relewiseUser: () => null,

    allowTracking: () => null,

    sortingOptions: null,
    selectedSortingOption: null,
    setSelectedSortingOption: () => null,

    products: null,
    totalProductCount: null,
    facets: null,
    handleProductResponse: () => null,

    mapProduct: () => null,
    mapProductSearchResponse: () => null,
});

type Props = {
    paginationPageSize?: number;
    children: React.ReactNode;
};

export enum Sort {
    Recommended = "Recommended",
    Popular = "Popular",
    AlphabeticalAsc = "AlphabeticalAsc",
    AlphabeticalDesc = "AlphabeticalDesc",
}

const initialSortingOption = Sort.Popular;
const queryStringSortParamKey = "sortBy";

export const RelewiseProvider = ({ paginationPageSize, children }: Props): JSX.Element => {
    const [initialDefaultPageSize] = useState<number>(24);
    const [initialQuickOrderPageSize] = useState<number>(200);
    const [pageSize, setPageSize] = useState<number>(paginationPageSize || initialDefaultPageSize);
    const [page, setPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(0);
    const [categoryId, setCategoryId] = useState<string>(undefined);
    const [totalProductCount, setTotalProductCount] = useState<number>(0);
    const [facetGroups, setFacetGroups] = useState<string[]>(null);
    const [products, setProducts] = useState<DA_NewProductSimple[]>([]);
    const [facets, setFacets] = useState<ProductFacetResult>(undefined);

    const [dataSet, setDataSet] = useState<Dataset>(undefined);
    const {
        currentCulture,
        customerNumber,
        cvrAndCustomerNumbers,
        isAdmin,
        isMasterAdmin,
        isImpersonator,
    } = useAppData();
    const { getCategoryPathFromId } = usePage();

    const [selectedSortingOption, setSelectedSortingOption] = useState<string>(() => {
        const params = new URLSearchParams(location.search);
        return params.has(queryStringSortParamKey)
            ? params.get(queryStringSortParamKey)
            : initialSortingOption;
    });

    const [sortingOptions] = useState<string[]>([
        Sort.Recommended,
        Sort.Popular,
        Sort.AlphabeticalAsc,
        Sort.AlphabeticalDesc,
    ]);

    const [selectedFacets, setSelectedFacets] = useState<Record<string, string[]>>({});
    const [searchParams] = useSearchParams();

    if (!dataSet) {
        setDataSet(
            new Dataset(
                RELEWISE.datasetId,
                RELEWISE.apiKey,
                twoLetterIsoLanguage(currentCulture),
                "DKK",
                RELEWISE.datasetName,
                RELEWISE.serverUrl
            )
        );
    }

    const removeFilter = useCallback(
        (filterKey: string, filterValue: string) => {
            const tempFacets = { ...selectedFacets };
            setSelectedFacets(null);

            const index = tempFacets[filterKey].indexOf(filterValue);
            tempFacets[filterKey].splice(index, 1);
            setSelectedFacets(tempFacets);
        },
        [selectedFacets]
    );

    const resetFilters = useCallback(() => {
        const selectedFilters: Record<string, string[]> = {};

        if (facetGroups?.length) {
            facetGroups.map((group) => {
                selectedFilters[group] = [];
            });
        }

        setSelectedFacets(selectedFilters);
    }, [facetGroups]);

    const getSelectedDataset = useCallback(() => {
        if (!dataSet) {
            console.error("Dataset not correctly initialized");
        }
        return dataSet;
    }, [dataSet]);

    const isConfigured = useCallback(() => {
        const selectedDataset = getSelectedDataset();
        if (
            !selectedDataset.apiKey ||
            !selectedDataset.datasetId ||
            !selectedDataset.currencyCode ||
            !selectedDataset.language
        ) {
            console.error("Dataset not correctly configured");
            return false;
        }

        return true;
    }, [getSelectedDataset]);

    const getRecommender = useCallback(() => {
        const selectedDataset = getSelectedDataset();

        return new Recommender(selectedDataset.datasetId, selectedDataset.apiKey, {
            serverUrl: selectedDataset.serverUrl,
        });
    }, [getSelectedDataset]);

    const getSearcher = useCallback(() => {
        const selectedDataset = getSelectedDataset();
        return new Searcher(selectedDataset.datasetId, selectedDataset.apiKey, {
            serverUrl: selectedDataset.serverUrl,
        });
    }, [getSelectedDataset]);

    const getTracker = useCallback(() => {
        const selectedDataset = getSelectedDataset();

        return new Tracker(selectedDataset.datasetId, selectedDataset.apiKey, {
            serverUrl: selectedDataset.serverUrl,
        });
    }, [getSelectedDataset]);

    const getProductSettings = useCallback(() => {
        return {
            displayName: true,
            allData: true,
            brand: true,
            categoryPaths: true,
            pricing: true,
            allVariants: true,
        } as SelectedProductPropertiesSettings;
    }, []);

    const getDefaultSettings = useCallback(() => {
        if (!isConfigured()) {
            throw new Error("Missing language or currencycode");
        }

        const dataset = getSelectedDataset();
        // TODO: Change location and user

        return {
            language: dataset.language,
            currency: dataset.currencyCode,
            displayedAtLocation: "Product Listing Page",
            user: UserFactory.anonymous(),
        };
    }, [getSelectedDataset, isConfigured]);

    const fetchProducts = (currentPage?: number, currentPageSize?: number) => {
        const builder = new ProductSearchBuilder(getDefaultSettings())
            .setSelectedProductProperties(getProductSettings())
            .setSelectedVariantProperties({ allData: true })
            .setExplodedVariants(1)
            .facets((f) => {
                facetGroups?.map((facetGroup) => {
                    const facetValues = selectedFacets ? selectedFacets[facetGroup] : [];
                    f.addProductDataStringValueFacet(
                        facetGroup,
                        "ProductWithFallbackToVariant",
                        facetValues
                    );
                });
            })
            .filters((f) => {
                f.addProductCategoryIdFilter("Ancestor", [categoryId]);
            })
            .pagination((p) =>
                p
                    .setPageSize(currentPageSize ? currentPageSize : pageSize)
                    .setPage(currentPage || page)
            )
            .sorting((s) => {
                s.sortByProductPopularity();

                switch (selectedSortingOption) {
                    case Sort.Popular: {
                        s.sortByProductPopularity();
                        break;
                    }
                    case Sort.Recommended: {
                        s.sortByProductRelevance();
                        break;
                    }
                    case Sort.AlphabeticalAsc: {
                        s.sortByProductAttribute("DisplayName", "Ascending");
                        break;
                    }
                    case Sort.AlphabeticalDesc: {
                        s.sortByProductAttribute("DisplayName", "Descending");
                        break;
                    }
                    default: {
                        break;
                    }
                }
            });

        return getSearcher().searchProducts(builder.build());
    };

    const getCategory = useCallback(
        (categoryId: string) => {
            const builder = new ProductCategorySearchBuilder(getDefaultSettings())
                .setSelectedCategoryProperties({ displayName: true })
                .filters((f) =>
                    f.addProductCategoryIdFilter("ImmediateParentOrItsParent", [categoryId])
                );

            return getSearcher().searchProductCategories(builder.build());
        },
        [getDefaultSettings, getSearcher]
    );

    // Set user based on authentication
    const relewiseUser = useCallback(() => {
        return customerNumber
            ? UserFactory.byAuthenticatedId(cvrAndCustomerNumbers.userId)
            : UserFactory.anonymous();
    }, [customerNumber, cvrAndCustomerNumbers.userId]);

    // Allow tracking if not admin, impersonator or master admin
    const allowTracking = useCallback(() => {
        if (isAdmin || isImpersonator || isMasterAdmin) {
            return false;
        }
        return true;
    }, [isAdmin, isImpersonator, isMasterAdmin]);

    // TODO: move mappers to new helper
    // -------------------------------------------------------
    // Mapping Relewise data types
    // -------------------------------------------------------
    const getProductUrl = useCallback(
        (product: ProductResult, currentCategoryId) => {
            if (!product.categoryPaths?.length) {
                return undefined;
            }

            const leafCategories = product.categoryPaths?.map(
                (cp) => cp.pathFromRoot.reduce((_acc, current) => current).id
            );

            const foundInLeafCategory = leafCategories.findIndex(
                (cat) => cat === currentCategoryId
            );

            // Id is found in leaf categoryPaths
            // Use it to stay in correct context (as product can be present in many categories)
            if (foundInLeafCategory > 0) {
                return `${getCategoryPathFromId(leafCategories[foundInLeafCategory])}/${
                    product.data?.webNameSlug?.value
                }`;
            }

            // Id is found in leaf category tree
            // Use it to stay in context as much as possible
            const foundInCategoryTree = product.categoryPaths?.find((cp) =>
                cp.pathFromRoot.find((p) => p.id === currentCategoryId)
            );

            if (foundInCategoryTree?.pathFromRoot?.length) {
                const treeCategoryId = foundInCategoryTree.pathFromRoot.reduce(
                    (_acc, current) => current
                ).id;
                return `${getCategoryPathFromId(treeCategoryId)}/${
                    product.data?.webNameSlug?.value
                }`;
            }

            // Id not found in either leaf category or category tree.
            // Just fallback to canonicalBaseUrl
            return `${product.data?.canonicalBaseUrl?.value}/${product.data?.webNameSlug?.value}`;
        },
        [getCategoryPathFromId]
    );

    const mapProduct = useCallback(
        (product: ProductResult, currentCategoryId?: string): DA_NewProductSimple => {
            // TODO: Delete categoryId
            return {
                webName: product.displayName,
                displayName: product.displayName,
                entityId: product.productId,
                variantEntityId: product.variant?.variantId ?? "", // Ensure a default value if variantId is null
                hasVariants: product.allVariants?.length > 1,
                quantityOnPallet: product?.data?.quantityOnPallet,

                masterProductNumber: product.data?.masterProductNumber?.value, // TODO: missing data from Relewise
                productNumber: product.data?.masterProductNumber?.value, // TODO: missing data from Relewise

                hasHazardStatement: product.data?.hazardStatement?.value?.length > 0,

                retailVariantId: "", // TODO: missing data
                newProduct: false, // TODO: missing data

                imageUrl: product.data?.mediaUrl?.value ?? "", // Ensure a default value if mediaUrl is null
                url: getProductUrl(product, currentCategoryId),
                productUrl: `${product.data?.canonicalBaseUrl?.value}/${product.data?.webNameSlug?.value}`,
                data: product.data,

                // TODO: Delete temp prop
                relewiseProduct: product,
            };
        },
        [getProductUrl]
    );

    const mapProductSearchResponse = useCallback(
        (
            productSearchResponse: ProductSearchResponse,
            currentCategoryId: string
        ): DA_NewProductSimple[] => {
            return productSearchResponse.results?.map((product) =>
                mapProduct(product, currentCategoryId)
            );
        },
        [mapProduct]
    );

    useEffect(() => {
        // Set initial facet selection - prepare records with page facets and QS params
        if (facetGroups?.length) {
            const selectedFilters: Record<string, string[]> = {};
            facetGroups.map((group) => {
                selectedFilters[group] = searchParams.has(group)
                    ? searchParams.get(group).split(",")
                    : [];
            });
            setSelectedFacets(selectedFilters);
        }
    }, [facetGroups, searchParams]);

    const generateFacetQueryString = useCallback(() => {
        const params = new URLSearchParams(location.search);
        // const params = new URLSearchParams(searchParams.toString());

        Object.keys(selectedFacets).map((facet) => {
            if (selectedFacets[facet].length) {
                params.set(facet, selectedFacets[facet].toString());
            } else {
                params.delete(facet);
            }
        });

        if (selectedSortingOption !== initialSortingOption) {
            params.set(queryStringSortParamKey, selectedSortingOption);
        } else {
            params.delete(queryStringSortParamKey);
        }

        return params;
    }, [selectedFacets, selectedSortingOption]);

    const updateSearchParams = (params: URLSearchParams) => {
        const newUrl = [window.location.pathname, params.toString()].filter(Boolean).join("?");

        // alright, let's talk about this...
        // Normally you'd update the params via useSearchParams from react-router-dom
        // and updating the search params will trigger the search to update for you.
        // However, it also triggers a navigation to the new url, which will trigger
        // the loader to run which we do not want because all our data is already
        // on the client and we're just doing client-side filtering of data we
        // already have. So we manually call `window.history.pushState` to avoid
        // the router from triggering the loader.

        window.history.replaceState(null, "", newUrl);
    };

    const handleSearchParams = useCallback(
        (): Promise<void> =>
            new Promise((resolve) => {
                // Use custom param handling to avoid double requests to relewise on facets
                updateSearchParams(generateFacetQueryString());
                return resolve(null);
            }),
        [generateFacetQueryString]
    );

    const handleProductResponse = useCallback(
        (response: ProductSearchResponse, aggregateProduct?: boolean) => {
            setPageCount(Math.ceil(response?.hits / pageSize));
            setTotalProductCount(response?.hits);
            setFacets(response.facets);
            handleSearchParams();

            if (aggregateProduct) {
                // Aggregate products instead of just setting them - to support infinite scroll
                setProducts((prevState) => [
                    ...prevState,
                    ...mapProductSearchResponse(response, categoryId),
                ]);
            } else {
                setPage(1);
                // Sorting or facets changed, so replace products
                setProducts(mapProductSearchResponse(response, categoryId));
            }
        },
        [categoryId, handleSearchParams, mapProductSearchResponse, pageSize]
    );

    return (
        <RelewiseContext.Provider
            value={{
                getSelectedDataset,
                getDefaultSettings,
                getProductSettings,
                getSearcher,
                getTracker,
                getRecommender,
                isConfigured,
                fetchProducts,
                getCategory,
                page,
                pageCount,
                setPage,
                categoryId,
                setCategoryId,
                pageSize,
                initialDefaultPageSize,
                initialQuickOrderPageSize,
                setPageSize,
                facetGroups,
                setFacetGroups,
                selectedFacets,
                setSelectedFacets,
                removeFilter,
                resetFilters,

                sortingOptions,
                selectedSortingOption,
                setSelectedSortingOption,

                products,
                totalProductCount,
                facets,
                handleProductResponse,

                mapProduct,
                mapProductSearchResponse,
                relewiseUser,

                allowTracking,
            }}
        >
            {children}
        </RelewiseContext.Provider>
    );
};

export const useRelewise = (): RelewiseHook => React.useContext(RelewiseContext);
