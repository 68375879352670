import { useCallback } from "react";
import { DA_SelectOption, DA_SelectProps } from "../Select.props";

type Props<T> = Pick<DA_SelectProps<T>, "getLabel" | "getValue" | "getExtra">;

export const useConvertOption = <T>({ getLabel, getValue, getExtra }: Props<T>) =>
    useCallback(
        (option: T): DA_SelectOption =>
            option
                ? {
                      // @ts-ignore
                      value: getValue ? getValue(option) : option.value,
                      // @ts-ignore
                      label: getLabel ? getLabel(option) : option.label,
                      // @ts-ignore
                      extra: getExtra ? getExtra(option) : option.extra,
                      // @ts-ignore
                      disabled: option.disabled,
                  }
                : undefined,
        [getValue, getLabel, getExtra]
    );
