import { useState, useEffect, useCallback } from "react";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import { MessageState, CookieValue } from "@danishagro/shared/src/content/message/messageEnums";

export const useModalMessage = (allMessages) => {
    const [currentMessage, setCurrentMessage] = useState(null);
    const location = useLocation();

    const COOKIE_NAME_PREFIX = "modalMessageShown_";

    const hasMessageBeenShown = useCallback((messageId) => {
        return Cookies.get(`${COOKIE_NAME_PREFIX}${messageId}`) === CookieValue.True;
    }, []);

    const findNextMessageToShow = useCallback(() => {
        const currentUrl = window.location.href;
        const now = new Date();
        return allMessages?.find((msg) => {
            const isActive = msg?.messageState === MessageState.Active;
            const isInDateRange =
                new Date(msg?.activeFrom) <= now && now <= new Date(msg?.activeTo);
            const isModal = msg?.type === "Modal";
            const isNotDeactivated = !msg?.deactivated;
            const notYetShown = !hasMessageBeenShown(msg?.id);
            const isUrlMatch = msg?.urls.some((urlPattern) => {
                if (urlPattern.endsWith("*")) {
                    const basePattern = urlPattern.slice(0, -1);
                    return currentUrl.startsWith(basePattern);
                }
                return currentUrl === urlPattern;
            });
            return (
                isActive &&
                isInDateRange &&
                isNotDeactivated &&
                notYetShown &&
                isUrlMatch &&
                isModal
            );
        });
    }, [allMessages, hasMessageBeenShown]);

    useEffect(() => {
        const messageToShow = findNextMessageToShow();
        if (messageToShow) {
            setCurrentMessage(messageToShow);
        }
    }, [findNextMessageToShow, location]);

    const handleModalClose = useCallback(
        (messageId) => {
            Cookies.set(`${COOKIE_NAME_PREFIX}${messageId}`, CookieValue.True, { expires: 1 });
            setCurrentMessage(null);
            setTimeout(() => {
                setCurrentMessage(findNextMessageToShow());
            }, 0);
        },
        [findNextMessageToShow]
    );

    return { currentMessage, handleModalClose };
};
