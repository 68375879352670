export const GET_MYFARM_SITE_ORIGIN = (type: "RELATIVE" | "ABSOLUTE" = "RELATIVE") => {
    let url = globalThis.myfarmFrontendUrl;

    if (type === "RELATIVE") {
        url = url?.replace(window?.location?.origin, "");
    }

    return (
        url ||
        (location.hostname === "localhost" || location.hostname === "127.0.0.1"
            ? "https://myfarm-dev.danishagro.com"
            : "")
    ).replace(/\/$/, "");
};

export const GET_B2B_SITE_ORIGIN = (type: "RELATIVE" | "ABSOLUTE" = "RELATIVE") => {
    let url = globalThis.b2bFrontendUrl;

    if (type === "RELATIVE") {
        url = url?.replace(window?.location?.origin, "");
    }

    return (
        url ||
        (location.hostname === "localhost" || location.hostname === "127.0.0.1"
            ? "https://b2bshop-dev.danishagro.com"
            : "")
    ).replace(/\/$/, "");
};

export const GET_CMS_SITE_ORIGIN = (type: "RELATIVE" | "ABSOLUTE" = "RELATIVE") => {
    let url = globalThis.cmsFrontendUrl;

    if (type === "RELATIVE") {
        url = url?.replace(window?.location?.origin, "");
    }

    return (
        url ||
        (location.hostname === "localhost" || location.hostname === "127.0.0.1"
            ? "https://app-danishagrobasesite-dev.azurewebsites.net"
            : "")
    ).replace(/\/$/, "");
};

export const GET_ECOM_SITE_ORIGIN = (type: "RELATIVE" | "ABSOLUTE" = "RELATIVE") => {
    let url = globalThis.b2bUmbracoUrl;

    if (type === "RELATIVE") {
        url = url?.replace(window?.location?.origin, "");
    }

    return (
        url ||
        (location.hostname === "localhost" || location.hostname === "127.0.0.1"
            ? "https://webshop-danishagro-dk.euwest01.umbraco.io/"
            : "")
    ).replace(/\/$/, "");
};
