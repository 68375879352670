/* eslint-disable @typescript-eslint/no-explicit-any */
import { lazy } from "react";

const useRecursiveBlock = (alias: string) => {
    const getTemplate = (name: string) =>
        lazy(async (): Promise<any> => {
            const custom = import(
                /* webpackChunkName: "[request]" */ `src/blocks/components/${name}/forks/${alias}/${name}.component`
            );
            const fallback = import(
                /* webpackChunkName: "blocks" */ `src/blocks/components/${name}/${name}.component`
            );

            try {
                return await custom;
            } catch {
                return await fallback;
            }
        });

    return getTemplate;
};

export default useRecursiveBlock;
