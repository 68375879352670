/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_Container } from "@danishagro/shared/src/components/atoms/Container/Container.component";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
// Import types
import { SocialMediaSharingProps } from "./SocialMediaSharing.interface";
// Import styles
import S from "./SocialMediaSharing.module.scss";

// Social media platforms we are using
const platforms = {
    facebook: {
        url: "https://www.facebook.com/sharer/sharer.php?u=",
        icon: DA_IconNames.Facebook,
    },
    linkedin: {
        url: "https://www.linkedin.com/sharing/share-offsite/?url=",
        icon: DA_IconNames.LinkedIn,
    },
    // Twitter removed for now
    // twitter: {
    //     url: "https://twitter.com/intent/tweet?text=",
    //     icon: DA_IconNames.X,
    // },
};

const SocialMediaSharing = ({ shareUrl }: SocialMediaSharingProps) => {
    const { getDictionaryString } = useTranslations();

    // Define the function to handle share button click events
    const handleShare = (platformKey: keyof typeof platforms) => {
        // If a specific shareUrl is not provided, use the current page URL
        const url = shareUrl || window.location.href;
        // If a specific shareText is not provided, use the document title
        // const text = shareText || document.title;

        // Get the details of the clicked platform
        const platform = platforms[platformKey];
        let newShareUrl = "";

        // Prepare the share URL depending on the platform
        newShareUrl = `${platform.url}${encodeURIComponent(url)}`;

        // Twitter removed for now
        // if (platformKey !== "twitter") {
        //     newShareUrl = `${platform.url}${encodeURIComponent(url)}`;
        // } else {
        //     newShareUrl = `${platform.url}${encodeURIComponent(text)}&url=${encodeURIComponent(
        //         url
        //     )}`;
        // }

        // Open the share URL in a new tab
        window.open(newShareUrl, "_blank");
    };

    return (
        <div className={cn(S.wrapper)}>
            <DA_Container noVerticalPadding>
                <div className={S.inner}>
                    <span className={S.text}>{getDictionaryString("General.SoMeShare")}</span>
                    <div className={S.someList}>
                        {Object.keys(platforms).map((key) => {
                            // Cast the key to the proper type
                            const platformKey = key as keyof typeof platforms;
                            // Create the dictionary key for the platform title
                            const titleKey = `General.ShareTo${
                                platformKey.charAt(0).toUpperCase() + platformKey.slice(1)
                            }`;

                            return (
                                // Create the share button with the appropriate event handlers, title, and icon
                                <div
                                    key={key}
                                    onClick={() => handleShare(platformKey)}
                                    title={getDictionaryString(titleKey)}
                                >
                                    <DA_Icon useSvgDimensions name={platforms[platformKey].icon} />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </DA_Container>
        </div>
    );
};

export default SocialMediaSharing;
