import { useMemo } from "react";
import { FetchOptions } from "../interfaces/fetchOptions.interface";
import { getApiUrl } from "../helpers/getApiUrl.helper";
import { fetchHandler } from "../helpers/fetchHandler.helper";
import { useAppData } from "../contexts/appData.context";
import { API_GATEWAY_ROOT } from "../constants/urlRoot.constants";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type QueryObject = Record<string, any>;

export const useRequest = () => {
    const { fetchWithAuth } = useAppData();

    return useMemo(
        () => ({
            get: async (
                path: string,
                parameters?: QueryObject,
                options?: FetchOptions,
                expectText?: boolean | string
            ) => {
                const response = await fetchWithAuth(
                    getApiUrl(API_GATEWAY_ROOT, path, parameters),
                    options
                );
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                if (response.status === 204 || response.headers.get("content-length") === "0") {
                    return null;
                }
                try {
                    return expectText === "blob"
                        ? await response.blob()
                        : expectText === true
                        ? await response.text()
                        : await response.json();
                } catch (err) {
                    console.error("Error parsing JSON:", err);
                    throw new Error("Error parsing JSON");
                }
            },
            post: (
                path: string,
                parameters: QueryObject | string | number | boolean,
                options?: FetchOptions
            ) => fetchHandler(fetchWithAuth, API_GATEWAY_ROOT, "POST", path, parameters, options),
            put: (
                path: string,
                parameters: QueryObject | string | number | boolean,
                options?: FetchOptions
            ) => fetchHandler(fetchWithAuth, API_GATEWAY_ROOT, "PUT", path, parameters, options),
            delete: (
                path: string,
                parameters: QueryObject | string | number | boolean,
                options?: FetchOptions
            ) => fetchHandler(fetchWithAuth, API_GATEWAY_ROOT, "DELETE", path, parameters, options),
            patch: (
                path: string,
                parameters: QueryObject | string | number | boolean,
                options?: FetchOptions
            ) => fetchHandler(fetchWithAuth, API_GATEWAY_ROOT, "PATCH", path, parameters, options),
        }),
        [fetchWithAuth]
    );
};
