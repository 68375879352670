import { useEffect, useState } from "react";

declare global {
    interface Window {
        CookieConsent: {
            renew: () => void;
        };
        CookieInformation: {
            getConsentGivenFor: (cookieName: string) => boolean;
        };
    }
}

export const useMarketingConsent = () => {
    const [marketingConsent, setMarketingConsent] = useState(
        window?.CookieInformation?.getConsentGivenFor("cookie_cat_marketing")
    );

    const handleConsentChange = () => {
        const consent = window?.CookieInformation?.getConsentGivenFor("cookie_cat_marketing");

        if (consent !== marketingConsent) {
            setMarketingConsent(consent);
        }
    };

    useEffect(() => {
        window.addEventListener("CookieInformationConsentGiven", handleConsentChange);

        return () => {
            window.removeEventListener("CookieInformationConsentGiven", handleConsentChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [marketingConsent]);

    return { marketingConsent };
};
