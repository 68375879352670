import { DA_SiteVariant } from "../interfaces/siteVariant.interface";

export const SITE_VARIANTS: DA_SiteVariant[] = [
    {
        alias: "danish-agro",
    },
    {
        alias: "dla-agro",
    },
    {
        alias: "nordic-seed",
    },
    {
        alias: "vilofarm",
    },
    {
        alias: "agro-business",
    },
    {
        alias: "vilomix",
    },
    {
        alias: "agrosan",
    },
    {
        alias: "wildbird",
    },
    {
        alias: "green",
    },
    {
        alias: "blue",
    },
    {
        alias: "red",
    },
    {
        alias: "orange",
    },
    {
        alias: "trinol",
    },
    {
        alias: "lolland-falster-bioenergi",
    },
    {
        alias: "ada-green-energy",
    },
];
