export const checkCurrentOrAncestorPath = (path: string) => {
    if (path) {
        const searchPath = path.replace(/^\/?([^/]+(?:\/[^/]+)*)\/?$/, "/$1");

        // Checking if given path is a direct match to current pathn
        if (searchPath === window.location.pathname) {
            return true;
        }

        // Checking if given path is an ancestor to current pathn
        else if (`^${window.location.pathname}`.indexOf(`^${searchPath}/`) >= 0) {
            return true;
        }
    }

    return false;
};
