import { useMemo } from "react";

export const useTags = (tag: string, S: { [key: string]: string }) => {
    const tagClass = useMemo(() => {
        switch (tag) {
            case "News":
                return S.tagNews;
            case "Press":
                return S.tagPress;
            case "Partnership":
                return S.tagPartnership;
        }
    }, [tag, S]);

    return {
        tagClass,
    };
};
