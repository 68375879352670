import React, { useMemo } from "react";
import { DA_Container } from "@danishagro/shared/src/components/atoms/Container/Container.component";
import {
    DA_Title,
    DA_TitleTag,
} from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import { Helmet } from "react-helmet";
import { isProduction } from "@helpers/isProduction.helper";
import { useItems } from "../NewsListPage/hooks/useItems.hook";
import { DynamicBlock } from "../../../../blocks/DynamicBlock.component";
import { NewsListPageProps } from "./NewsListPage.interface";
import Card from "./components/Card/Card.component";
import S from "./NewsListPage.module.scss";

export const NewsListPage = (props: NewsListPageProps) => {
    const { items, viewState } = useItems(props.props?.rootFolder?.id);

    const robotsIndexFollow = useMemo(() => {
        if (!isProduction()) {
            return "noindex, nofollow";
        }

        return `${props.props.allowIndexing === true ? "index" : "noindex"},
                ${props.props.allowFollowing === true ? "follow" : "nofollow"}`;
    }, [props.props?.allowFollowing, props.props?.allowIndexing]);

    return (
        <div>
            <Helmet>
                <title>{props.props?.metaTitle || props.name}</title>
                <meta name="description" content={props.props?.metaDescription} />
                <meta name="robots" content={robotsIndexFollow} />
            </Helmet>

            <DA_Container noVerticalPadding>
                <div className={S.inner}>
                    {/* Hero */}
                    {props?.props?.hero?.[0]
                        ? props.props.hero?.map((block, index) => (
                              <DynamicBlock key={index} {...block} />
                          ))
                        : null}

                    <div className={S.textWrapper}>
                        {/** Tagline */}
                        {props.props.tagline ? (
                            <span className={S.tagline}>{props.props.tagline}</span>
                        ) : null}

                        {/** Headline */}
                        {props.props.headline ? (
                            <DA_Title h2 tag={DA_TitleTag.H2} noMargin>
                                {props.props.headline}
                            </DA_Title>
                        ) : null}

                        {/** Text */}
                        {props.props.text ? <DA_Text noPadding html={props.props.text} /> : null}
                    </div>

                    {/** List */}
                    <div className={S.list}>
                        {viewState === "DONE" ? (
                            <>
                                {items?.map((item, key) => {
                                    return <Card key={key} {...item} className={S.item} />;
                                })}
                            </>
                        ) : null}
                    </div>

                    {/* Blocks */}
                    {props?.props?.modules?.[0]
                        ? props.props.modules?.map((block, index) => (
                              <DynamicBlock key={index} {...block} />
                          ))
                        : null}
                </div>
            </DA_Container>
        </div>
    );
};
