import React from "react";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import {
    DA_Title,
    DA_TitleTag,
} from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { getAbsoluteUrl } from "@helpers/getAbsoluteUrl.helper";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { ImageViewer } from "src/components/ImageViewer/ImageViewer.component";
import { EmployeeProps } from "./Card.interface";
// eslint-disable-next-line css-modules/no-unused-class
import S from "./Card.module.scss";

export const Card = ({ employee, className }: { employee: EmployeeProps; className?: string }) => {
    const { getDictionaryString } = useTranslations();

    return (
        <div className={className}>
            <div className={S.inner}>
                <div className={S.imageWrapper}>
                    {/** Image */}
                    {employee?.props?.thumbnail?.props?.umbracoFile ? (
                        <div className={S.image}>
                            <ImageViewer
                                className={S.image}
                                src={getAbsoluteUrl(employee?.props?.thumbnail?.url)}
                            />
                        </div>
                    ) : null}
                </div>

                <div className={S.tags}>
                    {employee?.props?.tags.map((tag, index) => (
                        <div className={S.tag} key={index}>
                            {tag}
                        </div>
                    ))}
                </div>

                {/**Content */}
                <div className={S.textWrapper}>
                    {/** Title */}
                    <div className={S.title}>
                        <DA_Title h3 tag={DA_TitleTag.H3} noMargin>
                            {employee?.props?.title}
                        </DA_Title>
                    </div>

                    {employee?.props?.introductory ? (
                        <div className={S.text}>
                            <DA_Text noPadding html={employee?.props?.introductory} />
                        </div>
                    ) : null}

                    {/** Button */}
                    {/*// News open in new tab*/}
                    <div>
                        <DA_Button
                            title={getDictionaryString("General.Read")}
                            href={employee?.url}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card;
