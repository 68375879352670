import React, { useCallback } from "react";
import toast from "react-hot-toast";
import { DA_Toast } from "../components/molecules/Toast/Toast.component";
import { DA_ToastProps } from "../components/molecules/Toast/Toast.props";

interface Props extends DA_ToastProps {
    title?: React.ReactNode;
}

export const useToastMessage = () =>
    useCallback(
        ({ title, message, ...props }: Props) =>
            toast.custom(
                () => (
                    <DA_Toast
                        {...props}
                        message={
                            <>
                                {title && <div>{title}</div>}
                                <div className="break-all">{message}</div>
                            </>
                        }
                    />
                ),
                {
                    ariaProps: {
                        role: props.type === "error" ? "alert" : "status",
                        "aria-live": props.type === "error" ? "assertive" : "polite",
                    },
                }
            ),
        []
    );
