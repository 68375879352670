import React, { createContext, ReactNode, useCallback, useContext } from "react";
import { getAbsoluteUrl } from "@helpers/getAbsoluteUrl.helper";
import { Page } from "@interfaces/page.interface";

interface ApiState {
    fetchPage: (pathname: string) => Promise<Page>;
}

interface Props {
    children: ReactNode;
}

const ApiContext = createContext<ApiState>({} as ApiState);

export const ApiProvider = ({ children }: Props) => {
    const fetchPage = useCallback(async (pathname: string): Promise<Page> => {
        const frontendUrl = getAbsoluteUrl(pathname, "SPA");
        const cultureCode = globalThis?.cultures[0]?.alias;

        const backendParameters = {
            url: frontendUrl,
            rootNodeId: globalThis.rootNodeId,
        };

        let backendUrl;

        if (globalThis.isPreview === true) {
            backendParameters["isPreview"] = "true";
            backendUrl = new URL("/GetContentByUrl", window.location.origin);

            if (backendParameters) {
                for (const key in backendParameters) {
                    backendUrl.searchParams.append(key, backendParameters[key]);
                }
            }
        } else {
            backendUrl = getAbsoluteUrl("/GetContentByUrl", "SPA", backendParameters);
        }

        const request = await fetch(`${backendUrl}&cultureCode=${cultureCode}`);

        const response: Page = await request.json();

        return {
            ...response,
            status: request.status,
        };
    }, []);

    return <ApiContext.Provider value={{ fetchPage }}>{children}</ApiContext.Provider>;
};

export const useApi = (): ApiState => useContext(ApiContext);
