import React from "react";
import { DA_Container } from "@danishagro/shared/src/components/atoms/Container/Container.component";
import { Page } from "@interfaces/page.interface";
import S from "./ErrorPage.module.scss";

const ErrorPage = (_props: Page) => {
    return (
        <DA_Container className={S.wrapper}>
            <h1>500</h1>
            <h4>Der skete en fejl</h4>
        </DA_Container>
    );
};

export default ErrorPage;
