import React, { useCallback, useEffect, useState } from "react";
import { useMedia } from "react-use";

export enum SCREEN_SIZES {
    XXS = 1,
    XS = 512,
    SM = 768,
    MD = 992,
    LG = 1220,
    XL = 1540,
    XXL = 1840,
}

interface ScreenHook {
    screenSize: SCREEN_SIZES;
    isMobile: boolean;
    isMobileOrTablet: boolean;
    isMinLg: boolean;
    isMinXs: boolean;
    isScrollLocked: boolean;
    toggleScrollLock: (state?: boolean) => void;
    scrollLockOffset: number;
    scrollTo: (to: number | Element, instant?: boolean) => void;
}

const ScreenContext = React.createContext<ScreenHook>({
    screenSize: SCREEN_SIZES.XXS,
    isMobile: true,
    isMobileOrTablet: true,
    isMinLg: false,
    isMinXs: false,
    isScrollLocked: false,
    toggleScrollLock: () => null,
    scrollLockOffset: 0,
    scrollTo: () => null,
});

type Props = {
    children: React.ReactNode;
};

export const ScreenProvider = ({ children }: Props): JSX.Element => {
    const [isScrollLocked, setIsScrollLocked] = useState(false);
    const [scrollLockOffset, setScrollLockOffset] = useState(0);
    const [screenSize, setScreenSize] = useState(SCREEN_SIZES.XXS);

    const toggleScrollLock = useCallback(
        (state?: boolean) => {
            const newState = typeof state === "boolean" ? state : !isScrollLocked;
            setIsScrollLocked(newState);
            setScrollLockOffset(newState ? window.scrollY : 0);
        },
        [isScrollLocked]
    );

    const isMinXs = useMedia(`(min-width: ${SCREEN_SIZES.XS}px)`);
    const isMinSm = useMedia(`(min-width: ${SCREEN_SIZES.SM}px)`);
    const isMinMd = useMedia(`(min-width: ${SCREEN_SIZES.MD}px)`);
    const isMinLg = useMedia(`(min-width: ${SCREEN_SIZES.LG}px)`);
    const isMinXl = useMedia(`(min-width: ${SCREEN_SIZES.XL}px)`);
    const isMinXxl = useMedia(`(min-width: ${SCREEN_SIZES.XXL}px)`);

    const scrollTo = useCallback(
        (to: number | Element, instant = false) => {
            const y =
                typeof to === "number"
                    ? to
                    : to.getBoundingClientRect().top + document.documentElement.scrollTop;
            const top = Math.max(0, y - (isMinLg ? 189 : isMinMd ? 177 : isMinSm ? 89 : 46));

            setTimeout(
                // @ts-ignore
                () => window.scrollTo({ top, behavior: instant ? "instant" : "smooth" }),
                0
            );
        },
        [isMinLg, isMinMd, isMinSm]
    );

    useEffect(() => {
        let newScreenSize = SCREEN_SIZES.XXS;
        if (isMinXxl) {
            newScreenSize = SCREEN_SIZES.XXL;
        } else if (isMinXl) {
            newScreenSize = SCREEN_SIZES.XL;
        } else if (isMinLg) {
            newScreenSize = SCREEN_SIZES.LG;
        } else if (isMinMd) {
            newScreenSize = SCREEN_SIZES.MD;
        } else if (isMinSm) {
            newScreenSize = SCREEN_SIZES.SM;
        } else if (isMinXs) {
            newScreenSize = SCREEN_SIZES.XS;
        }
        setScreenSize(newScreenSize);
    }, [isMinXs, isMinSm, isMinMd, isMinLg, isMinXl, isMinXxl]);

    return (
        <ScreenContext.Provider
            value={{
                screenSize,
                isMobile: !isMinSm,
                isMobileOrTablet: !isMinMd,
                isMinLg,
                isMinXs,
                isScrollLocked,
                toggleScrollLock,
                scrollLockOffset,
                scrollTo,
            }}
        >
            {children}
        </ScreenContext.Provider>
    );
};

export const useScreen = (): ScreenHook => React.useContext(ScreenContext);
