import jwtDecode from "jwt-decode";
import { MYFARM_SITE_ORIGIN } from "@danishagro/shared/src/constants/urlRoot.constants";

const BEARER_TOKEN_URL = `${MYFARM_SITE_ORIGIN}/gettoken`;
// const B2B_BEARER_TOKEN_URL = `${API_GATEWAY_ROOT}/utility/dwtoken`;
const B2B_BEARER_TOKEN_URL = `${MYFARM_SITE_ORIGIN}/gettoken`;

export const getBearerToken = (forceNew = false): Promise<string> => {
    const globalToken = globalThis.accessToken;
    const expiration = globalToken
        ? (jwtDecode(globalToken) as Record<"exp", number>).exp * 1000
        : 0;
    const updateBearerToken =
        globalThis.isAuthenticated &&
        (forceNew || (typeof expiration === "number" ? expiration <= Date.now() : false));

    return !updateBearerToken
        ? Promise.resolve(globalToken)
        : fetch(BEARER_TOKEN_URL, {
              credentials: "include",
          })
              .then((res) => res.text())
              .then((token) => {
                  globalThis.accessToken = token;
                  return token;
              });
};

// export const getBearerTokenB2B = (customerNumber: string, forceNew = false): Promise<string> => {
//     const globalToken = globalThis.dwToken;
//     const expiration = globalToken
//         ? (jwtDecode(globalToken) as Record<"exp", number>).exp * 1000
//         : 0;
//     const updateBearerToken =
//         forceNew || (typeof expiration === "number" ? expiration <= Date.now() : false);

//     return !updateBearerToken
//         ? Promise.resolve(globalToken || undefined)
//         : fetch(`${B2B_BEARER_TOKEN_URL}?customerNumber=${customerNumber}`, {
//               credentials: "include",
//               headers: {
//                   Authorization: "Bearer " + globalThis.accessToken,
//               },
//           })
//               .then((res) => res.json())
//               .then(({ token }) => {
//                   globalThis.dwToken = token;
//                   return token;
//               });
// };

export const getBearerTokenB2B = (customerNumber: string, forceNew = false): Promise<string> => {
    const globalToken = globalThis.accessToken;
    const expiration = globalToken
        ? (jwtDecode(globalToken) as Record<"exp", number>).exp * 1000
        : 0;
    const updateBearerToken =
        forceNew || (typeof expiration === "number" ? expiration <= Date.now() : false);

    return !updateBearerToken
        ? Promise.resolve(globalToken || undefined)
        : fetch(`${B2B_BEARER_TOKEN_URL}?customerNumber=${customerNumber}`, {
              credentials: "include",
              headers: {
                  Authorization: "Bearer " + globalThis.accessToken,
              },
          })
              .then((res) => res.json())
              .then(({ token }) => {
                  //   globalThis.dwToken = token;
                  return token;
              });
};
