import React, { createContext, ReactNode, useContext, useState } from "react";
import { SiteSettings } from "@danishagro/cms/src/interfaces/siteSettings.interface";

interface SiteSettingsState {
    siteSettings: SiteSettings;
}

interface Props {
    children: ReactNode;
    initial: {
        siteSettings: SiteSettings;
    };
}

const SiteSettingsContext = createContext<SiteSettingsState>({} as SiteSettingsState);

export const SiteSettingsProvider = ({ children, initial }: Props) => {
    const [siteSettings] = useState<SiteSettings>(initial.siteSettings);

    return (
        <SiteSettingsContext.Provider value={{ siteSettings }}>
            {children}
        </SiteSettingsContext.Provider>
    );
};

export const useSiteSettings = (): SiteSettingsState => useContext(SiteSettingsContext);
