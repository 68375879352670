import React from "react";
import reactStringReplace from "react-string-replace";
import { B2bImageSrc } from "@danishagro/shared/src/helpers/imageSrc.helper";
import { ImageConfig } from "@danishagro/shared/src/content/imageConfigs/imageConfig.enum";
import { GET_B2B_SITE_ORIGIN } from "@danishagro/shared/src/helpers/urlRoot.helper";
import { DA_BasicLink } from "../../../../atoms/BasicLink/BasicLink.component";
import { RelewiseSearchProduct } from "../interfaces/relewiseSearchProduct.interface";
import S from "./SearchProduct.module.scss";

export const _SearchProduct = ({
    highlightedString,
    onResultClicked,
    ...props
}: RelewiseSearchProduct & { highlightedString: string; onResultClicked: () => void }) => {
    const baseUrl = GET_B2B_SITE_ORIGIN("ABSOLUTE");
    const productUrl = `${baseUrl}${props.data?.canonicalBaseUrl?.value}/${props.data?.webNameSlug?.value}`;

    const mediaUrl = props.data?.mediaUrl?.value;

    return (
        <DA_BasicLink
            forceNativeUrl
            onClick={onResultClicked}
            href={productUrl}
            target="_self"
            className={S.link}
        >
            <figure className={S.image}>
                <img
                    src={B2bImageSrc(mediaUrl, ImageConfig.SearchImage)}
                    alt={props.displayName}
                    width="50"
                    height="50"
                    className={S.imagesrc}
                />
            </figure>
            <div className={S.data}>
                <div className={S.details}>
                    <div className={S.productNumber}>{props.data.masterProductNumber.value}</div>
                    {reactStringReplace(
                        props.displayName || "Mangler produktnavn",
                        new RegExp(`(${highlightedString})`, "ig"),
                        (match, index) => (
                            <b key={index}>{match}</b>
                        )
                    )}
                </div>
            </div>
        </DA_BasicLink>
    );
};
