import React from "react";
import { DA_BasicLink } from "@danishagro/shared/src/components/atoms/BasicLink/BasicLink.component";
import { DA_Title } from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { format } from "date-fns";
import { ImageViewer } from "src/components/ImageViewer/ImageViewer.component";
import { getAbsoluteUrl } from "@helpers/getAbsoluteUrl.helper";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { useItems } from "./hooks/useItems.hook";
import { LatestNewsProps } from "./LatestNews.interface";
import S from "./LatestNews.module.scss";

const LatestNews = (props: LatestNewsProps) => {
    const { items, viewState } = useItems(props.take);
    const { getDictionaryString } = useTranslations();

    return (
        <>
            {viewState === "DONE" ? (
                <div className={S.list}>
                    <DA_Title h4 noMargin>
                        {getDictionaryString("News.LatestNews")}
                    </DA_Title>

                    {items?.[0] &&
                        items.map((item, key) => {
                            //use dateCulture from Umbraco global settings
                            const publishDate = item?.publishedDate
                                ? globalThis.siteSettingsObj.dateCulture === "da-dk"
                                    ? format(new Date(item?.publishedDate), "dd.MM.yyyy")
                                    : format(new Date(item?.publishedDate), "MM.dd.yyyy")
                                : // use dd.MM.yyyy if globalThis.siteSettingsObj.dateCulture not set
                                  format(new Date(item?.publishedDate), "dd.MM.yyyy");

                            return (
                                <DA_BasicLink className={S.link} key={key} href={item.url}>
                                    {/** Image */}
                                    {item.thumbnail ? (
                                        <div className={S.image}>
                                            <ImageViewer
                                                className={S.image}
                                                src={getAbsoluteUrl(item.thumbnail?.url, "UMBRACO")}
                                                forceWidth={200}
                                            />
                                        </div>
                                    ) : null}

                                    <div className={S.content}>
                                        {/** Date */}
                                        {item.publishedDate ? <span>{publishDate}</span> : null}

                                        {/** Headline */}
                                        <strong className={S.headline}>{item.title}</strong>
                                    </div>
                                </DA_BasicLink>
                            );
                        })}
                </div>
            ) : null}
        </>
    );
};

export default LatestNews;
