import React from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_FooterObject } from "../../../../interfaces/footerObject.interface";
import { DA_BasicLink } from "../../../atoms/BasicLink/BasicLink.component";
import { DA_Icon, DA_IconNames } from "../../../atoms/Icon/Icon.component";
import { DA_Accordion } from "../../../molecules/Accordion/Accordion.component";
import { _FooterLink } from "../FooterLink/FooterLink.component";
import {
    AddressType,
    TextType,
    LinkType,
    SoMeType,
} from "../../../../interfaces/footerObject.interface";
import S from "./MobileFooter.module.scss";

export interface DA_MobileFooterProps {
    data: DA_FooterObject;
}

export const DA_MobileFooter = ({ data }: DA_MobileFooterProps) => {
    const isFooterAddress = (
        item: TextType | LinkType | AddressType | SoMeType
    ): item is AddressType => {
        return item.itemType === "footerAddress";
    };

    return (
        <footer className={S.mobileFooter}>
            {data?.columns?.map((column, index) => (
                <DA_Accordion
                    key={index}
                    className={S.accordion}
                    title={column.header}
                    showToggleIcons
                    animation
                >
                    {column?.items?.map((item, index) => {
                        if (item?.itemType === "text") {
                            return (
                                <div key={index} className={S.footerText}>
                                    {item.title && (
                                        <span className={S.itemTitle}>{item.title}</span>
                                    )}

                                    <span dangerouslySetInnerHTML={{ __html: item.text }} />
                                </div>
                            );
                        }
                        if (isFooterAddress(item)) {
                            return (
                                <div
                                    key={index}
                                    itemScope
                                    itemType="http://schema.org/Organization"
                                    className={S.addressInfoWrapper}
                                >
                                    {item.company && (
                                        <span
                                            itemProp="name"
                                            className={S.addressInfo}
                                            dangerouslySetInnerHTML={{
                                                __html: item.company,
                                            }}
                                        />
                                    )}
                                    {item.address1 && (
                                        <span
                                            itemProp="streetAddress"
                                            className={S.addressInfo}
                                            dangerouslySetInnerHTML={{
                                                __html: item.address1,
                                            }}
                                        />
                                    )}
                                    {item.address2 && (
                                        <span
                                            itemProp="streetAddress"
                                            className={S.addressInfo}
                                            dangerouslySetInnerHTML={{
                                                __html: item.address2,
                                            }}
                                        />
                                    )}
                                    {item.zipCity && (
                                        <span className={S.addressInfo}>
                                            <span itemProp="postalCode">
                                                {item.zipCity.split(" ")[0]}
                                            </span>
                                            <span itemProp="addressLocality">{` ${
                                                item.zipCity.split(" ")[1]
                                            }`}</span>
                                        </span>
                                    )}
                                    {item.country && (
                                        <span itemProp="addressCountry" className={S.addressInfo}>
                                            {item.country}
                                        </span>
                                    )}
                                    {item.cvr && (
                                        <span itemProp="vatID" className={S.addressInfo}>
                                            {item.cvr}
                                        </span>
                                    )}
                                    {item.email && (
                                        <span itemProp="email" className={S.addressInfo}>
                                            {item.email}
                                        </span>
                                    )}
                                    {item.phone && (
                                        <span itemProp="telephone" className={S.addressInfo}>
                                            {item.phone}
                                        </span>
                                    )}
                                </div>
                            );
                        }
                        if (item?.itemType === "link") {
                            return <_FooterLink key={index} {...item} classname={S.footerLink} />;
                        }
                    })}
                </DA_Accordion>
            ))}

            {data?.socials?.map((social, index) => (
                <div key={index} className={cn(S.mobileFooterItem, S.noBorderBottom)}>
                    <span className={S.mobileItemTitle}>{social.header}</span>
                    <div className={S.soMeLinks}>
                        {social?.items?.map((item) => {
                            const iconName =
                                item.type === "facebook"
                                    ? DA_IconNames.Facebook
                                    : item.type === "linkedin"
                                    ? DA_IconNames.LinkedIn
                                    : item.type === "instagram"
                                    ? DA_IconNames.Instagram
                                    : item.type === "youtube"
                                    ? DA_IconNames.YouTube
                                    : DA_IconNames.Question;

                            return (
                                <DA_BasicLink
                                    href={item.href}
                                    key={item.type}
                                    className={S.soMeLink}
                                    ariaLabel={item.type}
                                >
                                    <DA_Icon
                                        name={iconName}
                                        className={S.soMeIcon}
                                        useSvgDimensions
                                    />
                                </DA_BasicLink>
                            );
                        })}
                    </div>
                </div>
            ))}
        </footer>
    );
};
