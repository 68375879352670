import { DA_NavigationPage } from "../../../../../interfaces/navigationPage.interface";
import { checkCurrentOrAncestorPath } from "./checkCurrentOrAncestorPath.helper";

export const recursiveCheckCurrentPathMobile = (
    previous: DA_NavigationPage[],
    current: DA_NavigationPage
) => {
    const isInCurrentPath = checkCurrentOrAncestorPath(current.path);

    if (isInCurrentPath === true && current.path !== "/" && current.children?.[0]) {
        previous.push(current);
        return current.children.reduce(recursiveCheckCurrentPathMobile, previous);
    }

    return previous;
};
