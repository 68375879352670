import React, { useEffect, useRef } from "react";
import { useMeasure } from "react-use";
import { useModal } from "@danishagro/shared/src/contexts/modal.context";
import { useModalMessage } from "@danishagro/shared/src/hooks/useModalMessage.hook";
import { useStickyBannerMessages } from "@danishagro/shared/src/hooks/useStickyBannerMessages.hook";
import { DA_HeaderMessage } from "@danishagro/shared/src/components/molecules/Message/HeaderMessage/headerMessage.component";
import { DA_ModalMessage } from "@danishagro/shared/src/components/molecules/Message/ModalMessage/modalMessage.component";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { useScreen } from "../../../contexts/screen.context";
import { useSiteHeader } from "../../../contexts/siteHeader.context";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside.hook";
import { DA_Container } from "../../atoms/Container/Container.component";
import { DA_BasicLink } from "../../atoms/BasicLink/BasicLink.component";
import { ReactComponent as DanishAgroLogo } from "../../../assets/svg/danish-agro-logo.svg";
import { ReactComponent as VilofarmLogo } from "../../../assets/svg/vilofarm-logo.svg";
import { ReactComponent as DLALogo } from "../../../assets/svg/logos/DLA_Agro.svg";
import { ReactComponent as NordicSeedLogo } from "../../../assets/svg/logos/Nordic_Seed.svg";
import { DA_Logo, DA_LogoNames } from "../../atoms/Logo/Logo.component";
import { useTranslations } from "../../../contexts/translations/translations.context";
import { useAppData } from "../../../contexts/appData.context";
import { DA_ServiceButtons } from "./ServiceButtons/ServiceButtons.component";
import { DA_ServiceMenu } from "./ServiceMenu/ServiceMenu.component";
import { DA_NavigationDesktop } from "./NavigationDesktop/NavigationDesktop.component";
import { DA_AccountPickerPopUp } from "./AccountPicker/AccountPicker.component";
import { DA_MiniCartPopUp } from "./MiniCart/MiniCart.component";
import { DA_Toolbar } from "./Toolbar/Toolbar.component";
import { DA_SearchInput } from "./SearchInput/SearchInput.component";
import { DA_NavigationMobileTrigger } from "./NavigationMobile/components/Trigger/Trigger.component";
import { DA_LanguageSelector } from "./LanguageSelector/LanguageSelector.component";
import { SiteHeaderProps } from "./SiteHeader.props";
import S from "./SiteHeader.module.scss";

export const DA_SiteHeader = (props: SiteHeaderProps) => {
    const {
        setHeaderMeasure,
        showAccountPicker,
        showMiniCart,
        togglePopUp,
        toggleMobileNav,
        showMobileNav,
        showServiceMenu,
    } = useSiteHeader();
    const { getDictionaryString } = useTranslations();
    const { siteVariant, isAppFunctional, currentSite, logoName } = useAppData();
    const { isMobileOrTablet } = useScreen();
    const [headerRef, headerRect] = useMeasure<HTMLDivElement>();
    const serviceButtonsRef = useRef<HTMLDivElement>();
    const popUpAreaRef = useRef<HTMLDivElement>();

    useOnClickOutside([serviceButtonsRef, popUpAreaRef], () => togglePopUp(undefined));

    useEffect(() => setHeaderMeasure(headerRect), [headerRect, setHeaderMeasure]);

    // Formats the name of the logo
    const formatLogoName = (name: string) => name?.replace(/\s/g, "") || "";

    // TODO: SET FROM SCRIPT
    const messages = Array.isArray(globalThis?.announcementMessages)
        ? globalThis?.announcementMessages
        : null;

    // Determine which message to show
    const { currentMessage, handleModalClose } = useModalMessage(messages);
    const activeStickyBanners = useStickyBannerMessages(messages);
    const { showModal, isModalShowing, closeModal } = useModal();

    const getFrontPagePath = globalThis?.siteSettingsObj?.frontPagePath;
    const isLoginOptionsTrue = location.hostname !== "localhost" ? global?.apiGatewayUrl : true;

    // ExcellenceProgramme CMS
    const isExcellenceProgrammeCmsSite =
        typeof globalThis?.isUsingCustomAd === "string"
            ? globalThis.isUsingCustomAd.toLowerCase() === "true"
            : false;

    return (
        <>
            {/** Header */}
            <header ref={headerRef} className={S.header}>
                <a href="#main" className={S.skipToMainContentLink}>
                    {getDictionaryString("accessibilityMainContent")}
                </a>

                {!isMobileOrTablet &&
                    ((globalThis?.siteSettingsObj?.universalMenuLinks &&
                        globalThis.siteSettingsObj.universalMenuLinks.length > 0) ||
                        globalThis?.siteSettingsObj?.showLanguageSelector ||
                        isExcellenceProgrammeCmsSite ||
                        showServiceMenu) && (
                        <div className={S.serviceArea}>
                            <DA_Container noVerticalPadding>
                                <div className={S.serviceAreaFlex}>
                                    <DA_ServiceMenu />
                                    <div className={S.serviceRight}>
                                        {isAppFunctional &&
                                            isLoginOptionsTrue &&
                                            showServiceMenu && (
                                                <DA_ServiceButtons ref={serviceButtonsRef} />
                                            )}
                                        {globalThis.siteSettingsObj?.showLanguageSelector && (
                                            <DA_LanguageSelector />
                                        )}
                                    </div>
                                    {isExcellenceProgrammeCmsSite && (
                                        <div className={S.logOutLink}>
                                            <DA_BasicLink
                                                href="/logout"
                                                target="_self"
                                                conventionalRouting
                                                className={S.buttonLink}
                                            >
                                                <DA_Icon
                                                    name={DA_IconNames.SignOut}
                                                    className={S.buttonIcon}
                                                />
                                                <span className={S.buttonText}>
                                                    {getDictionaryString("logOut", {
                                                        uppercaseFirst: true,
                                                    })}
                                                </span>
                                            </DA_BasicLink>
                                        </div>
                                    )}
                                </div>
                            </DA_Container>
                        </div>
                    )}
                <DA_ModalMessage
                    currentMessage={currentMessage}
                    showModal={showModal}
                    isModalShowing={isModalShowing}
                    handleModalClose={handleModalClose}
                    closeModal={closeModal}
                    getDictionaryString={getDictionaryString}
                />
                {!isMobileOrTablet && (
                    <DA_HeaderMessage activeStickyBanners={activeStickyBanners} />
                )}

                <DA_Container noVerticalPadding>
                    <div className={S.navigationWrapper}>
                        <div className={S.logoWrapper}>
                            <DA_BasicLink
                                href={getFrontPagePath ? getFrontPagePath : "/"}
                                onClick={() => (showMobileNav ? toggleMobileNav() : null)}
                                ariaLabel={getDictionaryString("frontpage")}
                                className={S.logo}
                                forceNativeUrl={true}
                            >
                                {currentSite === "CMS" ? (
                                    <DA_Logo name={DA_LogoNames[formatLogoName(logoName)]} />
                                ) : (
                                    <>
                                        {siteVariant.alias === "danish-agro" && <DanishAgroLogo />}
                                        {siteVariant.alias === "vilofarm" && <VilofarmLogo />}
                                        {siteVariant.alias === "dla-agro" && <DLALogo />}
                                        {siteVariant.alias === "nordic-seed" && <NordicSeedLogo />}
                                    </>
                                )}
                            </DA_BasicLink>

                            {!isMobileOrTablet && (
                                <div className={S.search}>
                                    <DA_SearchInput trapFocus />
                                </div>
                            )}
                        </div>

                        {isMobileOrTablet ? (
                            <DA_NavigationMobileTrigger {...props} />
                        ) : (
                            <DA_NavigationDesktop />
                        )}

                        <div ref={popUpAreaRef}>
                            {/** Popup: Account Picker */}
                            {showAccountPicker && !isMobileOrTablet && <DA_AccountPickerPopUp />}

                            {/** Popup: Mini Cart */}
                            {showMiniCart && !isMobileOrTablet && <DA_MiniCartPopUp />}

                            {/* TODO: Detect that Account Picker or Mini Cart has gone from Desktop to MobileOrTablet and open the respective bottom sheet */}
                        </div>
                    </div>
                </DA_Container>
            </header>

            {/** Toolbar */}
            <DA_Toolbar isLoginOptionsTrue={isLoginOptionsTrue} />
        </>
    );
};
