import React from "react";
import { useScreen } from "@danishagro/shared/src/contexts/screen.context";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_Title } from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { LocationInfoProps } from "./LocationInfo.interface";
import S from "./LocationInfo.module.scss";

export const LocationInfo = ({
    workPlaceCoordinates,
    department,
    city,
    country,
    zip,
    address,
}: LocationInfoProps) => {
    const { getDictionaryString } = useTranslations();
    const { isMobileOrTablet } = useScreen();

    const GOOGLE_MAPS_API_KEY = "AIzaSyCBqY9mpYO6gk6YIWTeJbIev-lFSux2WOM";
    const ZOOM_LEVEL = 11;
    const MAP_WIDTH = 370;
    const MAP_HEIGHT = 240;

    // Coordinates
    const coordinates = workPlaceCoordinates?.split(", ");
    const [latitude, longitude] = coordinates || [null, null];

    return (
        <div className={S.workPlaceWrapper}>
            <div className={S.inner}>
                <DA_Title h2>
                    <span className={S.title}>{getDictionaryString("Job.Workplace")}</span>
                </DA_Title>
                {department.name && (
                    <span className={cn(S.info, S.boldText)}>{department.name}</span>
                )}
                {address && <span className={S.info}>{address}</span>}
                {(zip || city) && (
                    <span className={S.info}>
                        {zip && city ? `${zip} ${city}` : zip ? zip : city}
                    </span>
                )}
                {country && <span className={S.info}>{country}</span>}
            </div>

            {latitude && longitude && address && (
                <a
                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                        `${department.name}, ${address}, ${city}, ${country}`
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        src={`https://maps.googleapis.com/maps/api/staticmap?%20center=${latitude},${longitude}&${
                            isMobileOrTablet ? "scale=2" : "scale=1"
                        }&zoom=${ZOOM_LEVEL}&size=${MAP_WIDTH}x${MAP_HEIGHT}%20&markers=${latitude},${longitude}%20&key=${GOOGLE_MAPS_API_KEY}`}
                        alt="Google maps"
                    />
                </a>
            )}
        </div>
    );
};
