export enum ImageConfig {
    // Søgning
    SearchImage = "SearchImage", // Search 75x75

    // Mini basket
    MiniBasketThumbnail = "MiniBasketThumbnail", // minibasket 75x75

    // Kategorier på forside
    CategoryImage = "CategoryImage", // category 500x500

    // Produktdetaljeside
    PdpThumbnail = "PDPThumbnail", // product detail 75x75
    PdpMainImage = "PDPMainImage", // product detail 545x545
    PdpZoom = "PDPZoom", // product detail zoom 2500x2500

    // Produktliste
    PlpImage = "PLPImage", // product list 250x250
}
