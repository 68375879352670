import { getClosestWidth } from "./getClosestWidth.helper";

export const setImageOptions = (
    url: string,
    clientWidth?: number,
    clientHeight?: number,
    aspectRatioX?: number,
    aspectRatioY?: number,
    currentSite?: string
): string => {
    const params = new URLSearchParams();

    // Sizing
    const { width, height } = getClosestWidth(
        clientWidth,
        clientHeight,
        aspectRatioX,
        aspectRatioY
    );
    // on cms don't use width the aspect radio are used
    {
        currentSite === "CMS" ? "" : params.set("width", `${width}`);
    }
    params.set("height", `${height}`);

    // Miscellaneous
    params.set("mode", "crop");
    params.set("format", "webp");
    params.set("quality", "80");

    const querySeparator = url && url.toString().indexOf("?") > 0 ? "&" : "?";

    return `${url}${querySeparator}${params.toString()}`;
};
