import React from "react";
import { useBottomSheet } from "../../../../../contexts/bottomSheet/bottomSheet.context";
import { useTranslations } from "../../../../../contexts/translations/translations.context";
import { DA_MiniSearch } from "../../MiniSearch/MiniSearch.component";
import { useSiteHeader } from "../../../../../contexts/siteHeader.context";

export const useSearchButton = () => {
    const { getDictionaryString } = useTranslations();
    const { closeMobileNav, showMobileNav } = useSiteHeader();

    const { presentBottomSheet, dismissBottomSheet, activeBottomSheet } = useBottomSheet();

    const onSearchButtonClicked = () => {
        const id = "search";

        if (activeBottomSheet !== id) {
            presentBottomSheet(<DA_MiniSearch />, {
                id: id,
                headerTitle: getDictionaryString("searchModalTitle"),
            });
            // Close mobileNav after bottomSheet has opened
            if (showMobileNav) {
                setTimeout(() => {
                    closeMobileNav();
                }, 500);
            }
        } else {
            dismissBottomSheet();
        }
    };

    return {
        showSearch: true,
        onSearchButtonClicked,
        isSearchMenuActive: false,
    };
};
