import React, { useEffect, useRef, useState } from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_BasicLink } from "@danishagro/shared/src/components/atoms/BasicLink/BasicLink.component";
import { DA_ButtonLink } from "@danishagro/shared/src/components/atoms/ButtonLink/ButtonLink.component";
import { DA_Container } from "@danishagro/shared/src/components/atoms/Container/Container.component";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { DA_NavigationPage } from "@danishagro/shared/src/interfaces/navigationPage.interface";
import { DA_Title } from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { useLocation } from "react-router-dom";
import { useSiteHeader } from "@danishagro/shared/src/contexts/siteHeader.context";
import { useTranslations } from "../../../../../../contexts/translations/translations.context";
import { recursiveCheckCurrentPathMobile } from "../../helpers/recursiveCheckCurrentPathMobile.helper";
import { DA_LanguageSelector } from "../../../LanguageSelector/LanguageSelector.component";
import { MenuProps } from "./Menu.props";
import S from "./Menu.module.scss";

export const Menu = (props: MenuProps) => {
    const { currentSite } = useAppData();
    const { getDictionaryString } = useTranslations();
    const { toggleMobileNav } = useSiteHeader();
    const { pathname } = useLocation();

    const [activeItems, setActiveItems] = useState<DA_NavigationPage[]>([]);
    const menuElementRef = useRef<HTMLDivElement>();

    const onBackButtonClicked = (level: number) => {
        setActiveItems((x) => {
            const tmpActiveItems = [...x];

            tmpActiveItems.splice(level - 1);

            return tmpActiveItems;
        });
    };

    const onLinkClicked = (link: DA_NavigationPage, level: number) => {
        setActiveItems((x) => {
            const tmpActiveItems = [...x];

            tmpActiveItems.splice(level - 1);
            tmpActiveItems.push(link);

            return tmpActiveItems;
        });
    };

    // Update - Scroll to the farthest right

    useEffect(() => {
        setTimeout(() => {
            if (menuElementRef.current) {
                menuElementRef.current.scrollTo({
                    top: 0,
                    left: menuElementRef.current.scrollWidth ?? 0,
                    behavior: "smooth",
                });
            }
        }, 100);
    }, [activeItems]);

    // Init

    useEffect(() => {
        if (currentSite !== "B2B") {
            const initialActiveItems = props.menuItems.reduce(recursiveCheckCurrentPathMobile, []);
            setActiveItems(initialActiveItems);
        }
    }, [props.menuItems, pathname, currentSite]);

    return (
        <div className={S.wrapper}>
            <div className={S.menu} ref={menuElementRef}>
                {/** First Level */}
                <div className={S.section}>
                    <DA_Container>
                        {/** Items */}
                        {props.menuItems
                            ?.filter((x: DA_NavigationPage) => !x.hideInNavigation)
                            .map((item, index) => {
                                const currentActiveItem = activeItems?.[0];
                                const isActiveClass =
                                    currentActiveItem && item.path === currentActiveItem?.path
                                        ? S.isActive
                                        : undefined;
                                const hasChildren = !!item.children?.filter(
                                    (x) => !x.hideInNavigation
                                )?.[0];

                                return (
                                    <div className={cn(S.item, isActiveClass)} key={index}>
                                        {hasChildren && currentSite !== "B2B" ? (
                                            <button
                                                className={S.button}
                                                onClick={() => onLinkClicked(item, 1)}
                                            >
                                                <span>{item.name}</span>
                                                <DA_Icon
                                                    name={DA_IconNames.ChevronRight}
                                                    className={S.arrow}
                                                />
                                            </button>
                                        ) : (
                                            <DA_BasicLink
                                                target={item.url ? "_blank" : "_self"}
                                                href={item.url || item.path}
                                                onClick={toggleMobileNav}
                                                className={cn(S.link)}
                                            >
                                                {item.name}
                                            </DA_BasicLink>
                                        )}
                                    </div>
                                );
                            })}

                        {/** Customer Service */}
                        <div className={S.customerServiceLinks}>
                            {props?.customerServiceLinks?.map((item, index) => (
                                <div key={index}>
                                    <DA_BasicLink
                                        target="_self"
                                        href={item.href}
                                        onClick={toggleMobileNav}
                                        className={S.customerServiceLink}
                                    >
                                        {item.title}
                                    </DA_BasicLink>
                                </div>
                            ))}
                            {globalThis.siteSettingsObj?.showLanguageSelector && (
                                <DA_LanguageSelector
                                    showLanguageName={true}
                                    showDropDownArrow={true}
                                />
                            )}
                        </div>
                    </DA_Container>
                </div>

                {/** Sub Levels */}
                {activeItems
                    .filter((activeItem) =>
                        activeItem.children?.some((item) => !item.hideInNavigation)
                    ) // Only show activeItems that have at least one visible child
                    .map((activeItem, index) => {
                        const disableDirectLinkFirstSubLevel =
                            currentSite === "MYFARM" && index === 0 && !activeItem.url;

                        return (
                            <div key={index} className={S.section}>
                                <DA_Container>
                                    {/** Header */}
                                    <div className={S.header}>
                                        {/** Back Button */}
                                        <DA_ButtonLink
                                            className={S.backButton}
                                            onClick={() => onBackButtonClicked(index + 1)}
                                            title={getDictionaryString("back")}
                                            icon={DA_IconNames.ChevronLeft}
                                        />

                                        {activeItem.path && !disableDirectLinkFirstSubLevel ? (
                                            /** Forward Link */
                                            <DA_BasicLink
                                                target="_self"
                                                className={S.forwardLink}
                                                onClick={toggleMobileNav}
                                                href={activeItem.path}
                                            >
                                                <DA_Title h4 noMargin>
                                                    {activeItem.name}
                                                </DA_Title>
                                                <DA_Icon
                                                    className={S.forwardIcon}
                                                    name={DA_IconNames.ArrowRight}
                                                />
                                            </DA_BasicLink>
                                        ) : (
                                            /** Just Title */
                                            <div className={S.titleWrapper}>
                                                <DA_Title h4>{activeItem.name}</DA_Title>
                                            </div>
                                        )}
                                    </div>

                                    {/** Items */}
                                    {activeItem.children
                                        .filter((item) => !item.hideInNavigation) // Filter out items with hideInNavigation: true
                                        .map((item, childIndex) => {
                                            const currentActiveItem = activeItems?.[index + 1];
                                            const isActiveClass =
                                                (currentActiveItem &&
                                                    item.path === currentActiveItem?.path) ||
                                                item.path === window.location.pathname
                                                    ? S.isActive
                                                    : undefined;
                                            const hasChildren = !!item.children?.filter(
                                                (x) => !x.hideInNavigation
                                            )?.[0];

                                            return (
                                                <div
                                                    className={cn(S.item, isActiveClass)}
                                                    key={childIndex}
                                                >
                                                    {hasChildren ? (
                                                        <button
                                                            className={S.button}
                                                            onClick={() =>
                                                                onLinkClicked(item, index + 2)
                                                            }
                                                        >
                                                            <span>{item.name}</span>
                                                            <DA_Icon
                                                                name={DA_IconNames.ChevronRight}
                                                                className={S.arrow}
                                                            />
                                                        </button>
                                                    ) : (
                                                        <DA_BasicLink
                                                            target={item.url ? "_blank" : "_self"}
                                                            onClick={toggleMobileNav}
                                                            href={item.url || item.path}
                                                            className={cn(S.link)}
                                                        >
                                                            {item.name}
                                                        </DA_BasicLink>
                                                    )}
                                                </div>
                                            );
                                        })}

                                    {/** Customer Service */}
                                    <div className={S.customerServiceLinks}>
                                        {props?.customerServiceLinks?.map((item, index) => (
                                            <div key={index}>
                                                <DA_BasicLink
                                                    target="_self"
                                                    href={item.href}
                                                    onClick={toggleMobileNav}
                                                    className={S.customerServiceLink}
                                                >
                                                    {item.title}
                                                </DA_BasicLink>
                                            </div>
                                        ))}
                                    </div>
                                </DA_Container>
                            </div>
                        );
                    })}
            </div>

            {/* Universal menu bottom links */}
            {Array.isArray(globalThis?.universalMenuArr) && (
                <ul className={S.bottomLinks}>
                    {globalThis?.universalMenuArr?.map((menu, index) => {
                        return (
                            <li key={index} className={S.linkItem}>
                                <a
                                    href={menu.url}
                                    target={menu.target || "_self"}
                                    className={S.link}
                                >
                                    <span>{menu.name}</span>
                                    <span>{getDictionaryString("goto")}</span>
                                </a>
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
};
