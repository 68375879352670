import React, { useState, useEffect } from "react";
import { DA_BasicLink } from "@danishagro/shared/src/components/atoms/BasicLink/BasicLink.component";
import { DA_Title } from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { getAbsoluteUrl } from "@helpers/getAbsoluteUrl.helper";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import { ImageViewer } from "src/components/ImageViewer/ImageViewer.component";
import { MoreEmpolyeesProps } from "./MoreEmployees.interface";
import S from "./MoreEmployees.module.scss";

export const MoreEmployees = ({ items }: MoreEmpolyeesProps) => {
    // Helper function to get n random items from an array
    const getRandom = (arr, n) => {
        const result = new Array(n);
        const copy = [...arr];

        // If there are less items than requested, return all items
        if (n > copy.length) return arr;

        while (n--) {
            const x = Math.floor(Math.random() * copy.length);
            result[n] = copy.splice(x, 1)[0]; // This will remove the item from copy and return it.
        }
        return result;
    };

    // Number of siblings employees to show
    const NUM_OF_EMPLOYEES_TO_SHOW = 3;

    // Create state variable for the random siblings
    const [randomSiblings, setRandomSiblings] = useState(() =>
        getRandom(items.siblings, NUM_OF_EMPLOYEES_TO_SHOW)
    );

    // Update randomSiblings state when items.siblings changes
    useEffect(() => {
        setRandomSiblings(getRandom(items.siblings, NUM_OF_EMPLOYEES_TO_SHOW));
    }, [items.siblings]);

    return (
        <>
            {items?.siblings && items.siblings.length > 0 ? (
                <div className={S.list}>
                    {items?.parentGroup ? (
                        <DA_Title h4 noMargin>
                            {items.parentGroup}
                        </DA_Title>
                    ) : null}
                    {randomSiblings.map((item, index) => (
                        <DA_BasicLink className={S.link} key={index} href={item.url}>
                            {/** Image */}
                            {item.props.thumbnail ? (
                                <div className={S.image}>
                                    <ImageViewer
                                        className={S.image}
                                        src={getAbsoluteUrl(
                                            item.props.thumbnail.props.umbracoFile,
                                            "UMBRACO"
                                        )}
                                    />
                                </div>
                            ) : null}

                            <div className={S.content}>
                                <div className={S.tags}>
                                    {item?.props?.tags.map((tag, index) => (
                                        <div className={S.tag} key={index}>
                                            {tag}
                                        </div>
                                    ))}
                                </div>

                                <strong className={S.headline}>{item.props.title}</strong>

                                <div className={S.text}>
                                    <DA_Text noPadding html={item?.props?.introductory} />
                                </div>
                            </div>
                        </DA_BasicLink>
                    ))}
                </div>
            ) : null}
        </>
    );
};
