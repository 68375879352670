import React, { Fragment, useCallback, useEffect, useState } from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { getLoginLink } from "@danishagro/shared/src/helpers/getLoginLink.helper";
import { useBottomSheet } from "@danishagro/shared/src/contexts/bottomSheet/bottomSheet.context";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { useStickyBannerMessages } from "@danishagro/shared/src/hooks/useStickyBannerMessages.hook";
import { DA_Badge } from "../Badge/Badge.component";
import { DA_BasicLink } from "../../../atoms/BasicLink/BasicLink.component";
import { DA_Icon, DA_IconNames } from "../../../atoms/Icon/Icon.component";
import { useScreen } from "../../../../contexts/screen.context";
import { useAppData } from "../../../../contexts/appData.context";
import { useTranslations } from "../../../../contexts/translations/translations.context";
import { useAccountButton } from "./hooks/useAccountButton";
import { useCartButton } from "./hooks/useCartButton";
import { useFarmInTimeButton } from "./hooks/useFarmInTimeButton";
import { useSearchButton } from "./hooks/useSearchButton";
import S from "./Toolbar.module.scss";

type DA_ToolbarProps = {
    isLoginOptionsTrue?: boolean;
};

export const DA_Toolbar = ({ isLoginOptionsTrue }: DA_ToolbarProps) => {
    const { isMobileOrTablet } = useScreen();
    const { currentSite, isFarmInTimeEnabled } = useAppData();
    const { getDictionaryString } = useTranslations();
    const { presentBottomSheet, dismissBottomSheet } = useBottomSheet();
    const messages = Array.isArray(globalThis?.announcementMessages)
        ? globalThis?.announcementMessages
        : null;
    const activeStickyBanners = useStickyBannerMessages(messages);
    const [stickyBannersToShow, setStickyBannersToShow] = useState([]);
    const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);

    // ExcellenceProgramme CMS
    const isExcellenceProgrammeCmsSite =
        typeof globalThis?.isUsingCustomAd === "string"
            ? globalThis.isUsingCustomAd.toLowerCase() === "true"
            : false;

    // Cart
    const { showCart, onCartButtonClicked, cartBadge, isCartMenuActive, isLoadingCart } =
        useCartButton();
    const isCartMenuActiveClass = isCartMenuActive ? S.isActive : undefined;

    // Farm In Time
    const { showFarmInTime, farmInTimeLink, isFarmInTimeMenuActive } = useFarmInTimeButton();
    const isFarmInTimeMenuActiveClass = isFarmInTimeMenuActive ? S.isActive : undefined;

    // Account
    const { onAccountButtonClicked, accountBadge, isAccountMenuActive } = useAccountButton();
    const isAccountMenuActiveClass = isAccountMenuActive ? S.isActive : undefined;

    // Search
    const { showSearch, onSearchButtonClicked, isSearchMenuActive } = useSearchButton();
    const isSearchMenuActiveClass = isSearchMenuActive ? S.isActive : undefined;

    // Message
    // Filter out banners already shown in the session
    const SHOW_STICKY_BANNER = "shownStickyBanners";
    const BOTTOM_SHEET_NAME = "sticky-banner";
    const SNAP_POINT_BOTTOM_SHEET = 200;

    useEffect(() => {
        const shownBanners = sessionStorage.getItem(SHOW_STICKY_BANNER)
            ? JSON.parse(sessionStorage.getItem(SHOW_STICKY_BANNER))
            : [];
        const bannersToShow = activeStickyBanners?.filter(
            (banner) => !shownBanners.includes(banner.id)
        );
        setStickyBannersToShow(bannersToShow);
    }, [activeStickyBanners]);

    // Common function to handle banner dismissal
    const dismissBanner = useCallback((bannerId) => {
        // Update session storage to mark this banner as shown
        const shownBanners = JSON.parse(sessionStorage.getItem(SHOW_STICKY_BANNER) || "[]");
        if (!shownBanners.includes(bannerId)) {
            shownBanners.push(bannerId);
            sessionStorage.setItem(SHOW_STICKY_BANNER, JSON.stringify(shownBanners));
        }

        // Remove this banner from the stickyBannersToShow
        setStickyBannersToShow((prev) => prev.filter((b) => b.id !== bannerId));
    }, []);

    const showStickyBannerMessage = useCallback(
        (banner) => {
            setIsBottomSheetOpen(true);
            // Present the bottom sheet with the banner content
            presentBottomSheet(
                <div className={S.bottomSheetContent}>
                    <span
                        className={S.bottomSheetText}
                        dangerouslySetInnerHTML={{
                            __html: banner.content,
                        }}
                    />
                    <DA_Button
                        className={S.bottomSheetCloseBtn}
                        title={getDictionaryString("OK")}
                        onClick={() => {
                            dismissBottomSheet();
                            setIsBottomSheetOpen(false);
                        }}
                    />
                </div>,
                {
                    id: `${BOTTOM_SHEET_NAME}-${banner.id}`,
                    contentPadding: true,
                    headerTitle: <>{banner.headline}</>,
                    snapPoints: [SNAP_POINT_BOTTOM_SHEET],
                }
            );
            dismissBanner(banner.id);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dismissBottomSheet, presentBottomSheet, dismissBanner]
    );

    const handleCloseBanner = useCallback(
        (event, bannerId) => {
            event.stopPropagation();
            dismissBanner(bannerId);
        },
        [dismissBanner]
    );

    return (
        <Fragment>
            {isMobileOrTablet ? (
                <div className={S.toolbar}>
                    {isMobileOrTablet &&
                        !isBottomSheetOpen &&
                        stickyBannersToShow?.map((banner) => (
                            <div
                                className={S.messageBottom}
                                key={banner.id}
                                onClick={() => showStickyBannerMessage(banner)}
                                onKeyPress={(e) =>
                                    e.key === "Enter" && showStickyBannerMessage(banner)
                                }
                                role="button"
                                tabIndex={0}
                            >
                                <button
                                    className={S.closeMessageBtn}
                                    onClick={(e) => handleCloseBanner(e, banner.id)}
                                    aria-label={getDictionaryString("close")}
                                >
                                    <DA_Icon className={S.closeIcon} name={DA_IconNames.Close} />
                                </button>
                                <div className={S.messageBottomBox}>
                                    <span className={S.messageTitle}>{banner.headline}</span>
                                    <span
                                        className={S.messageContent}
                                        dangerouslySetInnerHTML={{
                                            __html: banner.content,
                                        }}
                                    />
                                </div>
                            </div>
                        ))}

                    <div className={S.inner}>
                        {isExcellenceProgrammeCmsSite ? (
                            <DA_BasicLink
                                href="/logout"
                                target="_self"
                                conventionalRouting
                                className={S.buttonLink}
                            >
                                <DA_Icon name={DA_IconNames.SignOut} className={S.buttonIcon} />
                            </DA_BasicLink>
                        ) : (
                            <>
                                {/** Cart */}
                                {isLoginOptionsTrue && showCart ? (
                                    <button
                                        onClick={onCartButtonClicked}
                                        className={cn(S.button, isCartMenuActiveClass)}
                                        aria-label={getDictionaryString("accessibilityButtonCart")}
                                    >
                                        <DA_Icon
                                            name={DA_IconNames.CartWeighted}
                                            className={S.icon}
                                        />

                                        {/** Quantity Badge */}
                                        {isLoadingCart ? (
                                            <DA_Badge text="" className={S.badge} showSpinner />
                                        ) : cartBadge > 0 ? (
                                            <DA_Badge text={cartBadge} className={S.badge} />
                                        ) : null}
                                    </button>
                                ) : null}

                                {/** Farm In Time */}
                                {isFarmInTimeEnabled && isLoginOptionsTrue && showFarmInTime ? (
                                    <DA_BasicLink
                                        href={farmInTimeLink}
                                        target="_self"
                                        className={cn(S.button, isFarmInTimeMenuActiveClass)}
                                        ariaLabel={getDictionaryString(
                                            "accessibilityFarmInTimeLinkText"
                                        )}
                                    >
                                        <DA_Icon
                                            name={DA_IconNames.FormCheckWeighted}
                                            className={S.icon}
                                        />
                                    </DA_BasicLink>
                                ) : null}

                                {/** Account: Logged Out */}
                                {isLoginOptionsTrue && !globalThis.isAuthenticated ? (
                                    <DA_BasicLink
                                        href={getLoginLink(currentSite)}
                                        target="_self"
                                        className={S.button}
                                        ariaLabel={getDictionaryString("accessibilityMobileLogin")}
                                    >
                                        <DA_Icon
                                            name={DA_IconNames.UserLoggedOutWeighted}
                                            className={S.icon}
                                        />
                                    </DA_BasicLink>
                                ) : null}

                                {/** Account: Logged In */}
                                {isLoginOptionsTrue && globalThis.isAuthenticated ? (
                                    <button
                                        onClick={onAccountButtonClicked}
                                        className={cn(S.button, isAccountMenuActiveClass)}
                                        aria-label={getDictionaryString("account picker")}
                                    >
                                        <DA_Icon
                                            name={DA_IconNames.UserLoggedInWeighted}
                                            className={S.icon}
                                        />

                                        {/** Notifications Badge */}
                                        {accountBadge > 0 ? (
                                            <DA_Badge text={accountBadge} className={S.badge} />
                                        ) : null}
                                    </button>
                                ) : null}
                            </>
                        )}

                        {/** Search */}
                        {showSearch ? (
                            <button
                                onClick={onSearchButtonClicked}
                                className={cn(
                                    S.button,
                                    isSearchMenuActiveClass,
                                    !isLoginOptionsTrue && S.searchFullWidthBtn
                                )}
                                aria-label={getDictionaryString("accessibilityButtonSearch")}
                            >
                                <DA_Icon name={DA_IconNames.ZoomWeighted} className={S.icon} />
                            </button>
                        ) : null}
                    </div>
                </div>
            ) : null}
        </Fragment>
    );
};
