// GTM Tracking
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation
import { pushToDataLayer } from "./useTagManger";

const useTrackScrollDistance = (event: string) => {
    const rafRef = useRef(null);
    const milestonesHit = useRef(new Set());
    const location = useLocation();

    useEffect(() => {
        // Clear milestones when page changes
        milestonesHit.current.clear();
    }, [location]);

    useEffect(() => {
        const handleScroll = () => {
            if (rafRef.current) {
                cancelAnimationFrame(rafRef.current);
            }

            rafRef.current = requestAnimationFrame(() => {
                const scrolled = window.scrollY + window.innerHeight;
                const height = document.documentElement.offsetHeight;
                const percentageScrolled = (scrolled / height) * 100;

                let milestone;
                if (percentageScrolled >= 25 && !milestonesHit.current.has(25)) milestone = 25;
                else if (percentageScrolled >= 50 && !milestonesHit.current.has(50)) milestone = 50;
                else if (percentageScrolled >= 75 && !milestonesHit.current.has(75)) milestone = 75;
                else if (percentageScrolled >= 100 && !milestonesHit.current.has(100))
                    milestone = 100;

                if (milestone) {
                    milestonesHit.current.add(milestone);
                    pushToDataLayer({
                        event: event,
                        scroll_distance: String(milestone),
                    });
                }
            });
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
            if (rafRef.current) {
                cancelAnimationFrame(rafRef.current);
            }
        };
    }, [event]);
};

export default useTrackScrollDistance;
