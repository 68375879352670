import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { MessageType, MessageState } from "@danishagro/shared/src/content/message/messageEnums";

export const useStickyBannerMessages = (allMessages) => {
    const [activeStickyBanners, setActiveStickyBanners] = useState([]);
    const location = useLocation();

    useEffect(() => {
        const currentUrl = window.location.href;
        const now = new Date();
        const activeBanners = allMessages?.filter((msg) => {
            const isActive = msg?.messageState === MessageState.Active;
            const isInDateRange =
                new Date(msg?.activeFrom) <= now && now <= new Date(msg?.activeTo);
            const isNotDeactivated = !msg?.deactivated;
            const isStickyBanner = msg?.type === MessageType.StickyBanner;
            const isUrlMatch = msg?.urls.some((urlPattern) => {
                if (urlPattern.endsWith("*")) {
                    const basePattern = urlPattern.slice(0, -1);
                    return currentUrl.startsWith(basePattern);
                }
                return currentUrl === urlPattern;
            });
            return isActive && isInDateRange && isNotDeactivated && isStickyBanner && isUrlMatch;
        });
        setActiveStickyBanners(activeBanners);
    }, [allMessages, location]);

    return activeStickyBanners;
};
