import React from "react";
import { useSiteHeader } from "../../../../contexts/siteHeader.context";
import { DA_AccountPicker } from "../AccountPicker/AccountPicker.component";
import { DA_NotificationsAndAccountsPopUpProps } from "./NotificationsAndAccounts.props";
import S from "./NotificationsAndAccounts.module.scss";

export const DA_NotificationsAndAccountsPopUp = (props: DA_NotificationsAndAccountsPopUpProps) => {
    const { headerMeasure } = useSiteHeader();

    return (
        <div
            className={S.wrapper}
            style={{
                maxHeight: headerMeasure ? `calc(100vh - ${headerMeasure.height}px)` : undefined,
            }}
        >
            <DA_AccountPicker notificationCount={props.notificationCount} />
        </div>
    );
};
