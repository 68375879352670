import { useCallback, useEffect, useState } from "react";
import { getAbsoluteUrl } from "@helpers/getAbsoluteUrl.helper";
import { useLocation } from "react-router-dom";
import { JobsPageProps } from "../JobsPage.interface";

export const useJobDetail = () => {
    const [data, setData] = useState<JobsPageProps["talentRecruiterPositionContent"] | null>(null);
    const [viewState, setViewState] = useState<string>("INIT");

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const jobId = queryParams.get("jobId");

    const fetchJobDetail = useCallback(async () => {
        try {
            const url = getAbsoluteUrl(`/GetPosition?id=${jobId}`, "SPA");
            const request = await fetch(url);
            if (request) {
                const response = await request.json();
                return response;
            }
        } catch {
            setViewState("ERROR");
        }
    }, [jobId]);

    const getJobDetail = useCallback(async () => {
        try {
            let response;

            if (typeof globalThis.pageContent?.talentRecruiterDetailPage !== "undefined") {
                response = globalThis.pageContent?.talentRecruiterPositionContent;
            } else {
                response = await fetchJobDetail();
            }

            if (response?.id > 0) {
                setData(response);
                setViewState("DONE");
            } else if (response?.id === 0) {
                setData(undefined);
                setViewState("EMPTY");
            } else {
                throw new Error("No Data Found");
            }
        } catch (error) {
            setData(null);
            setViewState("ERROR");
        }
    }, [fetchJobDetail]);

    // Init

    useEffect(() => {
        getJobDetail();

        return () => {
            setData(null);
            setViewState("INIT");
        };
    }, [getJobDetail]);

    return { data, viewState };
};
