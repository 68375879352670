import { isDevMode } from "@danishagro/shared/src/helpers/isDevMode.helper";
import { isAbsoluteUrl } from "@danishagro/shared/src/helpers/isAbsoluteUrl.helper";
import { CMS_API_ROOT, CMS_CDN_ROOT } from "@danishagro/shared/src/constants/urlRoot.constants";
import { GET_CMS_SITE_ORIGIN } from "@danishagro/shared/src/helpers/urlRoot.helper";

export const getAbsoluteUrl = (
    pathname: string,
    platform: "SPA" | "UMBRACO" = "SPA",
    parameters?: { [name: string]: string }
) => {
    let url: URL;

    // No link given
    if (!pathname) {
        return;
    }

    // If light website (no cdn root) but has CDN wildcard
    else if (!CMS_CDN_ROOT && pathname.indexOf("{CDNURL}") > -1) {
        return pathname.replace("{CDNURL}", `${CMS_API_ROOT}/media/`);
    }

    // If light website and has cdn
    else if (CMS_CDN_ROOT && pathname.indexOf("{CDNURL}") > -1) {
        const replacedPathname = pathname.replace("{CDNURL}", "");
        url = new URL(replacedPathname, CMS_CDN_ROOT);
    }

    // Already is absolute
    else if (pathname && isAbsoluteUrl(pathname)) {
        url = new URL(pathname);
    }

    // Has /media/ in the url
    else if (pathname && pathname.indexOf("/media/") >= 0) {
        url = new URL(pathname, CMS_API_ROOT);
    }

    // Dev Environment (umbraco routes)
    else if (platform === "UMBRACO" && isDevMode) {
        url = new URL(pathname, CMS_API_ROOT);
    }

    // Dev Environment (spa routes)
    else if (platform === "SPA" && isDevMode) {
        url = new URL(pathname, GET_CMS_SITE_ORIGIN("ABSOLUTE"));
    }

    // Production Environment (umbraco routes)
    else if (platform === "UMBRACO") {
        url = new URL(pathname, GET_CMS_SITE_ORIGIN("ABSOLUTE"));
    }

    // Production Environment (spa routes)
    else if (platform === "SPA" && GET_CMS_SITE_ORIGIN("ABSOLUTE")) {
        url = new URL(pathname, GET_CMS_SITE_ORIGIN("ABSOLUTE"));
    }

    // Anything else
    else {
        url = new URL(pathname, window.location.origin);
    }

    // Extra Params

    if (parameters) {
        for (const key in parameters) {
            url.searchParams.append(key, parameters[key]);
        }
    }

    return url.toString();
};
