export enum MessageState {
    Active = "Active",
    Inactive = "Inactive",
    Deactivated = "Deactivated",
    Deleted = "Deleted",
}

export enum MessageType {
    StickyBanner = "StickyBanner",
    Modal = "Modal",
}

export enum CookieValue {
    True = "true",
    False = "false",
}
