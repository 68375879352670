import { useCallback, useEffect, useState } from "react";
import {
    DA_NavigationPage,
    DA_NavigationPageSimple,
} from "../../../../../interfaces/navigationPage.interface";
import { softHyphenate } from "../../../../../helpers/softHyphenate.helper";
import { useAppData } from "../../../../../contexts/appData.context";

export const useMyFarm = (pageTree: DA_NavigationPage[] | DA_NavigationPageSimple[]) => {
    const { currentSite } = useAppData();

    const [myfarmNavigation, setMyfarmNavigation] = useState<DA_NavigationPage[]>([]);

    const mapMyfarmNavigationPageUrls = useCallback(
        (pageArray: DA_NavigationPage[], parent = ""): DA_NavigationPage[] => {
            const pages = [];

            (pageArray || []).map((item) => {
                const path = [parent, item.page].join("/");

                const tmpPage: DA_NavigationPage = {
                    alias: item.alias,
                    children: [],
                    hideInNavigation: item.hideInNavigation,
                    modules: item.modules,
                    name: softHyphenate(item.name),
                    page: undefined,
                    path: path,
                    url: item.url,
                };

                if (item.children && item.children.length) {
                    tmpPage.children.push(...mapMyfarmNavigationPageUrls(item.children, path));
                }

                pages.push(tmpPage);
            });

            return pages;
        },
        []
    );

    // Init

    useEffect(() => {
        if (currentSite !== "MYFARM") return;

        if (pageTree) {
            // Mapping data to absolute urls
            const pages = mapMyfarmNavigationPageUrls(pageTree as DA_NavigationPage[]);
            setMyfarmNavigation(pages);
        }
    }, [mapMyfarmNavigationPageUrls, currentSite, pageTree]);

    return {
        myfarmNavigation,
    };
};
