import React from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import {
    DA_Title,
    DA_TitleTag,
} from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { getAbsoluteUrl } from "@helpers/getAbsoluteUrl.helper";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import useFormattedCultureDate from "@hooks/useFormattedCultureDate.hook";
import { ImageViewer } from "../../../../../../components/ImageViewer/ImageViewer.component";
import { useTags } from "../../hooks/useTags.hook";
import { CardProps } from "./Card.interface";
// eslint-disable-next-line css-modules/no-unused-class
import S from "./Card.module.scss";

export const Card = (props: CardProps) => {
    const { tagClass } = useTags(props.tags[0], S);
    const { getDictionaryString } = useTranslations();

    //use dateCulture from Umbraco global settings
    const publishedDate = useFormattedCultureDate(props?.publishedDate);

    return (
        <div className={props.className}>
            <div className={S.inner}>
                <div className={S.imageWrapper}>
                    {/** Image */}
                    <div className={S.image}>
                        {props.thumbnail ? (
                            <ImageViewer
                                className={S.image}
                                src={getAbsoluteUrl(props.thumbnail?.url)}
                            />
                        ) : null}
                    </div>

                    {/**Tags*/}
                    <div className={S.tags}>
                        {props.tags.map((tag, index) => (
                            <div
                                className={cn(S.tag, tagClass, !props.thumbnail && S.tagNoImage)}
                                key={index}
                            >
                                {tag}
                            </div>
                        ))}
                    </div>
                </div>

                {/**Content */}
                <div className={S.textWrapper}>
                    {/** Date */}
                    {props.publishedDate ? <span>{publishedDate}</span> : null}

                    {/** Title */}
                    <DA_Title h3 tag={DA_TitleTag.H3} noMargin>
                        {props.title}
                    </DA_Title>

                    {props.introductory ? (
                        <div className={S.text}>
                            <DA_Text noPadding html={props.introductory} />{" "}
                        </div>
                    ) : null}

                    {/** Button */}
                    {/*// News open in new tab*/}
                    <div>
                        <DA_Button
                            title={getDictionaryString("General.Read")}
                            href={props.url}
                            target="_blank"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card;
