import { useCallback } from "react";
import { GroupBase } from "react-select";
import { DA_SelectOption, DA_SelectProps, DA_SelectValue } from "../Select.props";
import { useConvertOption } from "./useConvertOption.hook";

type Props<T> = Pick<DA_SelectProps<T>, "options" | "getLabel" | "getValue">;

export const useConvertValue = <T>({ options, getLabel, getValue }: Props<T>) => {
    const convertOption = useConvertOption({ getLabel, getValue });
    return useCallback(
        (target?: DA_SelectValue): DA_SelectOption =>
            target
                ? convertOption(
                      // @ts-ignore
                      typeof options[0].options !== "undefined"
                          ? (options as GroupBase<T>[]).find((group) =>
                                group.options.find(
                                    (option) =>
                                        (getValue
                                            ? getValue(option)
                                            : // @ts-ignore
                                              option.value) === target
                                )
                            )
                          : (options as T[]).find(
                                (option) =>
                                    (getValue
                                        ? getValue(option)
                                        : // @ts-ignore
                                          option.value) === target
                            )
                  )
                : undefined,
        [options, convertOption, getValue]
    );
};
