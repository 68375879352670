import { DA_DictionaryTranslationOptions } from "../interfaces/translations.interface";
import { uppercaseFirstLetter } from "./uppercaseFirstLetter.helper";
import { softHyphenate } from "./softHyphenate.helper";
import { markLinksFromString } from "./markLinksFromStrings.helper";
import { getUrl } from "./getUrl.helper";

const DICTIONARY_FALLBACK = { frontpage: "Forside" };

const replaceLinkAliases = (_match: string, newTab: string, group: string) => {
    // Split the grouped string into alias and text
    const [linkAlias, linkText] = group.split(",");

    // Get the URL from the url list
    const link = getUrl(linkAlias);

    // If newTab has a value, open in new tab
    const isTargetBlank = !!newTab;

    const attributes = [
        // Set href
        `href="${link || "#"}"`,
        // If no href, mark link as unknown
        !link ? `class="unknown-link" tabindex="-1"` : "",
        // Open in new tab - or set a data-attribute telling us
        // to use the React Router if the link is internal
        isTargetBlank
            ? `target="_blank"`
            : link?.startsWith("/")
            ? `data-internal-link-from-string="true"`
            : "",
    ];

    return `<a ${attributes.join(" ")}>${linkText}</a>`;
};

export const handleTranslation = (
    alias: string,
    string: string | undefined,
    options: DA_DictionaryTranslationOptions = {}
): string => {
    const { uppercaseFirst, fallback, ...replaceStrings } = options;
    const fallbackString = fallback ?? alias ?? "";
    const regex = new RegExp("{{" + Object.keys(replaceStrings).join("}}|{{") + "}}", "g");

    const returnString1 =
        typeof string !== "undefined" ? markLinksFromString(softHyphenate(string)) : fallbackString;

    const returnString2 = returnString1
        .replace(/\{\{link(newtab)?:(.*?)}}/gi, replaceLinkAliases)
        .replace(regex, (match) => String(replaceStrings[match.slice(2, -2)]) || "");

    return uppercaseFirst ? uppercaseFirstLetter(returnString2) : returnString2;
};

// TODO: Optimize this to not re-render dictionary every time.
const toLowerKeys = (obj) =>
    Object.keys(obj).reduce((accumulator, key) => {
        accumulator[key.toLowerCase()] = obj[key];
        return accumulator;
    }, {});

export const _getDictionaryString = (
    alias: string,
    highlightMissingItems: boolean,
    options: DA_DictionaryTranslationOptions = {},
    translations?: { [key: string]: string }
): string => {
    const dictionary = translations ?? globalThis.dictionaryObj?.translations;
    const lowercaseAlias = alias ? alias.toLowerCase() : "";
    const string = dictionary
        ? toLowerKeys(dictionary)[lowercaseAlias]
        : toLowerKeys(DICTIONARY_FALLBACK)[lowercaseAlias];
    const stringExist = typeof string !== "undefined";

    if (!stringExist && options?.dontShowFallback) {
        return "";
    }

    const returnString = handleTranslation(alias, string, {
        ...options,
        uppercaseFirst: !stringExist && highlightMissingItems ? false : options.uppercaseFirst,
    });

    return highlightMissingItems && !stringExist ? `{{${returnString}}}` : returnString;
};
