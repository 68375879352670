import { useMemo } from "react";
import S from "./useBackgroundColor.module.scss";

export const useBackgroundColor = (background: string) => {
    const backgroundColorClass = useMemo(() => {
        switch (background) {
            case "BACKGROUND_PRIMARY":
                return S.backgroundPrimary;
            case "BACKGROUND_ACCENT":
                return S.backgroundAccent;
            case "BACKGROUND_GREY_10":
                return S.backgroundGrey;
            default:
                return S.backgroundWhite;
        }
    }, [background]);

    const alternativeButtonTheme = useMemo(() => {
        switch (background) {
            case "BACKGROUND_PRIMARY":
                return "FULL_PRIMARY";
            case "BACKGROUND_ACCENT":
                return "FULL_ACCENT";
            default:
                return undefined;
        }
    }, [background]);

    const hasBackgroundColor = useMemo(() => {
        // Include default white background in the hasBackgroundColor check
        return [
            "BACKGROUND_PRIMARY",
            "BACKGROUND_ACCENT",
            "BACKGROUND_GREY_10",
            "BACKGROUND_WHITE",
        ].includes(background);
    }, [background]);

    return {
        backgroundColorClass,
        alternativeButtonTheme,
        hasBackgroundColor,
    };
};
