import React from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_FooterObject } from "../../../../interfaces/footerObject.interface";
import { DA_BasicLink } from "../../../atoms/BasicLink/BasicLink.component";
import { DA_Icon, DA_IconNames } from "../../../atoms/Icon/Icon.component";
import { DA_Container } from "../../../atoms/Container/Container.component";
import { _FooterLink } from "../FooterLink/FooterLink.component";
import {
    AddressType,
    TextType,
    LinkType,
    SoMeType,
} from "../../../../interfaces/footerObject.interface";
import S from "./DesktopFooter.module.scss";

export interface DA_DesktopFooterProps {
    data: DA_FooterObject;
}

export const DA_DesktopFooter = ({ data }: DA_DesktopFooterProps) => {
    const isFooterAddress = (
        item: TextType | LinkType | AddressType | SoMeType
    ): item is AddressType => {
        return item.itemType === "footerAddress";
    };

    return (
        <footer className={S.desktopFooter}>
            <DA_Container>
                <div className={S.desktopFooterInner}>
                    {data?.columns?.map((column, index) => (
                        <div key={index} className={S.desktopFooterColumn}>
                            <div className={S.desktopFooterItem}>
                                <span className={S.desktopFooterItemTitle}>{column.header}</span>
                                {column?.items?.map((item, index) => {
                                    if (item.itemType === "text") {
                                        // If both "title" and "text" exist: place them next to each other like columns
                                        // If only "title" exists: CSS will make sure it uses the full width
                                        // If only "text" exists: don't prevent the text from wrapping
                                        return (
                                            <div key={index} className={S.footerItemWrapper}>
                                                {item.title ? (
                                                    <span
                                                        className={S.title}
                                                        dangerouslySetInnerHTML={{
                                                            __html: item.title,
                                                        }}
                                                    />
                                                ) : null}
                                                {item.text ? (
                                                    <span
                                                        className={
                                                            item.title ? S.noWrap : undefined
                                                        }
                                                        dangerouslySetInnerHTML={{
                                                            __html: item.text,
                                                        }}
                                                    />
                                                ) : null}
                                            </div>
                                        );
                                    }
                                    if (isFooterAddress(item)) {
                                        return (
                                            <div
                                                key={index}
                                                itemScope
                                                itemType="http://schema.org/Organization"
                                                className={S.addressInfoWrapper}
                                            >
                                                {item.company && (
                                                    <span
                                                        itemProp="name"
                                                        className={S.addressInfo}
                                                        dangerouslySetInnerHTML={{
                                                            __html: item.company,
                                                        }}
                                                    />
                                                )}
                                                {item.address1 && (
                                                    <span
                                                        itemProp="streetAddress"
                                                        className={S.addressInfo}
                                                        dangerouslySetInnerHTML={{
                                                            __html: item.address1,
                                                        }}
                                                    />
                                                )}
                                                {item.address2 && (
                                                    <span
                                                        itemProp="streetAddress"
                                                        className={S.addressInfo}
                                                        dangerouslySetInnerHTML={{
                                                            __html: item.address2,
                                                        }}
                                                    />
                                                )}
                                                {item.zipCity && (
                                                    <span className={S.addressInfo}>
                                                        <span itemProp="postalCode">
                                                            {item.zipCity.split(" ")[0]}
                                                        </span>
                                                        <span itemProp="addressLocality">{` ${
                                                            item.zipCity.split(" ")[1]
                                                        }`}</span>
                                                    </span>
                                                )}
                                                {item.country && (
                                                    <span
                                                        itemProp="addressCountry"
                                                        className={S.addressInfo}
                                                    >
                                                        {item.country}
                                                    </span>
                                                )}
                                                {item.cvr && (
                                                    <span
                                                        itemProp="vatID"
                                                        className={S.addressInfo}
                                                    >
                                                        {item.cvr}
                                                    </span>
                                                )}
                                                {item.email && (
                                                    <span
                                                        itemProp="email"
                                                        className={S.addressInfo}
                                                    >
                                                        {item.email}
                                                    </span>
                                                )}
                                                {item.phone && (
                                                    <span
                                                        itemProp="telephone"
                                                        className={S.addressInfo}
                                                    >
                                                        {item.phone}
                                                    </span>
                                                )}
                                            </div>
                                        );
                                    }

                                    if (item.itemType === "link") {
                                        return (
                                            <div key={index} className={S.footerItemWrapper}>
                                                <_FooterLink
                                                    key={index}
                                                    {...item}
                                                    classname={S.footerLink}
                                                />
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        </div>
                    ))}
                </div>

                {data?.socials?.map((socials, index) => (
                    <div key={index} className={cn(S.desktopFooterItem, S.noMarginBottom)}>
                        <span className={S.desktopFooterItemTitle}>{socials.header}</span>
                        <div className={S.soMeLinks}>
                            {socials.items.map((item) => {
                                const iconName =
                                    item.type === "facebook"
                                        ? DA_IconNames.Facebook
                                        : item.type === "linkedin"
                                        ? DA_IconNames.LinkedIn
                                        : item.type === "instagram"
                                        ? DA_IconNames.Instagram
                                        : item.type === "youtube"
                                        ? DA_IconNames.YouTube
                                        : DA_IconNames.Question;

                                return (
                                    <DA_BasicLink
                                        href={item.href}
                                        key={item.type}
                                        className={S.soMeLink}
                                        ariaLabel={item.type}
                                    >
                                        <DA_Icon name={iconName} />
                                    </DA_BasicLink>
                                );
                            })}
                        </div>
                    </div>
                ))}
            </DA_Container>
        </footer>
    );
};
