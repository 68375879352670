/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo } from "react";
import { DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { EmployeeProps } from "@danishagro/cms/src/blocks/components/Employees/components/Employee/Employee.interface";
import { EmployeesProps } from "@danishagro/cms/src/blocks/components/Employees/Employees.interface";
import { getAbsoluteUrl } from "@helpers/getAbsoluteUrl.helper";
import { HeroProps } from "@danishagro/cms/src/blocks/components/Hero/Hero.interface";
import { QuotesProps } from "@danishagro/cms/src/blocks/components/Quotes/Quotes.interface";
import { TextAccordionsProps } from "@danishagro/cms/src/blocks/components/TextAccordions/TextAccordions.interface";
import { TextImageProps } from "@danishagro/cms/src/blocks/components/TextImage/TextImage.interface";
import { TextProps } from "@danishagro/cms/src/blocks/components/Text/Text.interface";
import { TextTableProps } from "@danishagro/cms/src/blocks/components/TextTable/TextTable.interface";
import { TextTextProps } from "@danishagro/cms/src/blocks/components/TextText/TextText.interface";
import { AdvancedTableProps } from "../components/AdvancedTable/AdvancedTable.interface";
import { BlockEmbedProps } from "../components/Embed/Embed.interface";
import { BlockImageProps } from "../components/Image/Image.interface";
import { BlockVideoProps } from "../components/Video/Video.interface";
import { CardProps } from "../components/ContentList/components/Card/Card.interface";
import { ContentListProps } from "../components/ContentList/ContentList.interface";
import { DepartmentProps } from "../components/DepartmentMap/components/Department/Department.interface";
import { NewsSliderProps } from "../components/NewsSlider/NewsSlider.interface";
import { EventsSliderProps } from "../components/EventsSlider/EventsSlider.interface";
import { TabsProps } from "../components/Tabs/Tabs.interface";
import { TextFormProps } from "../components/TextForm/TextForm.interface";
import { TextGraphicsProps } from "../components/TextGraphics/TextGraphics.interface";
import { TextImageBannerProps } from "../components/TextImageBanner/TextImageBanner.interface";
import { TextVideoProps } from "../components/TextVideo/TextVideo.interface";

export const useBlockDataMapping = (alias: string, data: any) => {
    const getIcon = useCallback((alias: string) => {
        switch (alias) {
            case "Shopping basket":
                return DA_IconNames.Cart;
            case "Cog wheel":
                return DA_IconNames.CogwheelStroke;
            case "Delivery truck":
                return DA_IconNames.Delivery;
            case "Delivery trolley":
                return DA_IconNames.DollyStroke;
            case "Harvest straw":
                return DA_IconNames.Harvest;
            case "Harvest machine":
                return DA_IconNames.Harvester;
            case "Barcode":
                return DA_IconNames.Barcode;
            case "Bell":
                return DA_IconNames.Bell;
            case "Clock":
                return DA_IconNames.Clock;
            case "Smartphone":
                return DA_IconNames.Mobile;
            case "Email envelope":
                return DA_IconNames.LetterOutlined;
            case "Location pin":
                return DA_IconNames.Pin;
            case "Checklist board":
                return DA_IconNames.FormCheck;
            case "PDF document":
                return DA_IconNames.Pdf;
            case "Rosette":
                return DA_IconNames.Rosette;
            case "Pencil":
                return DA_IconNames.Pencil;
            case "Support":
                return DA_IconNames.Support;
            case "Tractor":
                return DA_IconNames.Tractor;
            case "Facebook":
                return DA_IconNames.Facebook;
            case "LinkedIn":
                return DA_IconNames.LinkedIn;
        }
    }, []);

    const mapButtons = useCallback(
        (buttons: any) => {
            return buttons?.map((button) => {
                return {
                    alias: button.content.alias,
                    label: button.content.props.label || button.content.props?.link?.name,
                    url: button.content.props?.link?.url,
                    icon: getIcon(button.content.props?.icon),
                    target: button.content.props?.link?.target,
                };
            });
        },
        [getIcon]
    );

    const mapImage = useCallback((props: any) => {
        // Has local image
        if (props?.image?.url) {
            return {
                name: props?.image?.name,
                url: getAbsoluteUrl(props?.image?.url),
                width: props?.image?.props?.umbracoWidth,
                height: props?.image?.props?.umbracoHeight,
                extension: props?.image?.props?.umbracoExtension,
            };
        }

        // Or has digizuite image
        if (props?.digizuiteImage?.url) {
            return {
                name: props?.digizuiteImage?.name,
                alternative: props?.digizuiteImage?.alternative,
                url: getAbsoluteUrl(props?.digizuiteImage?.url),
                width: props?.digizuiteImage?.umbracoWidth,
                height: props?.digizuiteImage?.umbracoHeight,
                extension: props?.digizuiteImage?.umbracoExtension,
            };
        }

        return {};
    }, []);

    const getExtra = useCallback(
        (setting: "blockSettings" | "settings", ...aliases: string[]) => {
            let existingExtra: string = undefined;

            if (typeof aliases !== "undefined") {
                aliases.forEach((alias) => {
                    const tmpExistingExtra = data?.content?.props?.[setting]?.find((x) => {
                        return x.toUpperCase() === alias.toUpperCase();
                    });

                    if (typeof tmpExistingExtra !== "undefined") {
                        existingExtra = tmpExistingExtra;
                    }
                });
            }

            return existingExtra;
        },
        [data]
    );

    return useMemo(() => {
        switch (alias) {
            /**********/
            /** Hero **/
            /**********/
            case "blockHero": {
                return {
                    // Content
                    logo: data?.content?.props?.logo?.url && {
                        name: data?.content?.props?.logo?.name,
                        url: getAbsoluteUrl(data?.content?.props?.logo?.url),
                        width: data?.content?.props?.logo?.props?.umbracoWidth,
                        height: data?.content?.props?.logo?.props?.umbracoHeight,
                        extension: data?.content?.props?.logo?.props?.umbracoExtension,
                    },

                    tagline: data?.content?.props?.tagline,
                    headline: data?.content?.props?.headline,
                    text: data?.content?.props?.text,
                    buttons: mapButtons(data?.content?.props?.buttons),
                    alternative: data?.content?.props?.alternative,

                    // Video
                    video: {
                        alias:
                            data?.content?.props?.video?.[0]?.content?.alias === "videoDigizuite"
                                ? "digizuite"
                                : data?.content?.props?.video?.[0]?.content?.alias ===
                                  "blockHeroVideoLocal"
                                ? "local"
                                : undefined,

                        url:
                            data?.content?.props?.video?.[0]?.content?.alias === "videoDigizuite"
                                ? data?.content?.props?.video?.[0]?.content?.props?.videoFile?.url
                                : data?.content?.props?.video?.[0]?.content?.alias ===
                                  "blockHeroVideoLocal"
                                ? data?.content?.props?.video?.[0]?.content?.props?.videoFile?.url
                                : undefined,

                        caption: data?.content?.props?.caption,
                        alternative: data?.content?.props?.alternative,
                        autoplay: true,
                    },

                    // Image
                    image: data?.content?.props?.image?.url
                        ? {
                              name: data?.content?.props?.image?.name,
                              url: getAbsoluteUrl(data?.content?.props?.image?.url),
                              width: data?.content?.props?.image?.props?.umbracoWidth,
                              height: data?.content?.props?.image?.props?.umbracoHeight,
                              extension: data?.content?.props?.image?.props?.umbracoExtension,
                          }
                        : undefined,

                    // Extras
                    backgroundColor: getExtra(
                        "blockSettings",
                        "BACKGROUND_PRIMARY",
                        "BACKGROUND_ACCENT",
                        "BACKGROUND_GREY_10"
                    ),
                    filter: getExtra(
                        "settings",
                        "FILTER_BLACK_BACKGROUND",
                        "FILTER_WHITE_BACKGROUND"
                    ),
                    position: getExtra("settings", "POSITION_IMAGE_LEFT", "POSITION_IMAGE_RIGHT"),
                } as HeroProps;
            }

            /**********/
            /** Tabs **/
            /**********/
            case "blockTabs": {
                const tabs = data?.content?.props?.tabs?.map((tab) => {
                    return {
                        key: tab.content?.key,
                        modules: tab.content?.props?.modules,
                        title: tab.content?.props?.title,
                    };
                });

                return {
                    tabs: tabs,

                    // Extras
                    backgroundColor: getExtra(
                        "blockSettings",
                        "BACKGROUND_PRIMARY",
                        "BACKGROUND_ACCENT",
                        "BACKGROUND_GREY_10"
                    ),
                } as TabsProps;
            }

            /**********/
            /** Text **/
            /**********/
            case "blockText": {
                return {
                    // Content
                    tagline: data?.content?.props?.tagline,
                    headline: data?.content?.props?.headline,
                    text: data?.content?.props?.text,
                    buttons: mapButtons(data?.content?.props?.buttons),

                    // Extras
                    backgroundColor: getExtra(
                        "blockSettings",
                        "BACKGROUND_PRIMARY",
                        "BACKGROUND_ACCENT",
                        "BACKGROUND_GREY_10"
                    ),
                    alignment: getExtra("settings", "ALIGNMENT_CENTER"),
                } as TextProps;
            }

            /**********/
            /** Text **/
            /**********/
            case "blockTextGraphics": {
                const regions = data?.content?.props?.graphics?.[0]?.content.props?.regions?.map(
                    (item) => {
                        const logos = item.content.props.logos?.map((logo) => {
                            return {
                                name: logo.content?.props?.logoImage?.name,
                                url: getAbsoluteUrl(logo.content?.props?.logoImage?.url),
                                width: logo.content.props.logoImage.props.umbracoWidth,
                                height: logo.content.props.logoImage.props.umbracoHeight,
                                extension: logo.content?.props?.logoImage?.umbracoExtension,
                                caption: logo.content?.props?.logoImage?.caption,
                                alternative: logo.content?.props?.logoImage?.alternative,
                            };
                        });

                        return {
                            regionID: item.content.props.regionID,
                            headline: item.content.props.headline,
                            text: item.content.props.text,
                            logos: logos,
                            link: {
                                name: item?.content?.props?.link?.name,
                                url: item?.content?.props?.link?.url,
                                target: item?.content?.props?.link?.target,
                            },
                        };
                    }
                );

                // Transform graphics into an array of GraphicsContent
                const graphics = data?.content?.props?.graphics
                    ?.map((graphic) => {
                        if (graphic.content.alias === "europe") {
                            return {
                                alias: "europe",
                                key: graphic.content.key,
                                props: {
                                    regions: regions,
                                },
                            };
                        } else if (graphic.content.alias === "lottie") {
                            const lottieURL = graphic.content.props.lottieURL;
                            const lottieId = graphic.content.props.lottieFile?.id;
                            const umbracoFile =
                                graphic.content.props.lottieFile?.props?.umbracoFile;

                            return {
                                alias: "lottie",
                                key: graphic.content.key,
                                props: {
                                    lottieURL,
                                    umbracoFile,
                                    lottieId,
                                },
                            };
                        }

                        return undefined;
                    })
                    .filter(Boolean);

                return {
                    // Content
                    tagline: data?.content?.props?.tagline,
                    headline: data?.content?.props?.headline,
                    text: data?.content?.props?.text,
                    buttons: mapButtons(data?.content?.props?.buttons),
                    graphics: graphics, // Assign the transformed graphics array
                    regions: regions,
                    // Extras
                    backgroundColor: getExtra(
                        "blockSettings",
                        "BACKGROUND_PRIMARY",
                        "BACKGROUND_ACCENT",
                        "BACKGROUND_GREY_10"
                    ),
                    alignment: getExtra("settings", "ALIGNMENT_CENTER"),
                    position: getExtra(
                        "settings",
                        "POSITION_GRAPHIC_LEFT",
                        "POSITION_GRAPHIC_BOTTOM"
                    ),
                } as TextGraphicsProps;
            }

            /***************/
            /** News slider **/
            /***************/

            case "blockNewsSlider": {
                return {
                    // Content
                    tagline: data?.content?.props.tagline,
                    headline: data?.content?.props?.headline,
                    text: data?.content?.props?.text,

                    //Link
                    label: data?.content?.props?.label,
                    link: {
                        label: data?.content?.props?.link?.label,
                        url: data?.content?.props?.link?.url,
                    },

                    //Extras
                    alignment: getExtra("settings", "ALIGNMENT_LEFT"),
                } as NewsSliderProps;
            }

            /***************/
            /** News slider **/
            /***************/

            case "blockEventsSlider": {
                return {
                    // Content
                    tagline: data?.content?.props.tagline,
                    headline: data?.content?.props?.headline,
                    text: data?.content?.props?.text,

                    //Link
                    label: data?.content?.props?.label,
                    link: {
                        label: data?.content?.props?.link?.label,
                        url: data?.content?.props?.link?.url,
                    },

                    //Extras
                    alignment: getExtra("settings", "ALIGNMENT_LEFT"),
                } as EventsSliderProps;
            }

            /********************/
            /** Text and Image **/
            /********************/
            case "blockTextImage": {
                return {
                    // Content
                    tagline: data?.content?.props?.tagline,
                    headline: data?.content?.props?.headline,
                    text: data?.content?.props?.text,
                    buttons: mapButtons(data?.content?.props?.buttons),
                    authorName: data?.content?.props?.authorName,
                    authorTitle: data?.content?.props?.authorTitle,
                    authorImageAlias: data?.content?.props?.authorImage?.alias,
                    authorImageName: data?.content?.props?.authorImage?.name,
                    authorImageId: data?.content?.props?.authorImage?.id,
                    authorImageUrl: data?.content?.props?.authorImage?.url,
                    date: data?.content?.props?.date,

                    // Image
                    image: {
                        ...mapImage(data?.content?.props),
                        caption: data?.content?.props?.caption,
                        alternative: data?.content?.props?.alternative,
                    },

                    dropdownImageSize: data?.content?.props?.imageSizes,

                    // Extras
                    backgroundColor: getExtra(
                        "blockSettings",
                        "BACKGROUND_PRIMARY",
                        "BACKGROUND_ACCENT",
                        "BACKGROUND_GREY_10"
                    ),
                    position: getExtra("settings", "POSITION_IMAGE_LEFT", "POSITION_IMAGE_BOTTOM"),
                    imageSize: getExtra("settings", "WIDE_IMAGE"),
                } as TextImageProps;
            }

            /********************/
            /** Route Plan **/
            /********************/
            case "blockRoutePlan": {
                const imageProps = data?.content?.props?.image
                    ? mapImage(data?.content?.props)
                    : null;
                return {
                    // Content
                    headline: data?.content?.props?.headline,
                    text: data?.content?.props?.text,
                    buttons: mapButtons(data?.content?.props?.buttons),

                    // Image
                    image: imageProps
                        ? {
                              ...imageProps,
                              alternative: data?.content?.props?.alternative,
                          }
                        : null,

                    // Extras
                    backgroundColor: getExtra(
                        "blockSettings",
                        "BACKGROUND_PRIMARY",
                        "BACKGROUND_ACCENT",
                        "BACKGROUND_GREY_10"
                    ),
                    position: getExtra("settings", "POSITION_IMAGE_LEFT", "POSITION_IMAGE_BOTTOM"),
                    imageSize: getExtra("settings", "WIDE_IMAGE"),
                } as TextImageProps;
            }

            /***********/
            /** Image **/
            /***********/
            case "blockImage": {
                return {
                    // Image
                    image: {
                        ...mapImage(data?.content?.props),
                        caption: data?.content?.props?.caption,
                        alternative: data?.content?.props?.alternative,
                    },
                    dropdownImageSize: data?.content?.props?.imageSizes,

                    // Extras
                    backgroundColor: getExtra(
                        "blockSettings",
                        "BACKGROUND_PRIMARY",
                        "BACKGROUND_ACCENT",
                        "BACKGROUND_GREY_10"
                    ),
                } as BlockImageProps;
            }

            /***********/
            /** Embed **/
            /***********/
            case "blockEmbed": {
                return {
                    type: data?.content?.props.type?.[0],
                    variable1: data?.content?.props.variable1,
                    variable2: data?.content?.props.variable2,
                    variable3: data?.content?.props.variable3,
                    variable4: data?.content?.props.variable4,
                    topDanmarkEmbedKey: data?.content?.props?.topDanmarkEmbedKey,

                    // Extras
                    backgroundColor: getExtra(
                        "blockSettings",
                        "BACKGROUND_PRIMARY",
                        "BACKGROUND_ACCENT",
                        "BACKGROUND_GREY_10"
                    ),
                } as BlockEmbedProps;
            }

            /********************/
            /** Text and Video **/
            /********************/
            case "blockTextVideo": {
                return {
                    // Content
                    tagline: data?.content?.props?.tagline,
                    headline: data?.content?.props?.headline,
                    text: data?.content?.props?.text,
                    buttons: mapButtons(data?.content?.props?.buttons),

                    // Video
                    video: {
                        alias:
                            data?.content?.props?.video?.[0]?.content?.alias === "videoYouTube"
                                ? "youtube"
                                : data?.content?.props?.video?.[0]?.content?.alias === "videoVimeo"
                                ? "vimeo"
                                : data?.content?.props?.video?.[0]?.content?.alias === "videoLocal"
                                ? "local"
                                : data?.content?.props?.video?.[0]?.content?.alias ===
                                  "videoDigizuite"
                                ? "digizuite"
                                : undefined,
                        url:
                            data?.content?.props?.video?.[0]?.content?.alias === "videoYouTube"
                                ? data?.content?.props?.video?.[0].content?.props?.videoURL
                                : data?.content?.props?.video?.[0]?.content?.alias === "videoVimeo"
                                ? data?.content?.props?.video?.[0].content?.props?.videoURL
                                : data?.content?.props?.video?.[0]?.content?.alias === "videoLocal"
                                ? data?.content?.props?.video?.[0]?.content?.props?.videoFile?.url
                                : data?.content?.props?.video?.[0]?.content?.alias ===
                                  "videoDigizuite"
                                ? data?.content?.props?.video?.[0]?.content?.props?.videoFile?.url
                                : undefined,

                        caption: data?.content?.props?.caption,
                        alternative: data?.content?.props?.alternative,
                    },
                    posterStart: data?.content?.props?.video?.[0]?.content?.props?.posterStart
                        ? {
                              name: data?.content?.props?.video?.[0]?.content?.props?.posterStart
                                  ?.name,
                              url: getAbsoluteUrl(
                                  data?.content?.props?.video?.[0]?.content?.props?.posterStart?.url
                              ),
                              width: data?.content?.props?.video?.[0]?.content?.props?.posterStart
                                  ?.props?.umbracoWidth,
                              height: data?.content?.props?.video?.[0]?.content?.props?.posterStart
                                  ?.props?.umbracoHeight,
                              extension:
                                  data?.content?.props?.video?.[0]?.content?.props?.posterStart
                                      ?.props?.umbracoExtension,
                          }
                        : undefined,
                    posterEnd: data?.content?.props?.video?.[0]?.content?.props?.posterEnd
                        ? {
                              name: data?.content?.props?.video?.[0]?.content?.props?.posterEnd
                                  ?.name,
                              url: getAbsoluteUrl(
                                  data?.content?.props?.video?.[0]?.content?.props?.posterEnd?.url
                              ),
                              width: data?.content?.props?.video?.[0]?.content?.props?.posterEnd
                                  ?.props?.umbracoWidth,
                              height: data?.content?.props?.video?.[0]?.content?.props?.posterEnd
                                  ?.props?.umbracoHeight,
                              extension:
                                  data?.content?.props?.video?.[0]?.content?.props?.posterEnd?.props
                                      ?.umbracoExtension,
                          }
                        : undefined,

                    // Extras
                    backgroundColor: getExtra(
                        "blockSettings",
                        "BACKGROUND_PRIMARY",
                        "BACKGROUND_ACCENT",
                        "BACKGROUND_GREY_10"
                    ),
                    position: getExtra("settings", "POSITION_VIDEO_LEFT", "POSITION_VIDEO_BOTTOM"),
                    alignment: getExtra("settings", "ALIGNMENT_CENTER"),
                    videoSize: getExtra("settings", "VIDEO_SIZE_SMALL", "VIDEO_SIZE_MEDIUM"),
                } as TextVideoProps;
            }

            /***********/
            /** Video **/
            /***********/
            case "blockVideo": {
                return {
                    // Video
                    video: {
                        alias:
                            data?.content?.props?.video?.[0]?.content?.alias === "videoYouTube"
                                ? "youtube"
                                : data?.content?.props?.video?.[0]?.content?.alias === "videoVimeo"
                                ? "vimeo"
                                : data?.content?.props?.video?.[0]?.content?.alias === "videoLocal"
                                ? "local"
                                : data?.content?.props?.video?.[0]?.content?.alias ===
                                  "videoDigizuite"
                                ? "digizuite"
                                : undefined,

                        url:
                            data?.content?.props?.video?.[0]?.content?.alias === "videoYouTube"
                                ? data?.content?.props?.video?.[0].content?.props?.videoURL
                                : data?.content?.props?.video?.[0]?.content?.alias === "videoVimeo"
                                ? data?.content?.props?.video?.[0].content?.props?.videoURL
                                : data?.content?.props?.video?.[0]?.content?.alias === "videoLocal"
                                ? data?.content?.props?.video?.[0]?.content?.props?.videoFile?.url
                                : data?.content?.props?.video?.[0]?.content?.alias ===
                                  "videoDigizuite"
                                ? data?.content?.props?.video?.[0]?.content?.props?.videoFile?.url
                                : undefined,

                        caption: data?.content?.props?.caption,
                        alternative: data?.content?.props?.alternative,
                    },
                    posterStart: data?.content?.props?.video?.[0]?.content?.props?.posterStart
                        ? {
                              name: data?.content?.props?.video?.[0]?.content?.props?.posterStart
                                  ?.name,
                              url: getAbsoluteUrl(
                                  data?.content?.props?.video?.[0]?.content?.props?.posterStart?.url
                              ),
                              width: data?.content?.props?.video?.[0]?.content?.props?.posterStart
                                  ?.props?.umbracoWidth,
                              height: data?.content?.props?.video?.[0]?.content?.props?.posterStart
                                  ?.props?.umbracoHeight,
                              extension:
                                  data?.content?.props?.video?.[0]?.content?.props?.posterStart
                                      ?.props?.umbracoExtension,
                          }
                        : undefined,
                    posterEnd: data?.content?.props?.video?.[0]?.content?.props?.posterEnd
                        ? {
                              name: data?.content?.props?.video?.[0]?.content?.props?.posterEnd
                                  ?.name,
                              url: getAbsoluteUrl(
                                  data?.content?.props?.video?.[0]?.content?.props?.posterEnd?.url
                              ),
                              width: data?.content?.props?.video?.[0]?.content?.props?.posterEnd
                                  ?.props?.umbracoWidth,
                              height: data?.content?.props?.video?.[0]?.content?.props?.posterEnd
                                  ?.props?.umbracoHeight,
                              extension:
                                  data?.content?.props?.video?.[0]?.content?.props?.posterEnd?.props
                                      ?.umbracoExtension,
                          }
                        : undefined,

                    // Extras
                    backgroundColor: getExtra(
                        "blockSettings",
                        "BACKGROUND_PRIMARY",
                        "BACKGROUND_ACCENT",
                        "BACKGROUND_GREY_10"
                    ),

                    size: getExtra("settings", "VIDEO_SIZE_SMALL", "VIDEO_SIZE_MEDIUM"),
                } as BlockVideoProps;
            }

            /********************/
            /** Text and Table **/
            /********************/
            case "blockTextTable": {
                const rows = data?.content?.props?.rows?.reduce((previous, current) => {
                    const result = current.content.props.columns;
                    previous.push(result);
                    return previous;
                }, []);

                return {
                    // Content
                    tagline: data?.content?.props?.tagline,
                    headline: data?.content?.props?.headline,
                    text: data?.content?.props?.text,
                    buttons: mapButtons(data?.content?.props?.buttons),

                    // Table
                    headers: data?.content?.props?.headers,
                    rows: rows,

                    // Extras
                    backgroundColor: getExtra(
                        "blockSettings",
                        "BACKGROUND_PRIMARY",
                        "BACKGROUND_ACCENT",
                        "BACKGROUND_GREY_10"
                    ),
                    position: getExtra("settings", "POSITION_TABLE_LEFT", "POSITION_TABLE_BOTTOM"),
                } as TextTableProps;
            }

            /********************/
            /** Table (Advanced) **/
            /********************/
            case "blockTextAndAdvancedTable": {
                return {
                    // Content - Advanced table
                    text: data?.content?.props?.text,

                    // Content
                    tagline: data?.content?.props?.tagline,
                    headline: data?.content?.props?.headline,
                    contentText: data?.content?.props?.contentText,
                    buttons: mapButtons(data?.content?.props?.buttons),

                    // Extras
                    backgroundColor: getExtra(
                        "blockSettings",
                        "BACKGROUND_PRIMARY",
                        "BACKGROUND_ACCENT",
                        "BACKGROUND_GREY_10"
                    ),
                    alignment: getExtra("settings", "ALIGNMENT_CENTER"),
                    position: getExtra(
                        "settings",
                        "POSITION_ADVANCEDTABLE_LEFT",
                        "POSITION_ADVANCEDTABLE_BOTTOM"
                    ),
                } as AdvancedTableProps;
            }

            /*******************/
            /** Text and Text **/
            /*******************/
            case "blockTextText": {
                const items = data?.content?.props?.items?.map((item) => {
                    return {
                        headline: item.content.props.headline,
                        text: item.content.props.text,
                        buttons: mapButtons(item.content?.props?.buttons),
                    };
                });

                return {
                    // Header
                    tagline: data?.content?.props?.tagline,
                    headline: data?.content?.props?.headline,
                    text: data?.content?.props?.text,
                    alignment: getExtra("settings", "ALIGNMENT_LEFT"),
                    // Items
                    items: items,

                    // Extras
                    backgroundColor: getExtra(
                        "blockSettings",
                        "BACKGROUND_PRIMARY",
                        "BACKGROUND_ACCENT",
                        "BACKGROUND_GREY_10"
                    ),
                } as TextTextProps;
            }

            /***********/
            /** Quote **/
            /***********/
            case "blockQuote": {
                return {
                    // Content
                    headline: data?.content?.props?.headline,
                    quote: data?.content?.props?.quote,
                    author: data?.content?.props?.author,
                    authorPosition: data?.content?.props?.authorPosition,

                    // Features
                    image: mapImage(data?.content?.props),
                    alternative: data?.content?.props?.alternative,

                    // Extras
                    backgroundColor: getExtra(
                        "blockSettings",
                        "BACKGROUND_PRIMARY",
                        "BACKGROUND_ACCENT",
                        "BACKGROUND_GREY_10"
                    ),
                    position: getExtra("settings", "POSITION_IMAGE_LEFT"),
                    imageSize: getExtra("settings", "NARROW_IMAGE"),
                } as QuotesProps;
            }

            /*************************/
            /** Text and Accordions **/
            /*************************/
            case "blockTextAccordions": {
                const items = data?.content?.props?.items?.map((item) => {
                    return {
                        id: item.contentUdi.uriValue,
                        headline: item.content.props.headline,
                        text: item.content.props.text,
                    };
                });

                return {
                    // Content
                    tagline: data?.content?.props?.tagline,
                    headline: data?.content?.props?.headline,
                    text: data?.content?.props?.text,
                    buttons: mapButtons(data?.content?.props?.buttons),

                    // Accordions
                    items: items,

                    // Features
                    image: mapImage(data?.content?.props),

                    // Extras
                    backgroundColor: getExtra(
                        "blockSettings",
                        "BACKGROUND_PRIMARY",
                        "BACKGROUND_ACCENT",
                        "BACKGROUND_GREY_10"
                    ),
                    position: getExtra(
                        "settings",
                        "POSITION_ACCORDIONS_LEFT",
                        "POSITION_ACCORDIONS_BOTTOM"
                    ),
                } as TextAccordionsProps;
            }

            /*************************/
            /** Department Map **/
            /*************************/
            case "blockDepartmentMap": {
                // Mapping departments to an array of DepartmentProps objects
                const departments = data?.content?.props?.departments?.map((department) => ({
                    id: department.id,
                })) as DepartmentProps[];

                console.log(
                    "centerCoordinates",
                    data?.content?.props?.googleMapsCenter?.mapconfig?.centerCoordinates
                );
                return {
                    type:
                        data?.content?.props.type?.[0] === "LIST_AND_MAP" ? "LIST_AND_MAP" : "LIST",
                    googleMapsZoom: data?.content?.props?.googleMapsZoom,
                    departments: departments,
                    centerLat:
                        data?.content?.props?.googleMapsCenter?.mapconfig?.centerCoordinates?.lat,
                    centerLng:
                        data?.content?.props?.googleMapsCenter?.mapconfig?.centerCoordinates?.lng,
                };
            }

            /***************/
            /** Employees **/
            /***************/
            case "blockEmployees": {
                const items = data?.content?.props?.items?.map((item) => {
                    return {
                        image: mapImage(item?.content?.props),
                        alternative: item?.content?.props?.alternative,
                        headline: item.content.props.headline,
                        tagline: item.content.props.tagline,
                        text: item.content.props.text,
                        email: item.content.props.email,
                        phone: item.content.props.phone,
                    } as EmployeeProps;
                });

                return {
                    // Content
                    tagline: data?.content?.props?.tagline,
                    headline: data?.content?.props?.headline,
                    text: data?.content?.props?.text,

                    // Employees
                    subHeading: data?.content?.props?.subheading,
                    items: items,

                    // Extras
                    backgroundColor: getExtra(
                        "blockSettings",
                        "BACKGROUND_PRIMARY",
                        "BACKGROUND_ACCENT",
                        "BACKGROUND_GREY_10"
                    ),
                    cardSize: getExtra("settings", "CARD_SIZE_SMALL", "CARD_SIZE_MEDIUM"),
                    alignment: getExtra("settings", "ALIGNMENT_LEFT"),
                } as EmployeesProps;
            }

            /***********/
            /** Spots **/
            /***********/

            case "blockSpots": {
                const items = data?.content?.props?.items?.map((item) => {
                    const mapMedia = (media: any) => {
                        const result = {};

                        if (media?.[0]?.content.alias === "blockSpotsIcon") {
                            result["icon"] = getIcon(media?.[0]?.content.props?.icon);
                        }

                        if (media?.[0]?.content.alias === "blockSpotsImage") {
                            result["image"] = {
                                name: media?.[0]?.content.props?.image?.name,
                                url: getAbsoluteUrl(media?.[0]?.content.props?.image?.url),
                                width: media?.[0]?.content.props?.image?.props?.umbracoWidth,
                                height: media?.[0]?.content.props?.image?.props?.umbracoHeight,
                                extension:
                                    media?.[0]?.content.props?.image?.props?.umbracoExtension,
                                alternative: media?.[0]?.content.props?.alternative,
                                dropdownImageSize: media?.[0]?.content.props?.imageSizes,
                            };
                        }

                        return result;
                    };

                    return {
                        id: item.contentUdi.uriValue,

                        // Content
                        headline: item.content.props.headline,
                        text: item.content.props.text,
                        link: item.content.props.link,
                        buttons: mapButtons(item.content?.props?.buttons),

                        // Media
                        ...mapMedia(item.content.props.media),
                    };
                });

                return {
                    //Content
                    tagline: data?.content?.props?.tagline,
                    headline: data?.content?.props?.headline,
                    text: data?.content?.props?.text,

                    //Spots
                    items: items,

                    // Extras
                    backgroundColor: getExtra(
                        "blockSettings",
                        "BACKGROUND_PRIMARY",
                        "BACKGROUND_ACCENT",
                        "BACKGROUND_GREY_10"
                    ),
                    iconColor: getExtra("settings", "SECONDARY_ICON_COLOR"),
                    headerAlignment: getExtra("settings", "ALIGNMENT_LEFT"),
                    itemAlignment: getExtra(
                        "settings",
                        "ITEMS_ALIGNMENT_LEFT",
                        "ITEMS_ALIGNMENT_TOP"
                    ),
                };
            }

            /***************/
            /** Content List **/
            /***************/
            case "blockContentList": {
                const items = data?.content?.props?.items?.map((item) => {
                    return {
                        image: mapImage(item?.content?.props),
                        alternative: item.content.props.alternative,
                        headline: item.content.props.headline,
                        text: item.content.props.text,
                        buttons: mapButtons(item.content?.props?.buttons),
                    } as CardProps;
                });

                return {
                    // Content
                    tagline: data?.content?.props?.tagline,
                    headline: data?.content?.props?.headline,
                    text: data?.content?.props?.text,

                    // Cards
                    subHeading: data?.content?.props?.subheading,
                    items: items,

                    // Extras
                    backgroundColor: getExtra(
                        "blockSettings",
                        "BACKGROUND_PRIMARY",
                        "BACKGROUND_ACCENT",
                        "BACKGROUND_GREY_10"
                    ),
                    cardSize: getExtra(
                        "settings",
                        "CARD_SIZE_EXTRA_SMALL",
                        "CARD_SIZE_MEDIUM",
                        "CARD_SIZE_LARGE",
                        "CARD_SIZE_EXTRA_LARGE"
                    ),
                    alignment: getExtra("settings", "ALIGNMENT_LEFT"),
                    verticalImage: getExtra("settings", "VERTICAL_IMAGE"),
                } as ContentListProps;
            }

            /***************/
            /** TextForm **/
            /***************/

            case "blockTextForm": {
                return {
                    // Content
                    tagline: data?.content?.props?.tagline,
                    headline: data?.content?.props?.headline,
                    text: data?.content?.props?.text,
                    buttons: mapButtons(data?.content?.props?.buttons),

                    // Image
                    image: mapImage(data?.content?.props),

                    // Form
                    form: {
                        formTagline: data?.content?.props?.formTagline,
                        formHeadline: data?.content?.props?.formHeadline,

                        formData: data?.content?.props?.form,
                        receipt: {
                            receiptHeadline: data?.content?.props?.receiptHeadline,
                            receiptText: data?.content?.props?.receiptText,
                        },
                    },
                    // allFieldSets: allFieldSets,

                    // Receipt
                    receipt: {
                        receiptHeadline: data?.content?.props?.receiptHeadline,
                        receiptText: data?.content?.props?.receiptText,
                    },

                    // Extras
                    backgroundColor: getExtra(
                        "blockSettings",
                        "BACKGROUND_PRIMARY",
                        "BACKGROUND_ACCENT",
                        "BACKGROUND_GREY_10"
                    ),
                    backgroundCover: getExtra(
                        "settings",
                        "BACKGROUND_COVER_1",
                        "BACKGROUND_COVER_2",
                        "BACKGROUND_COVER_3",
                        "BACKGROUND_COVER_4"
                    ),
                    position: getExtra("settings", "POSITION_FORM_LEFT"),
                } as TextFormProps;
            }

            /***************/
            /** CustomForm **/
            /***************/

            case "blockCustomForm": {
                return {
                    // Content
                    tagline: data?.content?.props?.tagline,
                    headline: data?.content?.props?.headline,
                    text: data?.content?.props?.text,
                    buttons: mapButtons(data?.content?.props?.buttons),

                    // Image
                    image: mapImage(data?.content?.props),

                    // Form
                    form: {
                        formTagline: data?.content?.props?.formTagline,
                        formHeadline: data?.content?.props?.formHeadline,

                        formData: {
                            ...data?.content?.props?.form,
                            dealers: data?.content?.props?.dealers, // Add dealers
                            dates: data?.content?.props?.dates, // Add dates
                            formType: data?.content?.props?.formType,
                        },
                        receipt: {
                            receiptHeadline: data?.content?.props?.receiptHeadline,
                            receiptText: data?.content?.props?.receiptText,
                        },
                    },
                    // allFieldSets: allFieldSets,

                    // Receipt
                    receipt: {
                        receiptHeadline: data?.content?.props?.receiptHeadline,
                        receiptText: data?.content?.props?.receiptText,
                    },

                    // Extras
                    backgroundColor: getExtra(
                        "blockSettings",
                        "BACKGROUND_PRIMARY",
                        "BACKGROUND_ACCENT",
                        "BACKGROUND_GREY_10"
                    ),
                    backgroundCover: getExtra(
                        "settings",
                        "BACKGROUND_COVER_1",
                        "BACKGROUND_COVER_2",
                        "BACKGROUND_COVER_3",
                        "BACKGROUND_COVER_4"
                    ),
                    position: getExtra("settings", "POSITION_FORM_LEFT"),
                } as TextFormProps;
            }

            /**********/
            /** TextImageBanner **/
            /**********/
            case "blockTextImageBanner": {
                return {
                    // Content
                    tagline: data?.content?.props?.tagline,
                    headline: data?.content?.props?.headline,
                    text: data?.content?.props?.text,
                    buttons: mapButtons(data?.content?.props?.buttons),

                    // Image
                    image: mapImage(data?.content?.props),
                    alternative: data?.content?.props?.alternative,

                    // Extras
                    backgroundColor: getExtra(
                        "blockSettings",
                        "BACKGROUND_PRIMARY",
                        "BACKGROUND_ACCENT",
                        "BACKGROUND_GREY_10"
                    ),
                    position: getExtra(
                        "settings",
                        "POSITION_TEXT_LEFT",
                        "POSITION_TEXT_CENTER",
                        "POSITION_TEXT_RIGHT"
                    ),
                } as TextImageBannerProps;
            }
        }
    }, [alias, data, getExtra, mapButtons, mapImage, getIcon]);
};
