import { useMemo } from "react";
import { FetchOptions } from "../../../interfaces/fetchOptions.interface";
import { getApiUrl } from "../../../helpers/getApiUrl.helper";
import { useAppData } from "../../appData.context";
import { B2B_API_ROOT } from "../../../constants/urlRoot.constants";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type QueryObject = Record<string, any>;

export const useCartRequest = () => {
    const { customerNumber, fetchWithAuthB2b } = useAppData();

    return useMemo(
        () => ({
            get: (path: string, parameters?: QueryObject, options?: FetchOptions) =>
                (customerNumber
                    ? fetchWithAuthB2b(
                          getApiUrl(B2B_API_ROOT, path, parameters),
                          customerNumber,
                          options
                      )
                    : fetch(getApiUrl(B2B_API_ROOT, path, parameters), options)
                ).then((response) => response.json()),
            post: (
                path: string,
                parameters: QueryObject | string | number | boolean,
                options?: FetchOptions
            ) => {
                const opts = {
                    ...options,
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body:
                        typeof parameters === "object"
                            ? JSON.stringify(parameters)
                            : typeof parameters !== "string"
                            ? parameters.toString()
                            : parameters,
                };

                return (
                    customerNumber
                        ? fetchWithAuthB2b(getApiUrl(B2B_API_ROOT, path), customerNumber, opts)
                        : fetch(getApiUrl(B2B_API_ROOT, path), opts)
                ).then((data) => data.json());
            },
            patch: (
                path: string,
                parameters: QueryObject | string | number | boolean,
                options?: FetchOptions
            ) => {
                const opts = {
                    ...options,
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body:
                        typeof parameters === "object"
                            ? JSON.stringify(parameters)
                            : typeof parameters !== "string"
                            ? parameters.toString()
                            : parameters,
                };

                return (
                    customerNumber
                        ? fetchWithAuthB2b(getApiUrl(B2B_API_ROOT, path), customerNumber, opts)
                        : fetch(getApiUrl(B2B_API_ROOT, path), opts)
                ).then((data) => data.json());
            },
            delete: (
                path: string,
                parameters: QueryObject | string | number | boolean,
                options?: FetchOptions
            ) => {
                const opts = {
                    ...options,
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body:
                        typeof parameters === "object"
                            ? JSON.stringify(parameters)
                            : typeof parameters !== "string"
                            ? parameters.toString()
                            : parameters,
                };

                return (
                    customerNumber
                        ? fetchWithAuthB2b(getApiUrl(B2B_API_ROOT, path), customerNumber, opts)
                        : fetch(getApiUrl(B2B_API_ROOT, path), opts)
                ).then((data) => data.json());
            },
        }),
        [fetchWithAuthB2b, customerNumber]
    );
};
