import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import { isProduction } from "@helpers/isProduction.helper";
import { DA_Container } from "@danishagro/shared/src/components/atoms/Container/Container.component";
import { DynamicBlock } from "../../../../blocks/DynamicBlock.component";
import { Card } from "../EventListPage/components/Card/Card.component";
import { useItems } from "./hooks/useItems.hook";
import { EventListPageProps } from "./EventListPage.interface";
import S from "./EventListPage.module.scss";

export const EventListPage = (props: EventListPageProps) => {
    const { items, viewState } = useItems(props.props?.rootFolder?.id);

    const robotsIndexFollow = useMemo(() => {
        if (!isProduction()) {
            return "noindex, nofollow";
        }

        return `${props.props.allowIndexing === true ? "index" : "noindex"},
                ${props.props.allowFollowing === true ? "follow" : "nofollow"}`;
    }, [props.props?.allowFollowing, props.props?.allowIndexing]);
    return (
        <div>
            <Helmet>
                <title>{props.props?.metaTitle || props.name}</title>
                <meta name="description" content={props.props?.metaDescription} />
                <meta name="robots" content={robotsIndexFollow} />
            </Helmet>

            <DA_Container noVerticalPadding>
                {/* Hero */}
                {props?.props?.hero?.[0]
                    ? props.props.hero?.map((block, index) => (
                          <DynamicBlock key={index} {...block} />
                      ))
                    : null}

                <div className={S.list}>
                    {viewState === "DONE" ? (
                        <>
                            {items.map((item, key) => {
                                return <Card key={key} {...item} />;
                            })}
                        </>
                    ) : null}
                </div>

                {/* Blocks */}
                {props?.props?.modules?.[0]
                    ? props.props.modules?.map((block, index) => (
                          <DynamicBlock key={index} {...block} />
                      ))
                    : null}
            </DA_Container>
        </div>
    );
};
