import React, { Suspense, useCallback, useEffect, useRef, useState } from "react";
import { animated, useTransition } from "react-spring";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { blurActiveElement } from "../helpers/blurActiveElement.helper";

export enum ModalSize {
    XS = 360,
    SM = 480,
    MD = 580,
    LG = 720,
    XL = 840,
    XXL = 1000,
}

type ContentType = React.ReactNode | string;

export interface ModalOptions {
    size?: ModalSize;
    shouldCloseOnOverlayClick?: boolean;
    shouldCloseOnEsc?: boolean;
    onClose?: () => void;
}

interface ModalHook {
    showModal: (content: ContentType, options?: ModalOptions) => void;
    closeModal: () => void;
    isModalShowing: boolean;
    scrollToTop: () => void;
}

const ModalContext = React.createContext<ModalHook>({
    showModal: () => null,
    closeModal: () => null,
    isModalShowing: false,
    scrollToTop: () => null,
});

type Props = {
    children: React.ReactNode;
};

const ReactModal = React.lazy(() => import("react-modal"));

export const ModalProvider = ({ children }: Props): JSX.Element => {
    const [shouldCloseOnOverlayClick, setShouldCloseOnOverlayClick] = useState(true);
    const [shouldCloseOnEsc, setShouldCloseOnEsc] = useState(true);
    const [content, setContent] = useState<ContentType>();
    const [isShowing, setIsShowing] = useState(false);
    const [size, setSize] = useState<ModalSize>(ModalSize.SM);
    const onCloseCallback = useRef<(() => void) | null>(null);
    const heightChangeCount = useRef(0);

    const contentWrapperRef = useRef<HTMLDivElement | null>(null);

    const transition = useTransition(isShowing, {
        from: { opacity: 0, transform: "translate3d(0, -30px, 0)" },
        enter: { opacity: 1, transform: "translate3d(0, 0px, 0)" },
        leave: { opacity: 0, transform: "translate3d(0, 30px, 0)" },
        delay: isShowing ? 100 : 0,
        config: { friction: 24 },
        onDestroyed: () => {
            if (!isShowing) {
                setContent(undefined);
                heightChangeCount.current = 0;
                // When the modal closes, focus returns to the element that opened the modal.
                // This is good - but we don't want to draw attention to this element,
                // so let's blur it to remove the outline.
                setTimeout(() => blurActiveElement(), 0);
            }
        },
    });

    const showModal = useCallback((content: ContentType, options?: ModalOptions) => {
        const {
            shouldCloseOnOverlayClick = true,
            shouldCloseOnEsc = true,
            onClose,
        } = options || {};
        setShouldCloseOnOverlayClick(shouldCloseOnOverlayClick);
        setShouldCloseOnEsc(shouldCloseOnEsc);

        setSize(options?.size || ModalSize.SM);
        if (typeof onClose === "function") {
            onCloseCallback.current = onClose;
        }
        setContent(content);
    }, []);

    const closeModal = useCallback(() => {
        setIsShowing(false);

        if (onCloseCallback.current) {
            onCloseCallback.current();
            onCloseCallback.current = null;
        }
    }, [onCloseCallback]);

    const scrollToTop = useCallback(() => {
        contentWrapperRef?.current.scrollTo({ top: 0, behavior: "smooth" });
    }, [contentWrapperRef]);

    useEffect(() => {
        if (content) {
            setIsShowing(true);
        }
    }, [content]);

    return (
        <ModalContext.Provider
            value={{
                showModal,
                closeModal,
                isModalShowing: typeof content !== "undefined",
                scrollToTop,
            }}
        >
            {children}

            {/** Modal */}
            <Suspense>
                <ReactModal
                    isOpen={typeof content !== "undefined"}
                    onRequestClose={closeModal}
                    shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
                    shouldCloseOnEsc={shouldCloseOnEsc}
                    overlayClassName="modal-container"
                    className="modal-content"
                    style={{
                        content: {
                            maxWidth: `${size}px`,
                        },
                    }}
                    appElement={document.body}
                    parentSelector={() => document.body}
                    overlayElement={(props, contentEl) => (
                        <div {...props}>
                            <div
                                className={cn("modal-overlay", isShowing && "modal-overlay--show")}
                            />
                            {contentEl}
                        </div>
                    )}
                >
                    {transition(({ transform, opacity }, state) =>
                        state ? (
                            <animated.div style={{ transform, opacity }}>
                                <animated.div
                                    ref={contentWrapperRef}
                                    className="modal-content-inside"
                                >
                                    {content}
                                </animated.div>
                            </animated.div>
                        ) : null
                    )}
                </ReactModal>
            </Suspense>
        </ModalContext.Provider>
    );
};

export const useModal = (): ModalHook => React.useContext(ModalContext);
