import React from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_Spinner } from "../../../atoms/Spinner/Spinner.component";
import S from "./Badge.module.scss";

export enum DA_BadgeColor {
    Action,
    Alert,
}

interface DA_BadgeProps {
    text: React.ReactNode;
    color?: DA_BadgeColor;
    size?: "normal" | "small";
    className?: string;
    showSpinner?: boolean;
}

export const DA_Badge = ({ text, color, size, className, showSpinner }: DA_BadgeProps) => (
    <span
        className={cn(
            S.badge,
            size === "small" && S.small,
            color === DA_BadgeColor.Action ? S.action : S.alert,
            showSpinner && S.hasSpinner,
            className
        )}
    >
        {showSpinner ? <DA_Spinner /> : text}
    </span>
);
