import React, { useMemo } from "react";
import { useCart } from "../../../../../contexts/cart/cart.context";
import { useSiteHeader } from "../../../../../contexts/siteHeader.context";
import { DA_MiniCartPopUp } from "../../MiniCart/MiniCart.component";
import { useBottomSheet } from "../../../../../contexts/bottomSheet/bottomSheet.context";
import { useTranslations } from "../../../../../contexts/translations/translations.context";

export const useCartButton = () => {
    const { orderLines, isLoadingCart } = useCart();
    const { showMiniCart, closeMobileNav, showMobileNav } = useSiteHeader();
    const { getDictionaryString } = useTranslations();

    const { presentBottomSheet, dismissBottomSheet, activeBottomSheet } = useBottomSheet();

    const onCartButtonClicked = () => {
        const id = "cart";

        if (activeBottomSheet !== id) {
            presentBottomSheet(<DA_MiniCartPopUp />, {
                id: id,
                headerTitle: getDictionaryString("miniCartTitle", {
                    uppercaseFirst: true,
                }),
            });
            // Close mobileNav after bottomSheet has opened
            if (showMobileNav) {
                setTimeout(() => {
                    closeMobileNav();
                }, 500);
            }
        } else {
            dismissBottomSheet();
        }
    };

    // Mappers

    const totalQuantity = useMemo(() => {
        if (orderLines?.[0]) {
            return orderLines.reduce((total, line) => total + line.quantity, 0);
        }

        return 0;
    }, [orderLines]);

    return {
        showCart: true,
        onCartButtonClicked,
        cartBadge: totalQuantity,
        isCartMenuActive: showMiniCart,
        isLoadingCart,
    };
};
