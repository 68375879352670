import { useMemo } from "react";
import { useAppData } from "../contexts/appData.context";

export const useCustomer = () => {
    const { cvrAndCustomerNumbers, customerNumber } = useAppData();

    const customer = useMemo(
        () =>
            cvrAndCustomerNumbers?.accounts?.find((x) =>
                x.customers?.find((y) => y.number === customerNumber)
            ),
        [cvrAndCustomerNumbers, customerNumber]
    );

    return { customer, customerNumber };
};
