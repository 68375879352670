import React from "react";
import { DA_BasicLink } from "../../../atoms/BasicLink/BasicLink.component";
import { DA_LinkProps } from "../../../../interfaces/linkProps.interface";

export const _FooterLink = (props: DA_LinkProps & { classname: string }) => {
    return /CookieConsent.*?renew/.test(props.href) ? (
        <button onClick={() => window["CookieConsent"]?.renew()} className={props.classname}>
            {props.title}
        </button>
    ) : (
        <DA_BasicLink href={props.href} className={props.classname}>
            {props.title}
        </DA_BasicLink>
    );
};
