import {
    GET_B2B_SITE_ORIGIN,
    GET_CMS_SITE_ORIGIN,
    GET_MYFARM_SITE_ORIGIN,
} from "@danishagro/shared/src/helpers/urlRoot.helper";

export const getLoginLink = (site: "B2B" | "MYFARM" | "CMS" = "B2B") => {
    let origin = undefined;

    switch (site) {
        case "MYFARM":
            origin = GET_MYFARM_SITE_ORIGIN("ABSOLUTE");
            break;
        case "CMS":
            origin = GET_CMS_SITE_ORIGIN("ABSOLUTE");
            break;
        default:
            origin = GET_B2B_SITE_ORIGIN("ABSOLUTE");
            break;
    }

    return `${origin}/login?redirectUrl=${location.pathname + location.search + location.hash}`;
};
