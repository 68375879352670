import React from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { useAppData } from "../../../contexts/appData.context";
import { markLinksFromString } from "../../../helpers/markLinksFromStrings.helper";
import S from "./Text.module.scss";

interface TextWithChildren {
    html?: never;
    children: React.ReactNode;
}

interface TextWithString {
    html: string;
    children?: never;
}

interface BaseProps {
    bold?: boolean;
    dimmed?: boolean;
    noPadding?: boolean;
    smallText?: boolean;
    alignCenter?: boolean;
    className?: string;
}

export type DA_TextProps = BaseProps & (TextWithChildren | TextWithString);

export const DA_Text = (props: DA_TextProps) => {
    const { currentSite } = useAppData();

    const shouldLargeText = currentSite === "CMS";

    const combinedClassNames = cn(
        S.wrapper,
        props.className,
        props.alignCenter && S.alignCenter,
        props.bold && S.bold,
        props.dimmed && S.dimmed,
        props.noPadding || S.paddingBottom,
        props.smallText && S.smallText,
        shouldLargeText && S.largeText
    );

    return props.html ? (
        <div
            className={combinedClassNames}
            dangerouslySetInnerHTML={{
                __html: markLinksFromString(props.html),
            }}
        />
    ) : (
        <div className={combinedClassNames}>{props.children}</div>
    );
};
