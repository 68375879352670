import React from "react";
import { DA_Title } from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import { DA_BasicLink } from "@danishagro/shared/src/components/atoms/BasicLink/BasicLink.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
// import style
import { ImageViewer } from "src/components/ImageViewer/ImageViewer.component";
import { DA_InlineLink } from "@danishagro/shared/src/components/atoms/InlineLink/InlineLink.component";
import { Position } from "../../JobListPage.interface";
import S from "./Card.module.scss";

const getRelativePathFromUrl = (url) => {
    if (typeof url !== "string" || url.trim() === "") return "";
    const urlObj = new URL(url);
    return urlObj.pathname + urlObj.search;
};

export const Card = ({
    id,
    name,
    department,
    city,
    country,
    teaser,
    positionCategory,
    advertisements,
}: Position) => {
    const url = globalThis.siteSettingsObj?.jobPositionDetailPage?.url.replace("{positionID}", id);

    const relativePath = getRelativePathFromUrl(url);

    const { getDictionaryString } = useTranslations();

    return (
        <DA_BasicLink
            href={`${relativePath}`}
            className={S.jobListCard}
            title={getDictionaryString("General.ReadMore")}
            target="_self"
        >
            {/* Image */}
            {advertisements?.[0].imageUrl ? (
                <div className={S.imageWrapper}>
                    <ImageViewer
                        src={advertisements?.[0].imageUrl}
                        aspectRatioX={350}
                        aspectRatioY={200}
                    />
                </div>
            ) : null}

            <div>
                {/* Title */}
                <DA_Title h3 noMargin>
                    <span className={S.title}>{name}</span>
                </DA_Title>

                {/* Text */}
                {teaser ? (
                    <div className={S.teaserWrapper}>
                        {/* <DA_Text noPadding html={teaser} className={S.teaserText} /> */}
                        <div className={S.teaserText}>
                            {teaser}

                            <DA_InlineLink
                                title={getDictionaryString("General.ReadMore")}
                                href={`${relativePath}`}
                                target="_self"
                                className={S.readMoreLink}
                            />
                        </div>
                    </div>
                ) : null}

                {/* Job info  */}
                <ul className={S.jobInfoList}>
                    <li className={S.jobInfoItem}>
                        <span className={S.jobInfoLabel}>
                            {getDictionaryString("jobDetail.areaOfWork.label")}
                        </span>
                        <span className={S.positionCategory}>
                            {positionCategory?.name ? positionCategory?.name : "-"}
                        </span>
                    </li>
                    <li className={S.jobInfoItem}>
                        <span className={S.jobInfoLabel}>
                            {getDictionaryString("jobDetail.company.label")}
                        </span>
                        <DA_Text noPadding html={department.name} className={S.departmentName} />
                    </li>
                    <li className={S.jobInfoItem}>
                        <span className={S.jobInfoLabel}>
                            {getDictionaryString("jobDetail.location.label")}
                        </span>
                        {(city || country) && (
                            <DA_Text
                                noPadding
                                html={city && country ? `${city}, ${country}` : city || country}
                                className={S.locationText}
                            />
                        )}
                    </li>
                </ul>
            </div>
        </DA_BasicLink>
    );
};
