import { DA_NavigationPage } from "../../../../../interfaces/navigationPage.interface";

export const splitIntoChunks = (array: DA_NavigationPage[], parts: number) => {
    const result = [];

    for (let i = parts; i > 0; i--) {
        result.push(array.splice(0, Math.ceil(array.length / i)));
    }

    return result;
};
