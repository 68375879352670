import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { NewsListPage } from "src/layout/pages/components/NewsListPage/NewsListPage.component";
import { Page } from "@interfaces/page.interface";
import { useApi } from "@contexts/api.context";
import { useLocation } from "react-router-dom";
import { usePage } from "@contexts/page.context";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { NewsPageProps } from "./components/NewsPage/NewsPage.interface";
import { ContentPageProps } from "./components/ContentPage/ContentPage.interface";
import ContentPage from "./components/ContentPage/ContentPage.component";
import ErrorPage from "./components/ErrorPage/ErrorPage.component";
import NewsPage from "./components/NewsPage/NewsPage.component";
import NotFoundPage from "./components/NotFoundPage/NotFoundPage.component";
import { NewsListPageProps } from "./components/NewsListPage/NewsListPage.interface";
import { NotFoundPageProps } from "./components/NotFoundPage/NotFoundPage.interface";
import { EventListPage } from "./components/EventListPage/EventListPage.component";
import { EventListPageProps } from "./components/EventListPage/EventListPage.interface";
import { JobsPageProps } from "./components/JobsPage/JobsPage.interface";
import { JobsPage } from "./components/JobsPage/JobsPage.component";
import { JobListPage } from "./components/JobListPage/JobListPage.component";
import { JobListPageProps } from "./components/JobListPage/JobListPage.interface";
import EventPage from "./components/EventPage/EventPage.component";
import { EventPageProps } from "./components/EventPage/EventPage.interface";
import { TestimonialsPage } from "./components/TestimonialsPage/TestimonialsPage.component";
import { TestimonialsPageProps } from "./components/TestimonialsPage/TestimonialsPage.interface";
import { TestimonialListPage } from "./components/TestimonialListPage/TestimonialListPage.component";
import { TestimonialListPageProps } from "./components/TestimonialListPage/TestimonialListPage.interface";
import S from "./DynamicPage.module.scss";

export const DynamicPage = () => {
    const { page, update } = usePage();
    const location = useLocation();
    const { fetchPage } = useApi();
    const { faviconColor } = useAppData(); // Default value if useAppData() returns null or undefined

    const color = faviconColor || "green"; // Default value if faviconColor is null, undefined, or an empty string

    const [isLoading, setIsLoading] = useState(false);

    // const { siteSettings } = useSiteSettings();

    const getPage = useCallback(
        async (pathname: string) => {
            setIsLoading(true);
            const fetchPageResponse = await fetchPage(pathname);

            if (fetchPageResponse.status === 404) {
                update({
                    alias: "notFoundPage",
                    name: "404",
                    url: window.location.href,
                    status: 404,
                });
            } else {
                update(fetchPageResponse);

                // Scroll to top
                setTimeout(() => {
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        // @ts-ignore
                        behavior: "instant",
                    });
                }, 100);
            }
            setIsLoading(false);
        },
        [fetchPage, update]
    );

    const checkPage = useCallback(
        async (page: Page, pathname: string) => {
            try {
                if (!page) {
                    // Fetching the first request.
                    getPage(pathname);
                } else if (page && page.url !== pathname) {
                    // Fetching a subsequent request.
                    getPage(pathname);
                }
            } catch {
                update({
                    alias: "errorPage",
                    name: "500",
                    url: window.location.href,
                    status: 500,
                });
            }
        },
        [getPage, update]
    );

    const lastCheckedPageRef = useRef<string>("");

    useEffect(() => {
        if (lastCheckedPageRef.current !== location.pathname) {
            lastCheckedPageRef.current = location.pathname;
            checkPage(page, location.pathname);
        }
    }, [page, location.pathname, checkPage]);

    const renderBody = (page: Page) => {
        switch (page?.alias) {
            case "frontpage": {
                return <ContentPage key={page?.url} {...(page as ContentPageProps)} />;
            }
            case "page": {
                return <ContentPage key={page?.url} {...(page as ContentPageProps)} />;
            }
            case "newsPage": {
                return <NewsPage key={page?.url} {...(page as NewsPageProps)} />;
            }
            case "newsListPage": {
                return <NewsListPage key={page?.url} {...(page as NewsListPageProps)} />;
            }
            case "eventListPage": {
                return <EventListPage key={page?.url} {...(page as EventListPageProps)} />;
            }
            case "eventPage": {
                return <EventPage key={page?.url} {...(page as EventPageProps)} />;
            }
            case "employeeArchive": {
                return (
                    <TestimonialListPage key={page?.url} {...(page as TestimonialListPageProps)} />
                );
            }
            case "testimonialsPage": {
                return <TestimonialsPage key={page?.url} {...(page as TestimonialsPageProps)} />;
            }
            case "errorPage": {
                return <ErrorPage {...(page as Page)} />;
            }
            case "talentRecruiterDetailPage": {
                return <JobsPage key={page?.url} {...(page as JobsPageProps)} />;
            }
            case "talentRecruiterListPage": {
                return <JobListPage key={page?.url} {...(page as JobListPageProps)} />;
            }
            case "Page404": {
                return <NotFoundPage {...(page as NotFoundPageProps)} />;
            }
        }
    };

    return isLoading ? (
        <div />
    ) : (
        <Fragment>
            <Helmet>
                <html lang="da" />
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href={`/icons/${color}/apple-touch-icon.png`}
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href={`/icons/${color}/favicon-32x32.png`}
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href={`/icons/${color}/favicon-16x16.png`}
                />
                <link rel="manifest" href={`/icons/${color}/site.webmanifest`} />
                <link rel="shortcut icon" href={`/icons/${color}/favicon.ico`} />
            </Helmet>
            {/* Body */}
            <div className={S.bodyPage}>{renderBody(page)}</div>
        </Fragment>
    );
};
