import React from "react";
import { DA_Title } from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { JobAgentProps } from "./JobAgent.interface";
import S from "./JobAgent.module.scss";

export const JobAgent = (props: JobAgentProps) => {
    return (
        <div className={S.wrapper}>
            {/* Title */}
            {props.title ? <DA_Title h3>{props.title}</DA_Title> : null}

            {/* Description */}
            {props.description ? <DA_Text html={props.description} /> : null}

            {/* Link */}
            {props?.link?.url ? (
                <DA_Button
                    title={props.link?.name}
                    isGhost
                    href={props?.link?.url}
                    target={props?.link.target ? props?.link.target : "_self"}
                />
            ) : null}
        </div>
    );
};
