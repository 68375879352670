import React, { useCallback, useState } from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { MYFARM_SITE_ORIGIN } from "@danishagro/shared/src/constants/urlRoot.constants";
import { SiteHeaderPopUpType, useSiteHeader } from "../../../../contexts/siteHeader.context";
import { useScreen } from "../../../../contexts/screen.context";
import { DA_Title, DA_TitleTag } from "../../../atoms/Title/Title.component";
import { DA_BasicLink } from "../../../atoms/BasicLink/BasicLink.component";
import { DA_Icon, DA_IconNames } from "../../../atoms/Icon/Icon.component";
import { useTranslations } from "../../../../contexts/translations/translations.context";
import { useBottomSheet } from "../../../../contexts/bottomSheet/bottomSheet.context";
import { DA_Spinner } from "../../../atoms/Spinner/Spinner.component";
import { useAppData } from "../../../../contexts/appData.context";
import { DA_AccountPickerProps } from "./AccountPicker.props";
import S from "./AccountPicker.module.scss";

export const DA_AccountPicker = (_props: DA_AccountPickerProps) => {
    const [isChangingCustomer, setIsChangingCustomer] = useState(false);
    const { headerMeasure, accountButtonMeasure, togglePopUp } = useSiteHeader();
    const { isMobileOrTablet } = useScreen();
    const { dismissBottomSheet } = useBottomSheet();
    const { getDictionaryString } = useTranslations();
    const {
        isAdmin,
        isMasterAdmin,
        isImpersonator,
        cvrAndCustomerNumbers,
        customerNumber,
        changeCustomer,
        currentSite,
    } = useAppData();

    const changeAccount = useCallback(
        (accountNumber: string) => {
            setIsChangingCustomer(true);
            changeCustomer(accountNumber, true)
                .then(() => {
                    togglePopUp(SiteHeaderPopUpType.AccountPicker);
                    dismissBottomSheet();
                })
                .finally(() => setIsChangingCustomer(false));
        },
        [changeCustomer, dismissBottomSheet, togglePopUp]
    );

    const onCloseButtonClicked = () => {
        dismissBottomSheet();
        togglePopUp(undefined);
    };

    const getLinkUrl = useCallback(
        (path: string) => (currentSite === "MYFARM" ? path : MYFARM_SITE_ORIGIN + path),
        [currentSite]
    );

    return (
        <div
            className={cn(
                S.inside,
                cvrAndCustomerNumbers?.accounts?.length > 1 && S.hasMultipleCompanies,
                cvrAndCustomerNumbers?.accounts?.length > 2 && S.hasThreeOrMoreCompanies
            )}
            style={{
                maxHeight:
                    !isMobileOrTablet && accountButtonMeasure
                        ? `calc(100vh - ${
                              accountButtonMeasure.top + accountButtonMeasure.bottom
                          }px)`
                        : undefined,
                right: !isMobileOrTablet && headerMeasure ? `${headerMeasure.left}px` : undefined,
                top:
                    !isMobileOrTablet && accountButtonMeasure
                        ? `${accountButtonMeasure.top + accountButtonMeasure.bottom}px`
                        : undefined,
            }}
        >
            <div className={S.top}>
                {!isMobileOrTablet && (
                    <div className={S.userName}>
                        {cvrAndCustomerNumbers.firstName} {cvrAndCustomerNumbers.lastName}
                    </div>
                )}

                <div className={S.buttons}>
                    {/** Button: Administrator */}
                    {(isAdmin ||
                        isMasterAdmin ||
                        (!isAdmin && !isMasterAdmin && isImpersonator)) && (
                        <div className={S.buttonWrapper}>
                            <DA_BasicLink
                                href={getLinkUrl("/admin")}
                                target="_self"
                                className={S.buttonLink}
                                onClick={onCloseButtonClicked}
                            >
                                <DA_Icon name={DA_IconNames.UserCrown} className={S.buttonIcon} />
                                <span className={S.buttonText}>
                                    {getDictionaryString("administrator", {
                                        uppercaseFirst: true,
                                    })}
                                </span>
                            </DA_BasicLink>
                        </div>
                    )}

                    {/** Button: Notifications */}
                    {/* NOTE: Removed for now because notifications isn't ready for launch. */}
                    {/* <div className={S.buttonWrapper}>
                        <DA_BasicLink
                            href={getLinkUrl("/notifications")}
                            target="_self"
                            className={S.buttonLink}
                            onClick={onCloseButtonClicked}
                        >
                            <div className={S.buttonIconWrapper}>
                                <DA_Icon name={DA_IconNames.Bell} className={S.buttonIcon} />

                                {props.notificationCount > 0 ? (
                                    <DA_Badge
                                        size="small"
                                        text={props.notificationCount}
                                        className={S.buttonIconBadge}
                                    />
                                ) : null}
                            </div>

                            <span className={S.buttonText}>
                                {getDictionaryString("notificationsTitle", {
                                    uppercaseFirst: true,
                                })}
                            </span>
                        </DA_BasicLink>
                    </div> */}

                    {/** Button: Settings */}
                    <div className={S.buttonWrapper}>
                        <DA_BasicLink
                            href={getLinkUrl("/profile")}
                            target="_self"
                            className={S.buttonLink}
                            onClick={onCloseButtonClicked}
                        >
                            <DA_Icon name={DA_IconNames.CogwheelStroke} className={S.buttonIcon} />
                            <span className={S.buttonText}>
                                {getDictionaryString("settings", {
                                    uppercaseFirst: true,
                                })}
                            </span>
                        </DA_BasicLink>
                    </div>

                    {/** Button: Logout */}
                    <div className={S.buttonWrapper}>
                        <DA_BasicLink
                            // All sites should have their own logout page
                            // that sends you to the front page when logged out
                            href="/logout"
                            target="_self"
                            conventionalRouting
                            className={S.buttonLink}
                            onClick={() => {
                                onCloseButtonClicked();
                            }}
                        >
                            <DA_Icon name={DA_IconNames.SignOut} className={S.buttonIcon} />
                            <span className={S.buttonText}>
                                {getDictionaryString("logOut", {
                                    uppercaseFirst: true,
                                })}
                            </span>
                        </DA_BasicLink>
                    </div>
                </div>
            </div>
            <div className={S.companies}>
                {cvrAndCustomerNumbers?.accounts?.map((account, accountIndex) => (
                    <section key={`${account.cvr}-${accountIndex}`} className={S.company}>
                        <div className={S.vatNumber}>CVR {account.cvr}</div>
                        <DA_Title h3 tag={DA_TitleTag.DIV}>
                            {account.name}
                        </DA_Title>
                        <ul className={S.accounts}>
                            {account.customers.map((customer) => (
                                <li
                                    key={`${account.cvr}-${accountIndex}-${customer.number}`}
                                    className={S.account}
                                >
                                    <button
                                        type="button"
                                        className={cn(
                                            S.accountButton,
                                            customer.number === customerNumber && S.active
                                        )}
                                        onClick={() => changeAccount(String(customer.number))}
                                    >
                                        <span className={S.accountName}>{customer.name}</span>
                                        <span className={S.accountNumber}>
                                            <span className={S.accountLabel}>
                                                {getDictionaryString("account", {
                                                    uppercaseFirst: true,
                                                })}
                                            </span>
                                            {customer.number}
                                        </span>
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </section>
                ))}
            </div>

            {isChangingCustomer && (
                <div className={S.changingCustomerOverlay}>
                    <div>
                        <DA_Spinner />
                        <div className={S.text}>
                            {getDictionaryString("Changing customer number")}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export const DA_AccountPickerPopUp = () => {
    const { headerMeasure } = useSiteHeader();
    const { isMobileOrTablet } = useScreen();

    // NOTE: Removed for now because notifications isn't ready for launch.
    // const { notificationCount } = useNotifications();

    return (
        <div
            className={S.wrapper}
            style={{
                right: isMobileOrTablet ? undefined : `${headerMeasure.left}px`,
                top: isMobileOrTablet ? undefined : 0,
            }}
        >
            <DA_AccountPicker notificationCount={undefined} />
        </div>
    );
};
