import jwtDecode from "jwt-decode";
import { setCookie } from "@baggie/core";
import { getDomainName } from "./getDomainName.helper";

export const setCustomerNumberCookie = (accessToken: string, customerNumber: string) => {
    const jwtData = jwtDecode(accessToken) as {
        oid?: string;
    };

    // Use a constant for the domain logic to avoid repeating globalThis check
    const cookieDomain = globalThis?.cookieDomain
        ? globalThis.cookieDomain.startsWith(".")
            ? globalThis.cookieDomain
            : `.${globalThis.cookieDomain}`
        : getDomainName();

    if ("oid" in jwtData) {
        setCookie(`customer.${jwtData.oid}`, customerNumber, {
            path: "/",
            domain: cookieDomain,
        });
    }

    if (location.hostname === "localhost") {
        setCookie("localhost-last-customer", customerNumber);
    }
};
