import React from "react";
import { useBottomSheet } from "../../../../../contexts/bottomSheet/bottomSheet.context";
import { useAppData } from "../../../../../contexts/appData.context";
import { useSiteHeader } from "../../../../../contexts/siteHeader.context";
import { DA_NotificationsAndAccountsPopUp } from "../../NotificationsAndAccounts/NotificationsAndAccounts.component";

export const useAccountButton = () => {
    // NOTE: Removed for now because notifications isn't ready for launch.
    // const { notificationCount } = useNotifications();
    const { showAccountPicker, closeMobileNav, showMobileNav } = useSiteHeader();
    const { cvrAndCustomerNumbers } = useAppData();

    const { presentBottomSheet, dismissBottomSheet, activeBottomSheet } = useBottomSheet();

    const onAccountButtonClicked = () => {
        const id = "account";

        if (activeBottomSheet !== id) {
            presentBottomSheet(<DA_NotificationsAndAccountsPopUp notificationCount={undefined} />, {
                id: id,
                headerTitle: (
                    <>
                        {cvrAndCustomerNumbers.firstName} {cvrAndCustomerNumbers.lastName}
                    </>
                ),
            });
            // Close mobileNav after bottomSheet has opened
            if (showMobileNav) {
                setTimeout(() => {
                    closeMobileNav();
                }, 500);
            }
        } else {
            dismissBottomSheet();
        }
    };

    return {
        onAccountButtonClicked,
        accountBadge: undefined,
        isAccountMenuActive: showAccountPicker,
    };
};
