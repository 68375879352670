import React from "react";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
// Import style
import { DA_Title } from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import useFormattedCultureDate from "@hooks/useFormattedCultureDate.hook";
import { ContactInfoProps } from "./ContactInfo.interface";
import S from "./ContactInfo.module.scss";

export const ContactInfo = ({
    applicationDue,
    startDate,
    startDateASAP,
    users,
    title,
    subTitle,
    className,
    userImage,
    hasContactTitle,
}: ContactInfoProps) => {
    const { getDictionaryString } = useTranslations();

    // Define constants for magic strings
    const DEFAULT_DATE_STRING = "01/01/0001 00:00:00";
    const ALTERNATE_DATE_STRING = "01.01.2001";

    const formattedApplicationDueDate = useFormattedCultureDate(applicationDue);
    const formattedStartDate = useFormattedCultureDate(startDate);

    // Check date
    function checkDate(dateString: string, asap: boolean = false): JSX.Element {
        if (dateString === DEFAULT_DATE_STRING || dateString === ALTERNATE_DATE_STRING || asap) {
            return <span>{getDictionaryString("Job.AsSoonAsPossible")}</span>;
        }
        return <span>{dateString}</span>;
    }

    const participant = users?.projectParticipants?.[0];

    return (
        <div className={cn(S.extraInfoWrapper, className)}>
            {formattedApplicationDueDate ? (
                <div className={S.extraBlock}>
                    <span className={S.title}>
                        {getDictionaryString("Job.ApplicationDeadline")}
                    </span>
                    <span className={S.info}>{checkDate(formattedApplicationDueDate)}</span>
                </div>
            ) : null}

            {startDate ? (
                <div className={S.extraBlock}>
                    <span className={S.title}>{getDictionaryString("Job.StartDate")}</span>
                    <span className={S.info}>{checkDate(formattedStartDate, startDateASAP)}</span>
                </div>
            ) : null}

            <div>
                {participant && (
                    <>
                        {/* {title} */}
                        {!hasContactTitle && (
                            <DA_Title h2 noMargin>
                                {title}
                            </DA_Title>
                        )}

                        {/* subTitle */}
                        <DA_Text noPadding html={subTitle} />
                        <div
                            className={cn(S.extraInfoContactBox, hasContactTitle && S.flexWrapper)}
                        >
                            {hasContactTitle ? (
                                <span className={S.contactTitle}>{title}</span>
                            ) : null}
                            {userImage ? (
                                <div className={S.extraInfoContactBoxLeft}>
                                    <img src={participant?.imageUrl} alt="Contactperson" />
                                </div>
                            ) : null}

                            <div className={S.extraInfoContactBoxRight}>
                                <span className={S.title}>
                                    {participant?.firstName} {participant?.lastName}
                                </span>
                                <span className={S.jobPosition}>{participant?.title}</span>
                                <div className={S.extraInfoContactBoxRightReach}>
                                    <span className={S.info}>
                                        <a href={`mailto:${participant?.email}`}>
                                            {participant?.email}
                                        </a>
                                    </span>
                                    <span className={S.info}>
                                        {participant?.phone ? (
                                            <>
                                                {getDictionaryString("Job.Phone")}:{" "}
                                                <a href={`tel:${participant?.phone}`}>
                                                    {participant?.phone}
                                                </a>
                                            </>
                                        ) : participant?.phoneMobile ? (
                                            <>
                                                {getDictionaryString("Job.Mobile")}:{" "}
                                                <a href={`tel:${participant?.phoneMobile}`}>
                                                    {participant?.phoneMobile}
                                                </a>
                                            </>
                                        ) : null}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
