import React, { Fragment } from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_BasicLink } from "../../atoms/BasicLink/BasicLink.component";
import { DA_Icon, DA_IconNames } from "../../atoms/Icon/Icon.component";
import { useAppData } from "../../../contexts/appData.context";
import { DA_BreadcrumbProps } from "./Breadcrumb.interface";
import S from "./Breadcrumb.module.scss";

export const DA_Breadcrumb = (props: DA_BreadcrumbProps) => {
    const { currentSite } = useAppData();
    const showNumberedListClass = props.showNumberedList ? S.showNumberedList : undefined;

    return (
        <nav className={showNumberedListClass}>
            <ol
                itemScope
                itemType="https://schema.org/BreadcrumbList"
                className={cn(S.list, props.hideOnMobile && S.hideOnMobile, props.listClassName)}
            >
                {props.breadcrumbItems.map((item, index) => {
                    const hasLink =
                        !(index === 0 && currentSite === "MYFARM") &&
                        ((index !== props.breadcrumbItems.length - 1 && item.href) ||
                            (index === props.breadcrumbItems.length - 1 &&
                                props.lastIsLink &&
                                item.href));

                    const isHighlightedClass = item.isHighlighted ? S.isHighlighted : undefined;

                    return (
                        <Fragment key={index}>
                            <li
                                itemProp="itemListElement"
                                itemScope
                                itemType="https://schema.org/ListItem"
                                className={cn(S.item, isHighlightedClass)}
                            >
                                {hasLink ? (
                                    <DA_BasicLink
                                        href={item.href}
                                        className={S.link}
                                        itemScope
                                        itemProp="item"
                                        itemType="https://schema.org/WebPage"
                                        id={item?.id}
                                    >
                                        <span itemProp="name">{item.title}</span>
                                    </DA_BasicLink>
                                ) : item.onClick ? (
                                    <button
                                        onClick={item.onClick}
                                        className={cn(S.title, S.expandBtn)}
                                    >
                                        {item.title}
                                    </button>
                                ) : (
                                    <span className={S.title}>{item.title}</span>
                                )}

                                <meta itemProp="position" content={String(index + 1)} />
                            </li>
                            {index !== props.breadcrumbItems.length - 1 && (
                                <li>
                                    <DA_Icon
                                        className={S.divider}
                                        name={DA_IconNames.ChevronRight}
                                    />
                                </li>
                            )}
                        </Fragment>
                    );
                })}
            </ol>
        </nav>
    );
};
