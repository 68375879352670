import { isDevMode } from "@danishagro/shared/src/helpers/isDevMode.helper";

export const isProduction = () => {
    if (isDevMode) {
        return false;
    } else if (window.location.host.indexOf("umbraco.io") > 0) {
        return false;
    } else if (window.location.host.indexOf("azurewebsites.net") > 0) {
        return false;
    } else if (window.location.host.indexOf("dev.danishagro.com") > 0) {
        return false;
    } else if (window.location.host.indexOf("test.danishagro.com") > 0) {
        return false;
    } else if (window.location.host.indexOf("preuat.danishagro.com") > 0) {
        return false;
    }

    return true;
};
