import { API_GATEWAY_ROOT } from "@danishagro/shared/src/constants/urlRoot.constants";
import { DA_SharedGlobalVariables } from "../interfaces/sharedGlobalVariables.interface";
import { getBearerToken } from "./getBearerToken.helper";
import { isDevMode } from "./isDevMode.helper";

export const getSharedGlobalVariables = (forceNew = false): Promise<DA_SharedGlobalVariables> =>
    new Promise((resolve, reject) => {
        const isAdmin = typeof globalThis.isAdmin === "boolean" ? globalThis.isAdmin : isDevMode;
        const isImpersonator =
            typeof globalThis.isImpersonator === "boolean" ? globalThis.isImpersonator : isDevMode;
        const isMasterAdmin =
            typeof globalThis.isMasterAdmin === "boolean" ? globalThis.isMasterAdmin : isDevMode;
        const newPageData =
            typeof globalThis.newPageContent !== "undefined" ? globalThis.newPageContent : null;

        const baseVariables = {
            isAdmin,
            isMasterAdmin,
            isImpersonator,
            newPageData,
        };

        (globalThis.isAuthenticated ? getBearerToken(forceNew) : Promise.reject())
            .then((accessToken) => {
                if (typeof accessToken !== "string") {
                    return reject("no token");
                }
                const getCvrAndCustomerNumbers =
                    globalThis.cvrAndCustomerNumbers && !forceNew
                        ? Promise.resolve(globalThis.cvrAndCustomerNumbers)
                        : !accessToken
                        ? Promise.resolve()
                        : fetch(`${API_GATEWAY_ROOT}/user/getuseraccounts`, {
                              credentials: "include",
                              headers: {
                                  Authorization: "Bearer " + accessToken,
                              },
                          })
                              .then((res) => res.json())
                              .catch(() => null);

                return getCvrAndCustomerNumbers
                    .then((cvrAndCustomerNumbers) => {
                        const variables: DA_SharedGlobalVariables = {
                            ...baseVariables,
                            cvrAndCustomerNumbers,
                        };

                        Object.keys(variables).forEach((key) => {
                            globalThis[key] = variables[key];
                        });

                        resolve(variables);
                    })
                    .catch((err) => reject(err));
            })
            .catch(() => {
                const variables = {
                    ...baseVariables,
                    cvrAndCustomerNumbers: undefined,
                };

                Object.keys(variables).forEach((key) => {
                    globalThis[key] = variables[key];
                });

                resolve(variables);
            });
    });
