export const getClosestWidth = (
    clientWidth?: number,
    clientHeight?: number,
    aspectRatioX?: number,
    aspectRatioY?: number
) => {
    const widthTiers = [400, 800, 1200, 1440];

    let width = undefined;
    let height = undefined;

    if (aspectRatioX && aspectRatioY && clientWidth) {
        width = Math.max.apply(null, widthTiers);

        for (const widthTier of widthTiers) {
            if (widthTier >= clientWidth && widthTier < width) {
                width = widthTier;
                break;
            }
        }

        if (aspectRatioX && aspectRatioY) {
            height = (width / aspectRatioX) * aspectRatioY;
        }
    } else if (clientWidth && clientHeight) {
        width = clientWidth;
        height = clientHeight;
    } else {
        width = 1280;
        height = 1280;
    }

    return {
        width,
        height,
    };
};
