import { useMemo } from "react";
import { GroupBase } from "react-select";
import { DA_SelectOption, DA_SelectProps } from "../Select.props";
import { useConvertOption } from "./useConvertOption.hook";

type Props<T> = Pick<DA_SelectProps<T>, "options" | "getLabel" | "getValue" | "getExtra">;

export const useParsedOptions = <T>({
    options,
    getLabel,
    getValue,
    getExtra,
}: Props<T>): DA_SelectOption[] | GroupBase<DA_SelectOption>[] => {
    const convertOption = useConvertOption({ getLabel, getValue, getExtra });
    return useMemo(
        () =>
            options &&
            options.length &&
            // @ts-ignore
            typeof options[0].options !== "undefined"
                ? options.map((group) => ({
                      ...group,
                      options: group.options.map((option) => convertOption(option)),
                  }))
                : options.map((option) => convertOption(option)),
        [options, convertOption]
    );
};
