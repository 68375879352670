import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import { DA_Container } from "@danishagro/shared/src/components/atoms/Container/Container.component";
import { isProduction } from "@helpers/isProduction.helper";
import { DA_Breadcrumb } from "@danishagro/shared/src/components/molecules/Breadcrumb/Breadcrumb.component";
import { DynamicBlock } from "src/blocks/DynamicBlock.component";
import { ContentPageProps } from "./ContentPage.interface";
import S from "./ContentPage.module.scss";

const ContentPage = (props: ContentPageProps) => {
    const breadcrumbItems = props.breadcrumbs ?? [];

    const robotsIndexFollow = useMemo(() => {
        if (!isProduction()) {
            return "noindex, nofollow";
        }

        return `${props.props.allowIndexing === true ? "index" : "noindex"},
                ${props.props.allowFollowing === true ? "follow" : "nofollow"}`;
    }, [props.props?.allowFollowing, props.props?.allowIndexing]);

    return (
        <main className={S.wrapper} id="main">
            <Helmet>
                <title>{props.props?.metaTitle || props.name}</title>
                <meta name="description" content={props.props?.metaDescription} />
                <meta name="robots" content={robotsIndexFollow} />
            </Helmet>

            {/* Breadcrumbs */}
            <DA_Container noVerticalPadding>
                <DA_Breadcrumb breadcrumbItems={breadcrumbItems} lastIsLink={true} />
            </DA_Container>

            {/* Hero */}
            {props?.props?.hero?.[0]
                ? props.props.hero?.map((block, index) => <DynamicBlock key={index} {...block} />)
                : null}

            {/* Blocks */}
            {props?.props?.modules?.[0]
                ? props.props.modules?.map((block, index) => (
                      <DynamicBlock key={index} {...block} />
                  ))
                : null}
        </main>
    );
};

export default ContentPage;
