import { useCallback, useEffect, useState } from "react";
import { getAbsoluteUrl } from "@helpers/getAbsoluteUrl.helper";
import { MoreEmpolyeesItem } from "../MoreEmployees.interface";

export const useItems = (take = 10, employeeGroupId: number) => {
    const [items, setItems] = useState<MoreEmpolyeesItem | undefined>(undefined);
    const [viewState, setViewState] = useState<string>("INIT");

    const fetchItems = useCallback(async () => {
        const cultureCode = globalThis?.cultures[0]?.alias;
        try {
            const url = getAbsoluteUrl(`/getemployee?id=${employeeGroupId}`, "SPA", {
                rootNodeId: globalThis.rootNodeId,
                pageNumber: "1",
                pageSize: take.toString(),
            });
            const request = await fetch(`${url}&cultureCode=${cultureCode}`);

            if (request) {
                const response = await request.json();
                return response;
            }
        } catch {
            // Empty
        }
    }, [take, employeeGroupId]);

    const getItems = useCallback(async () => {
        try {
            const response = await fetchItems();

            if (response) {
                setItems(response);
                setViewState("DONE");
            } else {
                throw new Error();
            }
        } catch {
            setItems(undefined);
            setViewState("ERROR");
        }
    }, [fetchItems]);

    // Init

    useEffect(() => {
        getItems();
    }, [getItems]);

    return {
        items,
        viewState,
    };
};
