import React from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import S from "./Title.module.scss";

export enum DA_TitleTag {
    DIV = "DIV",
    H1 = "H1",
    H2 = "H2",
    H3 = "H3",
    H4 = "H4",
    H5 = "H5",
    P = "P",
}

// A typeface is the design of lettering that can include variations in size,
// weight (e.g. bold), slope (e.g. italic), width (e.g. condensed), and so on.
export enum DA_TitleTypeface {
    H1 = "H1",
    H2 = "H2",
    H3 = "H3",
    H4 = "H4",
    H5 = "H5",
}

interface TitleWithTypeface {
    h1?: never;
    h2?: never;
    h3?: never;
    h4?: never;
    h5?: never;
    typeface: DA_TitleTypeface;
}

interface TitleWithH1 {
    h1: true;
    h2?: never;
    h3?: never;
    h4?: never;
    h5?: never;
    typeface?: never;
}

interface TitleWithH2 {
    h1?: never;
    h2: true;
    h3?: never;
    h4?: never;
    h5?: never;
    typeface?: never;
}

interface TitleWithH3 {
    h1?: never;
    h2?: never;
    h3: true;
    h4?: never;
    h5?: never;
    typeface?: never;
}

interface TitleWithH4 {
    h1?: never;
    h2?: never;
    h3?: never;
    h4: true;
    h5?: never;
    typeface?: never;
}

interface TitleWithH5 {
    h1?: never;
    h2?: never;
    h3?: never;
    h4?: never;
    h5: true;
    typeface?: never;
}

interface BaseProps {
    tag?: DA_TitleTag;
    children: React.ReactNode;
    appendix?: React.ReactNode;
    noMargin?: boolean;
    fullWidth?: boolean;
    itemProp?: string;
    inheritColor?: boolean;
    numberOfLines?: number;
    id?: string;
}

export type DA_TitleProps = BaseProps &
    (TitleWithTypeface | TitleWithH1 | TitleWithH2 | TitleWithH3 | TitleWithH4 | TitleWithH5);

export const DA_Title = (props: DA_TitleProps) => {
    const {
        typeface,
        tag,
        children,
        appendix,
        noMargin,
        fullWidth,
        itemProp,
        inheritColor,
        numberOfLines,
        id,
        ...headings
    } = props;

    const {
        h2 = typeface === DA_TitleTypeface.H2,
        h3 = typeface === DA_TitleTypeface.H3,
        h4 = typeface === DA_TitleTypeface.H4,
        h5 = typeface === DA_TitleTypeface.H5,
    } = headings;

    const titleClass = cn(
        S.title,
        cn(h2 && S.h2, h3 && S.h3, h4 && S.h4, h5 && S.h5),
        noMargin && S.noMargin,
        fullWidth && S.fullWidth,
        inheritColor && S.inheritColor
    );

    const HtmlTag = (tag ? tag === DA_TitleTag.H2 : h2)
        ? "h2"
        : (tag ? tag === DA_TitleTag.H3 : h3)
        ? "h3"
        : (tag ? tag === DA_TitleTag.H4 : h4)
        ? "h4"
        : (tag ? tag === DA_TitleTag.H5 : h5)
        ? "h5"
        : tag === DA_TitleTag.P
        ? "p"
        : tag === DA_TitleTag.DIV
        ? "div"
        : "h1";

    return (
        <div className={titleClass} id={id}>
            <HtmlTag
                className={cn(S.titleTag, numberOfLines && S.clamp)}
                itemProp={itemProp}
                style={{ "--title-line-clamp": numberOfLines } as React.CSSProperties}
            >
                {children}
            </HtmlTag>

            {/** Appendix */}
            {appendix && <div className={S.appendix}>{appendix}</div>}
        </div>
    );
};
