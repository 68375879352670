import React from "react";
import { useScreen } from "../../../contexts/screen.context";
import { DA_FooterObject } from "../../../interfaces/footerObject.interface";
import { DA_DesktopFooter } from "./DesktopFooter/DesktopFooter.component";
import { DA_MobileFooter } from "./MobileFooter/MobileFooter.component";

export interface DA_SiteFooterData {
    data: DA_FooterObject;
}

export const DA_SiteFooter = ({ data }: DA_SiteFooterData) => {
    const { isMobile } = useScreen();
    return data ? (
        isMobile ? (
            <DA_MobileFooter data={data} />
        ) : (
            <DA_DesktopFooter data={data} />
        )
    ) : null;
};
