import React, { useCallback, useEffect, useState } from "react";
import { UseMeasureRect } from "react-use/lib/useMeasure";
import { DA_NavigationPage, DA_NavigationPageSimple } from "../interfaces/navigationPage.interface";
import { useScreen } from "./screen.context";

export enum SiteHeaderPopUpType {
    MiniCart,
    AccountPicker,
}

type Props = {
    children: React.ReactNode;
    showServiceMenu: boolean;
    pageTree: DA_NavigationPage[] | DA_NavigationPageSimple[];
};

interface SiteHeaderHook extends Omit<Props, "children"> {
    headerMeasure?: UseMeasureRect;
    setHeaderMeasure: (rect: UseMeasureRect) => void;
    cartButtonMeasure?: UseMeasureRect;
    setCartButtonMeasure: (rect: UseMeasureRect) => void;
    farmInTimeButtonMeasure?: UseMeasureRect;
    setFarmInTimeButtonMeasure: (rect: UseMeasureRect) => void;
    accountButtonMeasure?: UseMeasureRect;
    setAccountButtonMeasure: (rect: UseMeasureRect) => void;
    showMiniCart: boolean;
    showAccountPicker: boolean;
    togglePopUp: (type: SiteHeaderPopUpType | undefined) => void;
    toggleMobileNav: () => void;
    closeMobileNav: () => void;
    showMobileNav: boolean;
    pageTree: DA_NavigationPage[] | DA_NavigationPageSimple[];
}

const SiteHeaderContext = React.createContext<SiteHeaderHook>({
    setHeaderMeasure: () => null,
    setCartButtonMeasure: () => null,
    setFarmInTimeButtonMeasure: () => null,
    setAccountButtonMeasure: () => null,
    showMiniCart: false,
    showAccountPicker: false,
    togglePopUp: () => null,
    toggleMobileNav: () => null,
    closeMobileNav: () => null,
    showServiceMenu: true,
    showMobileNav: false,
    pageTree: [],
});

export const SiteHeaderProvider = ({ children, ...inherited }: Props): JSX.Element => {
    const [headerMeasure, setHeaderMeasure] = useState<UseMeasureRect>();
    const [cartButtonMeasure, setCartButtonMeasure] = useState<UseMeasureRect>();
    const [farmInTimeButtonMeasure, setFarmInTimeButtonMeasure] = useState<UseMeasureRect>();
    const [accountButtonMeasure, setAccountButtonMeasure] = useState<UseMeasureRect>();
    const [showMiniCart, setShowMiniCart] = useState(false);
    const [showAccountPicker, setShowAccountPicker] = useState(false);
    const [showMobileNav, setShowMobileNav] = useState(false);
    const { toggleScrollLock } = useScreen();

    const togglePopUp = useCallback(
        (type: SiteHeaderPopUpType) => {
            setShowMiniCart(type === SiteHeaderPopUpType.MiniCart ? !showMiniCart : false);
            setShowAccountPicker(
                type === SiteHeaderPopUpType.AccountPicker ? !showAccountPicker : false
            );
        },
        [showMiniCart, showAccountPicker]
    );

    const closeMobileNav = useCallback(() => {
        setShowMobileNav(false);
    }, []);

    const toggleMobileNav = useCallback(() => {
        setShowMobileNav(!showMobileNav);
        toggleScrollLock(!showMobileNav);
    }, [showMobileNav, toggleScrollLock]);

    useEffect(() => {
        if (headerMeasure?.height) {
            document.documentElement.style.setProperty(
                "--header-height",
                `${headerMeasure.height}px`
            );
        }
    }, [headerMeasure]);

    return (
        <SiteHeaderContext.Provider
            value={{
                headerMeasure,
                setHeaderMeasure,
                cartButtonMeasure,
                setCartButtonMeasure,
                farmInTimeButtonMeasure,
                setFarmInTimeButtonMeasure,
                accountButtonMeasure,
                setAccountButtonMeasure,
                showMiniCart,
                showAccountPicker,
                togglePopUp,
                toggleMobileNav,
                closeMobileNav,
                showMobileNav,
                ...inherited,
            }}
        >
            {children}
        </SiteHeaderContext.Provider>
    );
};

export const useSiteHeader = (): SiteHeaderHook => React.useContext(SiteHeaderContext);
