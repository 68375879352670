import { getSharedGlobalVariables } from "@danishagro/shared/src/helpers/getSharedGlobalVariables.helper";
import { DA_NavigationPageSimple } from "@danishagro/shared/src/interfaces/navigationPage.interface";
import { DA_GlobalVariables } from "@danishagro/shared/src/interfaces/sharedGlobalVariables.interface";
import { isAbsoluteUrl } from "@danishagro/shared/src/helpers/isAbsoluteUrl.helper";
import { getAbsoluteUrl } from "./getAbsoluteUrl.helper";

interface UmbracoNav {
    alias: string;
    name: string;
    path: string;
    url: string;
    urlSegment: string;
    children?: UmbracoNav[];
}

export const getGlobalVariables = (
    customerNumber?: string,
    forceNew = false
): Promise<DA_GlobalVariables> =>
    new Promise((resolve, reject) =>
        getSharedGlobalVariables(forceNew)
            .then((sharedGlobalVariables) => {
                const currentCulture = globalThis.dictionaryObj?.culture || "da-dk";

                const getNavigationUrl = getAbsoluteUrl("/GetNavigation", "SPA", {
                    rootNodeId: globalThis.rootNodeId,
                });
                const getNavigationObj =
                    globalThis.navigationObj && !forceNew
                        ? Promise.resolve(globalThis.navigationObj)
                        : fetch(`${getNavigationUrl}&cultureCode=${currentCulture}`)
                              .then((res) => res.json())
                              .then((nav: UmbracoNav[]): DA_NavigationPageSimple[] => {
                                  function remapNav(arr: UmbracoNav[]): DA_NavigationPageSimple[] {
                                      return arr.map((item) => ({
                                          name: item.name,
                                          path: item.path,
                                          url: isAbsoluteUrl(item.url) ? item.url : undefined,
                                          hideInNavigation: false,
                                          children: item.children
                                              ? remapNav(item.children)
                                              : undefined,
                                      }));
                                  }

                                  return remapNav(nav);
                              });

                const getDictionaryUrl = getAbsoluteUrl("/GetTranslations", "SPA", {
                    rootNodeId: globalThis.rootNodeId,
                });
                const getDictionaryObj = globalThis.dictionaryObj
                    ? Promise.resolve(globalThis.dictionaryObj)
                    : fetch(`${getDictionaryUrl}&cultureCode=${currentCulture}`)
                          .then((res) => res.json())
                          .catch(() => ({
                              culture: currentCulture,
                              translations: [],
                          }));

                Promise.all([getNavigationObj, getDictionaryObj])
                    .then(([navigationObj, dictionaryObj]) => {
                        globalThis.dictionaryObj = dictionaryObj;
                        resolve({
                            ...sharedGlobalVariables,
                            currentCulture,
                            navigationObj: navigationObj,
                            dictionaryObj,
                            contentObj: { culture: dictionaryObj.culture, translations: {} }, // Only used on MyFarm
                            logoName: globalThis.siteSettingsObj?.logoName, // Only used on CMS
                            faviconColor: globalThis.siteSettingsObj?.faviconColor, // Only used on CMS
                            cultures: [], // Only used on MyFarm
                            siteVariant: globalThis.siteVariant,
                        });
                    })
                    .catch((err) => reject(err));
            })
            .catch((err) => reject(err))
    );
