import React, { useMemo } from "react";
import { isProduction } from "@helpers/isProduction.helper";
import { Helmet } from "react-helmet";
import Hero from "src/blocks/components/Hero/Hero.component";
import { DA_Container } from "@danishagro/shared/src/components/atoms/Container/Container.component";
import SocialMediaSharing from "src/blocks/components/SocialMediaSharing/SocialMediaSharing.component";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import { DA_Breadcrumb } from "@danishagro/shared/src/components/molecules/Breadcrumb/Breadcrumb.component";
import { usePage } from "@contexts/page.context";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_Title } from "@danishagro/shared/src/components/atoms/Title/Title.component";
import {
    DA_Button,
    DA_ButtonColor,
} from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { DynamicBlock } from "../../../../blocks/DynamicBlock.component";
import { useJobDetail } from "./hooks/useJobDetail.hook";
import { ContactInfo } from "./ContactInfo/ContactInfo.component";
// Import types
import { JobsPageProps } from "./JobsPage.interface";
import { LocationInfo } from "./LocationInfo/LocationInfo.component";
// Import style
import S from "./JobsPage.module.scss";

export const JobsPage = (props: JobsPageProps) => {
    const { currentPathDetails } = usePage();
    const { getDictionaryString } = useTranslations();

    const { data, viewState } = useJobDetail();

    // Path to job list
    const jobListUrl = globalThis?.siteSettingsObj?.jobPositionListPage?.url;

    const robotsIndexFollow = useMemo(() => {
        if (!isProduction()) {
            return "noindex, nofollow";
        }

        return `${props?.props?.allowIndexing === true ? "index" : "noindex"},
                ${props?.props?.allowFollowing === true ? "follow" : "nofollow"}`;
    }, [props?.props?.allowFollowing, props?.props?.allowIndexing]);

    const {
        advertisements = [],
        positionCategory = {},
        department = "",
        advertisementUrl = "",
        applicationFormUrl = "",
        startDate = "",
        startDateASAP = false,
        applicationDue = "",
        created = "",
        users = [],
        workPlaceCoordinates = {},
        city = "",
        name = "",
        country = "",
        address = "",
        zip = "",
        showApplyButton = true,
    } = data ?? {};

    const isDepartmentValid = department && "name" in department;
    const areUsersValid = users && "projectParticipants" in users;
    const areWorkPlaceCoordinatesValid = typeof workPlaceCoordinates === "string";

    //date format - just show the date without time
    //split the string into date and time
    const formattedStartDateSplitParts = startDate.split(" ");
    const formattedDatePart = formattedStartDateSplitParts[0];

    //TODO: Use for logo later
    // const talentRecruiterCompanyName = globalThis.siteSettingsObj?.talentRecruiterCompanyName;

    return (
        <main className={S.wrapper} id="main">
            <Helmet>
                <title>{props?.props?.metaTitle}</title>
                <meta name="description" content={props?.props?.metaDescription} />
                <meta name="robots" content={robotsIndexFollow} />
            </Helmet>

            {/* Breadcrumbs */}
            <DA_Container noVerticalPadding>
                <DA_Breadcrumb
                    breadcrumbItems={[
                        ...currentPathDetails.map(({ name, path }) => ({
                            title: name,
                            href: path,
                        })),
                    ]}
                    lastIsLink={true}
                />
            </DA_Container>
            {/** ViewState: DONE */}
            {viewState === "DONE" && data ? (
                <>
                    {positionCategory && advertisements?.[0]?.imageUrl && (
                        <Hero
                            tagline=""
                            headline=""
                            text=""
                            image={{
                                name: positionCategory?.name,
                                url: advertisements?.[0]?.imageUrl,
                                width: 800,
                                height: 600,
                            }}
                            className={S.hero}
                        />
                    )}
                    <DA_Container className={S.container} noVerticalPadding>
                        <main className={S.main} id="main">
                            <div className={S.inner}>
                                <div>
                                    {/* Logo */}
                                    {/*
                                    {talentRecruiterCompanyName.length > 0 ? (
                                        <div className={S.logoWrapper}>
                                            <h1>LOGO</h1>
                                        </div>
                                    ) : null}
                                    */}

                                    {/* Title */}
                                    <DA_Title h1>{name}</DA_Title>
                                    <strong className={S.introductory}>
                                        {props?.props?.introductory}
                                    </strong>
                                    <div className={S.content}>
                                        {advertisements?.map((advertisement, index) => {
                                            const contentWithHtmlBreaks =
                                                advertisement.content.replace(/\\r\\n|\\t/g, "");
                                            return (
                                                <DA_Text
                                                    key={index}
                                                    html={contentWithHtmlBreaks}
                                                    noPadding
                                                    className={S.textWrapper}
                                                />
                                            );
                                        })}
                                    </div>
                                    {/* Contact person */}
                                    <div>
                                        {areUsersValid && (
                                            <ContactInfo
                                                title={getDictionaryString(
                                                    "Job.ContactPerson.title"
                                                )}
                                                subTitle={getDictionaryString(
                                                    "Job.contactPerson.subTitle"
                                                )}
                                                users={users}
                                                userImage={true}
                                                className={S.mainContactPerson}
                                            />
                                        )}
                                    </div>
                                    <SocialMediaSharing />
                                </div>
                                <div className={S.asideWrapper}>
                                    <div className={S.jobInfoWrapper}>
                                        {/*  */}
                                        <DA_Title h2>
                                            <span className={S.jobTitle}>
                                                {getDictionaryString("Job.jobInfo.headline")}
                                            </span>
                                        </DA_Title>
                                        {isDepartmentValid &&
                                            areUsersValid &&
                                            areWorkPlaceCoordinatesValid && (
                                                <ContactInfo
                                                    advertisementUrl={advertisementUrl}
                                                    applicationFormUrl={applicationFormUrl}
                                                    startDate={formattedDatePart}
                                                    startDateASAP={startDateASAP}
                                                    applicationDue={applicationDue}
                                                    created={created}
                                                    users={users}
                                                    title={getDictionaryString(
                                                        "Job.ContactPerson.label"
                                                    )}
                                                    hasContactTitle={true}
                                                    className={S.asideContactPerson}
                                                />
                                            )}
                                        {showApplyButton && (
                                            <div className={S.buttonWrapper}>
                                                <DA_Button
                                                    title={getDictionaryString("Job.ApplyNow")}
                                                    href={applicationFormUrl}
                                                    target="_blank"
                                                    color={DA_ButtonColor.White}
                                                    className={S.buttonApply}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        {isDepartmentValid && areWorkPlaceCoordinatesValid ? (
                                            <LocationInfo
                                                workPlaceCoordinates={workPlaceCoordinates}
                                                department={department}
                                                city={city}
                                                country={country}
                                                zip={zip}
                                                address={address}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </main>
                    </DA_Container>

                    {/*Blocks */}
                    {props?.props?.modules?.[0]
                        ? props.props.modules?.map((block, index) => (
                              <DynamicBlock key={index} {...block} />
                          ))
                        : null}
                </>
            ) : null}

            {/** ViewState: EMPTY */}
            {viewState === "EMPTY" || viewState === "ERROR" ? (
                <DA_Container className={S.container} noVerticalPadding>
                    <main className={S.main} id="main">
                        {/* <NotFoundPage {...props} /> */}
                        <DA_Title h1>{getDictionaryString("Job.NonFoundTitle")}</DA_Title>
                        <DA_Text
                            html={getDictionaryString("Job.NonFoundText")}
                            noPadding
                            className={S.textWrapper}
                        />
                        {jobListUrl && (
                            <DA_Button
                                title={getDictionaryString("Job.GoBackToListPage")}
                                href={jobListUrl}
                                target="_self"
                                color={DA_ButtonColor.Action}
                                className={S.goBackBtn}
                            />
                        )}
                    </main>
                </DA_Container>
            ) : null}
        </main>
    );
};
