import { CSSObjectWithLabel } from "react-select";
import { StylesConfig } from "react-select/dist/declarations/src/styles";

interface Props {
    small?: boolean;
    invalid?: boolean;
    alignMenuRight?: boolean;
    isMulti?: boolean;
    isGrouped?: boolean;
    isMobile?: boolean;
}

export const getSelectStyles = ({
    small,
    invalid,
    alignMenuRight,
    isMulti,
    isGrouped,
    isMobile,
}: Props): StylesConfig => ({
    control: (provided: CSSObjectWithLabel, state) => ({
        ...provided,
        cursor: state.isDisabled ? "default" : "pointer",
        minHeight: 0,
        height: small ? `${30 / 16}rem` : `${40 / 16}rem`,
        background: state.isDisabled && "var(--color-grey-5)",
        color: state.isDisabled ? "var(--color-grey-40)" : "inherit",
        boxShadow: `inset 0 0 0 1px ${
            state.isFocused
                ? "var(--color-primary-1)"
                : invalid
                ? "var(--color-alert)"
                : "transparent"
        }`,
        borderRadius: "var(--border-radius-small)",
        border: `1px solid var(${
            state.isFocused ? "--color-primary-1" : invalid ? "--color-alert" : "--color-grey-40"
        })`,
        "&:hover": {
            borderColor: "var(--color-primary-1)",
        },
    }),
    valueContainer: (provided: CSSObjectWithLabel) => ({
        ...provided,
        padding: `0 ${(small ? 5 : 1) / 16}rem 0 ${(small ? 6 : 10) / 16}rem`,
        fontSize: small ? "0.75rem" : "1rem",
        zIndex: isMobile ? 9 : "inherit",
        paddingRight: isMobile ? "20px" : "inherit",
        marginRight: isMobile ? "10px" : "0",
    }),
    placeholder: (provided: CSSObjectWithLabel, state) => ({
        ...provided,
        margin: 0,
        color: state.isDisabled ? "var(--color-grey-40)" : "var(--color-grey-80)",
    }),
    indicatorsContainer: (provided: CSSObjectWithLabel) => ({
        ...provided,
        position: isMobile ? "absolute" : "initial",
        right: isMobile ? 0 : "inherit",
        top: isMobile ? "50%" : "inherit",
        transform: isMobile ? "translateY(-50%)" : "initial",
        zIndex: isMobile ? 0 : "inherit", // Inserted this
    }),
    option: (provided: CSSObjectWithLabel, state) => ({
        ...provided,
        paddingLeft: isGrouped ? "25px" : provided.paddingLeft,
        cursor: "pointer",
        background: isMulti
            ? state.isFocused
                ? "var(--color-primary-4)"
                : "inherit"
            : state.isSelected
            ? "var(--color-primary-1)"
            : state.isFocused
            ? "var(--color-primary-4)"
            : undefined,
        color: isMulti ? "inherit" : state.isSelected ? "var(--color-white)" : undefined,
        "&:active": {
            background: "var(--color-primary-4)",
        },
    }),
    singleValue: (provided: CSSObjectWithLabel) => ({
        ...provided,
        margin: 0,
    }),
    menuPortal: (provided: CSSObjectWithLabel) => ({
        ...provided,
        zIndex: 1000,
    }),
    menu: (provided: CSSObjectWithLabel) => ({
        ...provided,
        zIndex: 10,
        margin: 0,
        right: alignMenuRight ? 0 : undefined,
        whiteSpace: "nowrap",
        borderRadius: "var(--border-radius-small)",
        boxShadow: "var(--shadow-level-3)",
        width: "auto",
        minWidth: "100%",
    }),
    menuList: (provided: CSSObjectWithLabel) => ({
        ...provided,
        padding: 0,
    }),
    input: (provided: CSSObjectWithLabel) => ({
        ...provided,
        margin: 0,
    }),
});
