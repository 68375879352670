import { FetchOptions } from "../interfaces/fetchOptions.interface";
import { getApiUrl } from "./getApiUrl.helper";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type QueryObject = Record<string, any>;

export const fetchHandler = (
    fetchWithAuth: (url: RequestInfo, options?: RequestInit) => Promise<Response>,
    rootUrl: string,
    method: "POST" | "PUT" | "PATCH" | "DELETE",
    path: string,
    parameters: QueryObject | string | number | boolean,
    options?: FetchOptions
) =>
    fetchWithAuth(getApiUrl(rootUrl, path), {
        ...options,
        method,
        headers: {
            "Content-Type": "application/json",
        },
        credentials: method === "PATCH" || method === "DELETE" ? "include" : undefined,
        body:
            typeof parameters === "undefined"
                ? undefined
                : typeof parameters === "object"
                ? JSON.stringify(parameters)
                : typeof parameters !== "string"
                ? parameters.toString()
                : parameters,
    });
