import { useCallback, useEffect, useState } from "react";
import { getAbsoluteUrl } from "@helpers/getAbsoluteUrl.helper";
import { EventListPageProps } from "../EventListPage.interface";

export const useItems = (rootFolderId?: string) => {
    const [items, setItems] = useState<EventListPageProps[]>(undefined);
    const [viewState, setViewState] = useState<string>("INIT");

    const fetchItems = useCallback(async () => {
        const cultureCode = globalThis?.cultures[0]?.alias;
        try {
            const parameters = {
                rootNodeId: globalThis.rootNodeId,
            };

            if (typeof rootFolderId !== "undefined") {
                parameters["rootFolderId"] = rootFolderId;
            }

            const url = getAbsoluteUrl("/geteventlist", "SPA", parameters);
            const request = await fetch(`${url}&cultureCode=${cultureCode}`);

            if (request) {
                const response = await request.json();
                return response;
            }
        } catch {
            // Empty
        }
    }, [rootFolderId]);

    const getItems = useCallback(async () => {
        try {
            const response = await fetchItems();

            if (response) {
                setItems(response);
                setViewState("DONE");
            } else {
                throw new Error();
            }
        } catch {
            setItems(undefined);
            setViewState("ERROR");
        }
    }, [fetchItems]);

    // Init

    useEffect(() => {
        getItems();
    }, [getItems]);

    return {
        items,
        viewState,
    };
};
