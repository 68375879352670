import React, { useMemo } from "react";
import { DA_Container } from "@danishagro/shared/src/components/atoms/Container/Container.component";
import { Helmet } from "react-helmet";
import {
    DA_Title,
    DA_TitleTag,
} from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { isProduction } from "@helpers/isProduction.helper";
import { DA_Breadcrumb } from "@danishagro/shared/src/components/molecules/Breadcrumb/Breadcrumb.component";
import { usePage } from "@contexts/page.context";
import { DynamicBlock } from "../../../../blocks/DynamicBlock.component";
import { EmployeeProps } from "../TestimonialsPage/components/MoreEmployees/MoreEmployees.interface";
import Card from "./components/Card.component";
import { useItems } from "./hooks/useItems.hook";
import { TestimonialListPageProps } from "./TestimonialListPage.interface";
import S from "./TestimonialListPage.module.scss";

export const TestimonialListPage = (props: TestimonialListPageProps) => {
    const { currentPathDetails } = usePage();

    const { items, viewState } = useItems(props.props?.rootFolder?.id);

    const robotsIndexFollow = useMemo(() => {
        if (!isProduction()) {
            return "noindex, nofollow";
        }

        return `${props.props.allowIndexing === true ? "index" : "noindex"},
                ${props.props.allowFollowing === true ? "follow" : "nofollow"}`;
    }, [props.props?.allowFollowing, props.props?.allowIndexing]);

    // Get amount of employees for each groupe
    const getEmployeeGroupClass = (employees: Array<EmployeeProps>) => {
        switch (employees.length) {
            case 1:
            case 2:
                return S.twoLines;
            case 3:
                return S.threeLines;
            default:
                return S.fourLines;
        }
    };

    return (
        <div>
            <Helmet>
                <title>{props.props?.metaTitle || props.name}</title>
                <meta name="description" content={props.props?.metaDescription} />
                <meta name="robots" content={robotsIndexFollow} />
            </Helmet>

            {/* Breadcrumbs */}
            <DA_Container noVerticalPadding>
                <DA_Breadcrumb
                    breadcrumbItems={[
                        ...currentPathDetails.map(({ name, path }) => ({
                            title: name,
                            href: path,
                        })),
                    ]}
                    lastIsLink={true}
                />
            </DA_Container>

            {/* Hero */}
            {props?.props?.hero?.[0]
                ? props?.props?.hero?.map((block, index) => <DynamicBlock key={index} {...block} />)
                : null}

            <DA_Container noVerticalPadding>
                <div className={S.inner}>
                    {/** Text */}
                    {props.props.text ? (
                        <div className={S.textWrapper}>
                            <DA_Text alignCenter html={props.props.text} />
                        </div>
                    ) : null}
                    {/** List */}
                    <div className={S.list}>
                        {viewState === "DONE" ? (
                            <>
                                {items?.employeeGroups?.map((group) => (
                                    <div className={S.jobList} key={group.id}>
                                        <DA_Title h2 tag={DA_TitleTag.H2} fullWidth>
                                            {group.name}
                                        </DA_Title>
                                        <div
                                            className={cn(
                                                S.groupeList,
                                                getEmployeeGroupClass(group.employees)
                                            )}
                                        >
                                            {group.employees.map((employee, employeeIndex) => (
                                                <div key={employeeIndex}>
                                                    <Card
                                                        key={employee.id}
                                                        employee={employee}
                                                        className={S.item}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </>
                        ) : null}
                    </div>

                    {/* Blocks */}
                    {props?.props?.modules?.[0]
                        ? props.props.modules?.map((block, index) => (
                              <DynamicBlock key={index} {...block} />
                          ))
                        : null}
                </div>
            </DA_Container>
        </div>
    );
};
