import React from "react";
import { Helmet } from "react-helmet";
import { DA_ImageProps } from "../../../interfaces/imageProps.interface";

export interface DA_ImageImgProps extends DA_ImageProps {
    priority?: boolean;
}

export const DA_Image = React.forwardRef<HTMLImageElement, DA_ImageImgProps>(
    ({ alt, src, priority = false, ...rest }, ref) => (
        <>
            {priority && (
                <Helmet>
                    <link rel="preload" as="image" href={src} crossOrigin={rest.crossOrigin} />
                </Helmet>
            )}
            <img
                ref={ref}
                alt={alt}
                src={src}
                {...rest}
                loading={priority ? "eager" : rest.loading || "lazy"}
                decoding="async"
            />
        </>
    )
);
DA_Image.displayName = "DA_Image";
