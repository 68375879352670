import React, { useEffect, useRef } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ApiProvider } from "@contexts/api.context";
import { AppDataProvider, useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { BottomSheetProvider } from "@danishagro/shared/src/contexts/bottomSheet/bottomSheet.context";
import { DA_SiteFooter } from "@danishagro/shared/src/components/organisms/SiteFooter/SiteFooter.component";
import { DA_SiteHeader } from "@danishagro/shared/src/components/organisms/SiteHeader/SiteHeader.component";
import { DynamicPage } from "@danishagro/cms/src/layout/pages/DynamicPage.component";
import { ModalProvider } from "@danishagro/shared/src/contexts/modal.context";
import { PageProvider, usePage } from "@contexts/page.context";
import { ScreenProvider } from "@danishagro/shared/src/contexts/screen.context";
import { SiteHeaderProvider } from "@danishagro/shared/src/contexts/siteHeader.context";
import { SiteSettingsProvider } from "@contexts/settings.context";
import { TranslationsProvider } from "@danishagro/shared/src/contexts/translations/translations.context";
import { CartProvider } from "@danishagro/shared/src/contexts/cart/cart.context";
import { getGlobalVariables } from "@helpers/getGlobalVariables.helper";
import useTrackPageView from "@danishagro/shared/src/gtm/useTagManger";
import useTrackScrollDistance from "@danishagro/shared/src/gtm/useTrackScrollDistance ";
import { ContextProps } from "./App.interface";
import S from "./App.module.scss";
import "@danishagro/shared/src/styles/global/main.scss";

export const SecondaryContextWrapper = ({ children, page }: ContextProps) => {
    const { isAppReady, customerNumber } = useAppData();

    return isAppReady ? (
        <ApiProvider>
            <CartProvider customerNumber={customerNumber}>
                <ScreenProvider>
                    <ModalProvider>
                        <BottomSheetProvider>
                            <PageProvider initial={{ page: page }}>{children}</PageProvider>
                        </BottomSheetProvider>
                    </ModalProvider>
                </ScreenProvider>
            </CartProvider>
        </ApiProvider>
    ) : null;
};

export const MainContextWrapper = ({ children, siteSettings, page }: ContextProps) => (
    <Router>
        <AppDataProvider currentSite="CMS" getGlobalVariables={getGlobalVariables}>
            <TranslationsProvider>
                <SiteSettingsProvider initial={{ siteSettings: siteSettings }}>
                    <SecondaryContextWrapper page={page}>{children}</SecondaryContextWrapper>
                </SiteSettingsProvider>
            </TranslationsProvider>
        </AppDataProvider>
    </Router>
);

const AppComponent = () => {
    const { pageTree, page } = usePage();
    const { siteVariant } = useAppData();
    // GTM Tracking
    useTrackPageView(globalThis?.pageContent?.alias);
    useTrackScrollDistance("scroll_distance");

    const customerServiceLinks = useRef(
        globalThis.footerObj?.columns?.[0]?.items?.filter(({ itemType }) => itemType === "link") ||
            []
    ).current;

    useEffect(() => {
        // replaces spaces with dashes
        if (siteVariant?.alias) {
            const sanitizedAlias = siteVariant.alias.replace(/\s+/g, "-");

            const themeClass = document.body.className
                .replace(/\s+/, "")
                .split(" ")
                .find((className) => className.startsWith("theme--"));

            if (themeClass) {
                document.body.classList.replace(themeClass, `theme--${sanitizedAlias}`);
            } else {
                document.body.classList.add(`theme--${sanitizedAlias}`);
            }
        }
    }, [siteVariant]);

    return (
        <div className={S.wrapper}>
            <SiteHeaderProvider pageTree={pageTree} showServiceMenu={globalThis.apiGatewayUrl}>
                <DA_SiteHeader customerServiceLinks={customerServiceLinks} />
            </SiteHeaderProvider>

            {/** Content */}
            <div className={S.dynamicPage}>
                <DynamicPage />
            </div>

            {page ? <DA_SiteFooter data={globalThis.footerObj} /> : null}
        </div>
    );
};

export const App = () => {
    const preloadedPage = globalThis.pageContent;
    const preloadedSiteSettings = globalThis.siteSettingsObj;

    return (
        <MainContextWrapper page={preloadedPage} siteSettings={preloadedSiteSettings}>
            <AppComponent />
        </MainContextWrapper>
    );
};
