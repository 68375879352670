import React, { useCallback, useMemo, useRef } from "react";
import {
    DA_ContentTranslationOptions,
    DA_DictionaryTranslationOptions,
} from "@danishagro/shared/src/interfaces/translations.interface";
import {
    _getDictionaryString,
    handleTranslation,
} from "@danishagro/shared/src/helpers/getDictionaryString.helper";
import { isDevMode } from "@danishagro/shared/src/helpers/isDevMode.helper";
import { useAppData } from "../appData.context";
import { ModalProvider } from "../modal.context";
import { _EditButton } from "./EditButton.component";

interface TranslationsHook {
    getDictionaryItem: (
        alias: string,
        options?: DA_DictionaryTranslationOptions
    ) => React.ReactNode;
    getDictionaryString: (alias: string, options?: DA_DictionaryTranslationOptions) => string;
    getTextItem: (alias: string, options?: DA_ContentTranslationOptions) => React.ReactNode;
    globalTranslations: {
        pageTitlePostfix: string;
    };
}

const TranslationsContext = React.createContext<TranslationsHook>({
    getDictionaryItem: () => "",
    getDictionaryString: () => "",
    getTextItem: () => "",
    globalTranslations: {
        pageTitlePostfix: "",
    },
});

type TranslationsProviderProps = {
    children: React.ReactNode;
};

export const TranslationsProvider = ({ children }: TranslationsProviderProps): JSX.Element => {
    const { currentSite, dictionaryObj, contentObj, isMasterAdmin } = useAppData();

    const highlightMissingItems = useMemo(() => {
        return currentSite === "CMS"
            ? true
            : currentSite === "B2B"
            ? isDevMode
            : currentSite === "MYFARM"
            ? isMasterAdmin
            : false;
    }, [currentSite, isMasterAdmin]);

    const getDictionaryString = useCallback(
        (alias, options) =>
            _getDictionaryString(alias, highlightMissingItems, options).replace(/\n/g, "<br/>"),
        [highlightMissingItems]
    );

    const getDictionaryItem = useCallback(
        (alias: string, options?: DA_DictionaryTranslationOptions): React.ReactNode => {
            if (currentSite !== "MYFARM") {
                return getDictionaryString(alias, options);
            }

            const lowerCaseAlias = alias.toLocaleLowerCase();
            const string = dictionaryObj?.translations
                ? dictionaryObj.translations[lowerCaseAlias]
                : "";
            const stringExist = typeof string !== "undefined";
            const returnString =
                !stringExist && options?.dontShowFallback
                    ? ""
                    : handleTranslation(alias, string, options);

            return (
                <span style={{ position: "relative", display: "inline-block" }}>
                    {returnString}
                    {highlightMissingItems && (
                        <ModalProvider>
                            <_EditButton
                                alias={lowerCaseAlias}
                                type="dictionary"
                                alwaysVisible={!stringExist}
                            />
                        </ModalProvider>
                    )}
                </span>
            );
        },
        [dictionaryObj, highlightMissingItems, currentSite, getDictionaryString]
    );

    const getTextItem = useCallback(
        (alias: string, options: DA_ContentTranslationOptions = {}): React.ReactNode => {
            if (currentSite !== "MYFARM") throw "getTextItem() only works on MyFarm";

            const { disableEditor, ...replaceStrings } = options;
            const lowerCaseAlias = alias.toLocaleLowerCase();
            const string = contentObj?.translations ? contentObj.translations[lowerCaseAlias] : "";
            const stringExist = typeof string !== "undefined";
            const returnString = handleTranslation(alias, string, replaceStrings);

            return (
                <div style={{ position: "relative" }}>
                    <div dangerouslySetInnerHTML={{ __html: returnString }} />
                    {highlightMissingItems && !disableEditor && (
                        <ModalProvider>
                            <_EditButton
                                alias={lowerCaseAlias}
                                type="text"
                                alwaysVisible={!stringExist}
                            />
                        </ModalProvider>
                    )}
                </div>
            );
        },
        [contentObj, highlightMissingItems, currentSite]
    );

    const globalTranslations = useRef({
        pageTitlePostfix: _getDictionaryString(`page-title-${globalThis.siteVariant}`, isDevMode),
    }).current;

    return (
        <TranslationsContext.Provider
            value={{
                getDictionaryItem,
                getDictionaryString,
                getTextItem,
                globalTranslations,
            }}
        >
            {children}
        </TranslationsContext.Provider>
    );
};

export const useTranslations = (): TranslationsHook => React.useContext(TranslationsContext);
