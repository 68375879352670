import React, { useMemo } from "react";
import {
    DA_Button,
    DA_ButtonColor,
} from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { DA_InlineLink } from "@danishagro/shared/src/components/atoms/InlineLink/InlineLink.component";
import { getAbsoluteUrl } from "@helpers/getAbsoluteUrl.helper";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { ButtonsProps } from "./Buttons.interface";
import S from "./Buttons.module.scss";

export const Buttons = (props: ButtonsProps) => {
    const distinctButtons = useMemo(() => {
        const result = props.buttons?.reduce((previous, current) => {
            const existingButton = previous.find((x) => x.alias === current.alias);

            if (!existingButton) {
                previous.push(current);
            }

            return previous;
        }, []);

        return result;
    }, [props.buttons]);

    const { currentSite } = useAppData();
    const shouldHaveLargeText = currentSite === "CMS" ? true : false;

    return (
        <div className={S.buttons}>
            {distinctButtons?.map((button, index) => {
                let solidButtonColor, outlineButtonColor, clearButtonColorClass;

                switch (props.theme) {
                    case "FULL_WHITE":
                        solidButtonColor = DA_ButtonColor.White;
                        outlineButtonColor = DA_ButtonColor.White;
                        clearButtonColorClass = S.inlineLinkTheme;
                        break;
                    case "PARTIAL_WHITE":
                        outlineButtonColor = DA_ButtonColor.White;
                        clearButtonColorClass = S.inlineLinkTheme;
                        break;
                    case "FULL_PRIMARY":
                        solidButtonColor = DA_ButtonColor.Primary;
                        outlineButtonColor = DA_ButtonColor.Primary;
                        break;
                    case "FULL_ACCENT":
                        solidButtonColor = DA_ButtonColor.Accent;
                        outlineButtonColor = DA_ButtonColor.Accent;
                        break;
                    default:
                        break;
                }

                const target = button.target || "_self";

                return (
                    <div key={index}>
                        {/** Solid */}
                        {button.alias === "buttonSolid" && button.url ? (
                            <DA_Button
                                title={button.label}
                                href={getAbsoluteUrl(button.url)}
                                target={target}
                                icon={button.icon}
                                color={solidButtonColor}
                                largeText={shouldHaveLargeText}
                            />
                        ) : null}

                        {/** Outline */}
                        {button.alias === "buttonOutline" && button.url ? (
                            <DA_Button
                                title={button.label}
                                href={getAbsoluteUrl(button.url)}
                                target={target}
                                icon={button.icon}
                                isGhost={true}
                                color={outlineButtonColor}
                                largeText={shouldHaveLargeText}
                            />
                        ) : null}

                        {/** Clear */}
                        {button.alias === "buttonClear" && button.url ? (
                            <DA_InlineLink
                                title={button.label}
                                href={getAbsoluteUrl(button.url)}
                                target={target}
                                icon={button.icon}
                                className={clearButtonColorClass}
                                largeText={shouldHaveLargeText}
                            />
                        ) : null}
                    </div>
                );
            })}
        </div>
    );
};
