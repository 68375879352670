import { useMemo } from "react";
import { GroupBase } from "react-select";
import { DA_SelectOption, DA_SelectProps } from "../Select.props";

type Props<T> = Pick<DA_SelectProps<T>, "getValue" | "value"> & {
    parsedOptions: DA_SelectOption[] | GroupBase<DA_SelectOption>[];
};

export const useSelectedOption = <T>({ parsedOptions, value }: Props<T>) =>
    useMemo(
        () =>
            parsedOptions &&
            parsedOptions.length &&
            // @ts-ignore
            typeof parsedOptions[0].options !== "undefined"
                ? (parsedOptions as GroupBase<DA_SelectOption>[]).reduce<DA_SelectOption>(
                      (foundOption, group) =>
                          foundOption
                              ? foundOption
                              : group.options.find((option) => option.value === value),
                      undefined
                  )
                : (parsedOptions as DA_SelectOption[]).find((option) => option.value === value),
        [parsedOptions, value]
    );
