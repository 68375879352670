import React, {
    lazy,
    ReactNode,
    Suspense,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { snapPoints } from "react-spring-bottom-sheet/dist/types";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_Icon, DA_IconNames } from "../../components/atoms/Icon/Icon.component";
import { useSiteHeader } from "../siteHeader.context";
import { useScreen } from "../screen.context";
import { DA_Title } from "../../components/atoms/Title/Title.component";
import S from "./bottomSheet.module.scss";

export interface BottomSheetOptions {
    id: string;
    header?: ReactNode;
    headerTitle?: ReactNode;

    nestedSheet?: boolean;
    contentPadding?: boolean;
    contentPaddingLarge?: boolean;
    showDismissHandle?: boolean;
    nonBlocking?: boolean;
    snapPoints?: snapPoints | number[];

    className?: string;

    onWillDismiss?: () => void;

    // Custom function to execute on close
    closeButtonToBack?: boolean;
    hideCloseButton?: boolean;
    hideBackButton?: boolean;
}

interface BottomSheetHook {
    presentBottomSheet: (component: ReactNode, options: BottomSheetOptions) => void;
    dismissBottomSheet: () => void;
    activeBottomSheet: string;
}

const BottomSheetContext = React.createContext<BottomSheetHook>({} as BottomSheetHook);

type Props = {
    children: React.ReactNode;
};

const BottomSheet = lazy(() =>
    import("react-spring-bottom-sheet").then(({ BottomSheet }) => ({ default: BottomSheet }))
);

export const BottomSheetProvider = ({ children }: Props): JSX.Element => {
    const [data, setData] = useState<{ component: ReactNode; options: BottomSheetOptions }[]>([]);
    const [activeBottomSheet, setActiveBottomSheet] = useState("");
    const { isMobileOrTablet } = useScreen();
    const currentOptions = data.length > 0 ? data[data.length - 1].options : undefined;

    const { togglePopUp } = useSiteHeader();

    const presentBottomSheet = (component: ReactNode, options: BottomSheetOptions) => {
        if (options.nestedSheet) {
            setData((current) => current.concat({ component, options }));
        } else {
            setData([{ component, options }]);
        }
        setActiveBottomSheet(options.id);
    };

    /**
     * Dismiss bottom sheet
     *
     * dismissId (optional)
     * - undefined dismisses the last sheet
     * - "*" dismisses all sheets
     * - set to specific ID to dismiss specific sheet and all following sheets
     */
    const dismissBottomSheet = useCallback(
        (dismissId?: string) => {
            setData((current) => {
                const dismissFromIndex = dismissId
                    ? dismissId === "*"
                        ? 0
                        : current.findIndex((sheet) => sheet.options.id === dismissId)
                    : current.length - 1;
                const removedData = current.slice(dismissFromIndex).reverse();
                const newData = current.slice(0, dismissFromIndex);

                removedData.forEach((rd) => {
                    if (rd.options.onWillDismiss) {
                        rd.options.onWillDismiss();
                    }
                });

                if (newData.length === 0) {
                    togglePopUp(undefined);
                    setActiveBottomSheet("");
                } else {
                    setActiveBottomSheet(newData[newData.length - 1].options.id);
                }

                return newData;
            });
        },
        [togglePopUp]
    );

    const showDismissHandleClass =
        currentOptions?.showDismissHandle === false ? "bs-hide-handle" : undefined;

    const closeButtonHandler = useCallback(() => {
        // If closeButtonToBack is true, close the current sheet, otherwise close all
        if (currentOptions?.closeButtonToBack) {
            dismissBottomSheet();
        } else {
            dismissBottomSheet("*");
        }
    }, [currentOptions, dismissBottomSheet]);

    const headerComponent = useMemo(
        () => (
            <div className={S.header}>
                {/** Header Content */}
                <div className={S.headerContent}>
                    {data.length > 1 && !currentOptions?.hideBackButton && (
                        /** Back Button */
                        <button
                            className={cn(S.button, S.backButton)}
                            onClick={() => dismissBottomSheet()}
                        >
                            <DA_Icon name={DA_IconNames.ChevronLeft} />
                        </button>
                    )}

                    {currentOptions?.header ||
                        (currentOptions?.headerTitle ? (
                            <DA_Title h4>{currentOptions.headerTitle}</DA_Title>
                        ) : null)}
                </div>

                {/** Close Button */}
                {!currentOptions?.hideCloseButton && (
                    <button className={cn(S.button, S.closeButton)} onClick={closeButtonHandler}>
                        <DA_Icon name={DA_IconNames.Close} />
                    </button>
                )}
            </div>
        ),
        [data, dismissBottomSheet, currentOptions, closeButtonHandler]
    );

    useEffect(() => {
        if (!isMobileOrTablet) {
            dismissBottomSheet();
        }
    }, [isMobileOrTablet, dismissBottomSheet]);

    return (
        <BottomSheetContext.Provider
            value={{
                presentBottomSheet,
                dismissBottomSheet,
                activeBottomSheet,
            }}
        >
            {/** Children */}
            {children}

            {/** Bottom Sheet */}
            <Suspense>
                <BottomSheet
                    className={cn("bs-wrapper", showDismissHandleClass, currentOptions?.className)}
                    open={data.length > 0}
                    sibling={
                        currentOptions?.nonBlocking ? null : (
                            /** This is for blocking click-through on iOS */
                            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                            <div
                                data-rsbs-backdrop="true"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    dismissBottomSheet();
                                }}
                            />
                        )
                    }
                    blocking={false}
                    onDismiss={dismissBottomSheet}
                    defaultSnap={0}
                    snapPoints={
                        currentOptions?.snapPoints
                            ? Array.isArray(currentOptions.snapPoints)
                                ? (state) =>
                                      (currentOptions.snapPoints as number[])
                                          .map(
                                              (point) =>
                                                  point + state.headerHeight + state.footerHeight
                                          )
                                          .concat([state.maxHeight])
                                : currentOptions.snapPoints
                            : (state) => state.maxHeight
                    }
                    header={headerComponent}
                    footer={<div className="bs-footer" />}
                >
                    {data.map((sheet, sheetIndex) => (
                        <div
                            key={sheet.options.id}
                            className={cn(
                                sheet.options.contentPadding && S.contentPadding,
                                S.contentPaddingLarge
                            )}
                            hidden={sheetIndex < data.length - 1}
                            aria-hidden={sheetIndex < data.length - 1}
                        >
                            {sheet.component}
                        </div>
                    ))}
                </BottomSheet>
            </Suspense>
        </BottomSheetContext.Provider>
    );
};

export const useBottomSheet = (): BottomSheetHook => useContext(BottomSheetContext);
