import React, { useEffect } from "react";
import { DA_Modal } from "@danishagro/shared/src/components/molecules/Modal/Modal.component";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { MessageType } from "@danishagro/shared/src/content/message/messageEnums";
import { ModalSize } from "@danishagro/shared/src/contexts/modal.context";
import S from "./modalMessage.module.scss";

type Message = {
    id: number;
    headline: string;
    content: string;
    type: string;
};

// Define the type for the component props
type ModalMessageProps = {
    currentMessage: Message | null;
    showModal: (node: React.ReactNode, options?: object) => void;
    isModalShowing: boolean;
    handleModalClose: (id: number) => void;
    closeModal: () => void;
    getDictionaryString: (key: string) => string;
};

export const DA_ModalMessage = ({
    currentMessage,
    showModal,
    isModalShowing,
    handleModalClose,
    closeModal,
    getDictionaryString,
}: ModalMessageProps) => {
    useEffect(() => {
        if (currentMessage && !isModalShowing && currentMessage.type === MessageType.Modal) {
            showModal(
                <DA_Modal title={currentMessage.headline}>
                    <>
                        <span
                            className={S.modalMessageContent}
                            dangerouslySetInnerHTML={{
                                __html: currentMessage.content,
                            }}
                        />
                        <DA_Button
                            className={S.closeModalBtn}
                            title={getDictionaryString("OK")}
                            onClick={() => {
                                handleModalClose(currentMessage.id);
                                closeModal();
                            }}
                        />
                    </>
                </DA_Modal>,
                {
                    onClose: () => handleModalClose(currentMessage.id),
                    size: ModalSize.MD,
                }
            );
        }
    }, [
        showModal,
        currentMessage,
        handleModalClose,
        isModalShowing,
        closeModal,
        getDictionaryString,
    ]);

    // Render nothing as the modal is handled through the showModal function
    return null;
};
