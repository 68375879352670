import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { DA_NavigationPageSimple } from "@danishagro/shared/src/interfaces/navigationPage.interface";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { Page } from "@interfaces/page.interface";

interface PageHook {
    allPages: Omit<DA_NavigationPageSimple, "children">[];
    pageTree: DA_NavigationPageSimple[];
    currentPathDetails: ({ index: number } & Omit<DA_NavigationPageSimple, "children">)[];
    pageIsLoading: boolean;
    setPageIsLoading: (state: boolean) => void;

    page: Page;
    update: (partial: Page) => void;
}

const PageContext = React.createContext<PageHook>({
    allPages: [],
    pageTree: [],
    currentPathDetails: [],
    pageIsLoading: false,
    setPageIsLoading: () => null,

    page: null as Page,
    update: () => null,
});

type Props = {
    children: React.ReactNode;
    initial: {
        page: Page;
    };
};

const createPageArray = (pageArray: DA_NavigationPageSimple[]): DA_NavigationPageSimple[] => {
    const pages = [];

    (pageArray || []).map(({ children, ...current }) => {
        pages.push(current);

        if (children && children.length) {
            pages.push(...createPageArray(children));
        }
    });

    return pages;
};

export const PageProvider = ({ children, initial }: Props): JSX.Element => {
    const [page, setPage] = useState<Page>(initial.page);
    const [pageIsLoading, setPageIsLoading] = useState(false);
    const [currentPathDetails, setCurrentPathDetails] = useState([]);
    const { navigationObj } = useAppData();
    const { pathname } = useLocation();
    const allPages: DA_NavigationPageSimple[] = createPageArray(navigationObj);

    const update = useCallback(
        (partial: Page) => {
            setPage(partial);
        },
        [setPage]
    );

    const explorePath = useCallback((pathNames: string[], tree: DA_NavigationPageSimple[]) => {
        const indexes = [];
        tree.find(({ children, ...pageData }, index) => {
            if (pageData?.path) {
                const pathEnd = pageData.path.match(/\/?[^/]+\/?$/) || [pageData.path];
                if (pathEnd?.length && pathEnd[0].replace(/^\/|\/$/g, "") === pathNames[0]) {
                    indexes.push({ index, ...pageData });
                    if (typeof pathNames[1] === "string" && children) {
                        indexes.push(...explorePath(pathNames.slice(1), children));
                    }
                    return true;
                }
            }
        });
        return indexes;
    }, []);

    useEffect(() => {
        const pathNames = pathname.replace(/^\/|\/$/, "").split("/");
        const scopeIndexes = explorePath(pathNames, navigationObj);
        setCurrentPathDetails(scopeIndexes);
    }, [pathname, explorePath, navigationObj]);

    return (
        <PageContext.Provider
            value={{
                allPages,
                pageTree: navigationObj,
                currentPathDetails,
                pageIsLoading,
                setPageIsLoading,
                page,
                update,
            }}
        >
            {children}
        </PageContext.Provider>
    );
};

export const usePage = (): PageHook => React.useContext(PageContext);
