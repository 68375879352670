import React from "react";
import { DA_BasicLink } from "@danishagro/shared/src/components/atoms/BasicLink/BasicLink.component";
import { DA_Title } from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { ImageViewer } from "src/components/ImageViewer/ImageViewer.component";
import { getAbsoluteUrl } from "@helpers/getAbsoluteUrl.helper";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { LatestEventsProps } from "./LatestEvents.interface";
import { useFilteredItems } from "./hooks/useItems.hook";
import S from "./LatestEvents.module.scss";

const LatestEvents = (props: LatestEventsProps) => {
    const { items, viewState } = useFilteredItems(3, props.currentUrl);
    const { getDictionaryString } = useTranslations();

    const filteredItems = items?.filter((item) => item.url !== props.currentUrl);
    return (
        <>
            {viewState === "DONE" ? (
                <div className={S.list}>
                    <DA_Title h4 noMargin>
                        {getDictionaryString("Events.LatestEvents")}
                    </DA_Title>

                    {filteredItems?.[0] &&
                        filteredItems.map((item, key) => {
                            return (
                                <div key={key}>
                                    {/* //Just show latest events if the event has content  */}
                                    {item.hasContent ? (
                                        <DA_BasicLink className={S.link} key={key} href={item.url}>
                                            {/** Image */}
                                            {item.thumbnail ? (
                                                <div className={S.image}>
                                                    <ImageViewer
                                                        className={S.image}
                                                        src={getAbsoluteUrl(
                                                            item.thumbnail?.url,
                                                            "UMBRACO"
                                                        )}
                                                    />
                                                </div>
                                            ) : null}

                                            <div className={S.content}>
                                                {/** Date */}
                                                {item?.startDate && <span>{item?.startDate}</span>}

                                                {/** Headline */}
                                                <strong className={S.headline}>{item.title}</strong>
                                            </div>
                                        </DA_BasicLink>
                                    ) : null}
                                </div>
                            );
                        })}
                </div>
            ) : null}
        </>
    );
};

export default LatestEvents;
