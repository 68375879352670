import { useCallback } from "react";
import { B2B_SITE_ORIGIN } from "../constants/urlRoot.constants";
import { useAppData } from "../contexts/appData.context";
import { DA_SearchResults } from "../interfaces/search.interface";
import { GET_CMS_SITE_ORIGIN } from "../helpers/urlRoot.helper";

type SearchFunction = (
    searchTerm: string,
    options?: { maxProducts?: number },
    fetchOptions?: RequestInit
) => Promise<DA_SearchResults>;

export const useSearch = () => {
    const { currentSite } = useAppData();

    return useCallback<SearchFunction>(
        async (searchTerm, options?, fetchOptions?) => {
            let request = undefined;
            const cultureCode =
                Array.isArray(globalThis?.cultures) && globalThis.cultures[0]?.alias;

            if (currentSite === "CMS") {
                request = await fetch(
                    `${GET_CMS_SITE_ORIGIN("ABSOLUTE")}/search?rootNodeId=${
                        globalThis.rootNodeId
                    }&term=${searchTerm.trim()}&pageSize=${
                        options?.maxProducts || 10
                    }&cultureCode=${cultureCode || "da-dk"}`, // Fallback cultureCode if cultureCode from globalThis is undefined
                    {
                        ...fetchOptions,
                        credentials: "include",
                    }
                );
            } else {
                request = await fetch(
                    `${B2B_SITE_ORIGIN}/api/search?term=${searchTerm.trim()}&pageSize=${
                        options?.maxProducts || 10
                    }`,
                    {
                        ...fetchOptions,
                        credentials: "include",
                    }
                );
            }

            const response = await request.json();

            return {
                b2b: currentSite !== "CMS" ? response : undefined,
                cms: currentSite === "CMS" ? response : undefined,
            };
        },
        [currentSite]
    );
};
