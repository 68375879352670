import React, { useMemo } from "react";
import { isProduction } from "@helpers/isProduction.helper";
import { Helmet } from "react-helmet";
import { DynamicBlock } from "src/blocks/DynamicBlock.component";
import { DA_Container } from "@danishagro/shared/src/components/atoms/Container/Container.component";
import SocialMediaSharing from "src/blocks/components/SocialMediaSharing/SocialMediaSharing.component";
import { DA_Breadcrumb } from "@danishagro/shared/src/components/molecules/Breadcrumb/Breadcrumb.component";
import { usePage } from "@contexts/page.context";
import { TestimonialsPageProps } from "./TestimonialsPage.interface";
import { MoreEmployees } from "./components/MoreEmployees/MoreEmployees.component";
import { useItems } from "./components/MoreEmployees/hooks/useItems.hook";
import S from "./TestimonialsPage.module.scss";

export const TestimonialsPage = (props: TestimonialsPageProps) => {
    const { currentPathDetails } = usePage();
    const { items } = useItems(3, props.id);

    const robotsIndexFollow = useMemo(() => {
        if (!isProduction()) {
            return "noindex, nofollow";
        }

        return `${props.props.allowIndexing === true ? "index" : "noindex"},
                ${props.props.allowFollowing === true ? "follow" : "nofollow"}`;
    }, [props.props?.allowFollowing, props.props?.allowIndexing]);

    return (
        <main className={S.wrapper}>
            <Helmet>
                <title>{props?.props?.metaTitle || props?.name}</title>
                <meta name="description" content={props?.props?.metaDescription} />
                <meta name="robots" content={robotsIndexFollow} />
            </Helmet>

            {/* Breadcrumbs */}
            <DA_Container noVerticalPadding>
                <DA_Breadcrumb
                    breadcrumbItems={[
                        ...currentPathDetails.map(({ name, path }) => ({
                            title: name,
                            href: path,
                        })),
                    ]}
                    lastIsLink={true}
                />
            </DA_Container>

            {/* Hero */}
            {props?.props?.hero?.[0]
                ? props?.props?.hero?.map((block, index) => <DynamicBlock key={index} {...block} />)
                : null}

            <DA_Container className={S.container} noVerticalPadding>
                <main className={S.main}>
                    <div className={S.toolbar}>
                        <div className={S.tags}>
                            {props?.props?.tags.map((tag, index) => (
                                <div className={S.tag} key={index}>
                                    {tag}
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Blocks */}
                    <div className={S.content}>
                        {props?.props?.modules?.[0]
                            ? props?.props?.modules?.map((block, index) => (
                                  <DynamicBlock key={index} {...block} compact />
                              ))
                            : null}
                    </div>

                    <SocialMediaSharing />
                </main>
                {/** Related Testimonials */}
                {/* TODO: Show related Testimonials based on tag */}
                <aside className={S.aside}>
                    {items && items.siblings && items.siblings.length > 0 && (
                        <MoreEmployees items={items} />
                    )}
                </aside>
            </DA_Container>
        </main>
    );
};
