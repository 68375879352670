import React from "react";
import {
    B2B_SITE_ORIGIN,
    CMS_SITE_ORIGIN,
    MYFARM_SITE_ORIGIN,
} from "@danishagro/shared/src/constants/urlRoot.constants";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import S from "./ServiceMenu.module.scss";

export const DA_ServiceMenu = () => {
    const { currentSite, showOnlyUniversalMenu } = useAppData();

    const cmsActiveClass = currentSite === "CMS" ? S.isActive : undefined;
    const b2bActiveClass = currentSite === "B2B" ? S.isActive : undefined;
    const myfarmActiveClass = currentSite === "MYFARM" ? S.isActive : undefined;

    return (
        <ul className={S.list}>
            {!showOnlyUniversalMenu && globalThis.apiGatewayUrl && (
                <>
                    <li className={S.item}>
                        <a href={`${CMS_SITE_ORIGIN}/`} className={cn(S.link, cmsActiveClass)}>
                            Viden & Værdi
                        </a>
                    </li>
                    <li className={S.item}>
                        <a href={`${B2B_SITE_ORIGIN}/`} className={cn(S.link, b2bActiveClass)}>
                            Webshop
                        </a>
                    </li>
                    <li className={S.item}>
                        <a
                            href={`${MYFARM_SITE_ORIGIN}/`}
                            className={cn(S.link, myfarmActiveClass)}
                        >
                            Mit Landbrug
                        </a>
                    </li>
                </>
            )}

            {Array.isArray(globalThis?.universalMenuArr)
                ? globalThis?.universalMenuArr?.map((menu, index) => {
                      return (
                          <li key={index} className={S.item}>
                              <a href={menu.url} target={menu.target || "_self"} className={S.link}>
                                  {menu.name}
                              </a>
                          </li>
                      );
                  })
                : null}
        </ul>
    );
};
