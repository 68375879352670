import { useCallback, useEffect, useState } from "react";
import { getAbsoluteUrl } from "@helpers/getAbsoluteUrl.helper";
import { LatestEventsResponseItem } from "../LatestEvents.interface";

export const useFilteredItems = (take = 10, excludeUrl?: string) => {
    const [items, setItems] = useState<LatestEventsResponseItem[]>([]);
    const [viewState, setViewState] = useState<"INIT" | "DONE" | "ERROR">("INIT");

    const fetchItems = useCallback(async () => {
        const cultureCode = globalThis?.cultures[0]?.alias;
        try {
            const url = getAbsoluteUrl("/geteventlist", "SPA", {
                rootNodeId: globalThis.rootNodeId,
                pageNumber: "1",
                pageSize: take.toString(),
            });
            const request = await fetch(`${url}&cultureCode=${cultureCode}`);

            if (request.ok) {
                const response = await request.json();
                return response;
            }
            throw new Error("Failed to fetch");
        } catch (error) {
            throw new Error("Failed to fetch");
        }
    }, [take]);

    const getItems = useCallback(async () => {
        try {
            let response = await fetchItems();
            // Optionally filter items if excludeUrl is provided
            if (excludeUrl) {
                response = response.filter((item) => item.url !== excludeUrl);
            }
            setItems(response);
            setViewState("DONE");
        } catch (error) {
            setViewState("ERROR");
        }
    }, [fetchItems, excludeUrl]);

    useEffect(() => {
        getItems();
    }, [getItems]);

    return {
        items,
        viewState,
    };
};
