import React, { useEffect, useRef } from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_Icon, DA_IconNames } from "../../atoms/Icon/Icon.component";
import { DA_Title } from "../../atoms/Title/Title.component";
import { useModal } from "../../../contexts/modal.context";
import { DA_ContentBox } from "../../atoms/ContentBox/ContentBox.component";
// eslint-disable-next-line css-modules/no-unused-class
import S from "./Modal.module.scss";

export interface DA_ModalProps {
    title: React.ReactNode;
    closeLabel?: string;
    children?: React.ReactNode;
    hideCloseButton?: boolean;
    className?: string;
}

export const DA_Modal = ({
    title,
    closeLabel,
    children,
    hideCloseButton,
    className,
}: DA_ModalProps): JSX.Element => {
    const { closeModal } = useModal();
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Handler to close modal if a non _blank link is clicked
        const handleLinkClick = (e) => {
            if (e.target.tagName === "A" && e.target.target !== "_blank") {
                closeModal();
            }
        };

        // Attach event listener to the modal body
        const modalBody = ref.current;
        modalBody.addEventListener("click", handleLinkClick);

        // Cleanup function to remove the event listener
        return () => {
            modalBody.removeEventListener("click", handleLinkClick);
        };
    }, [closeModal]);

    return (
        <DA_ContentBox className={cn(S.modal, className && S[className])}>
            <header className={S.header}>
                <section className={S.top}>
                    <div className={S.title}>
                        <DA_Title h3 noMargin>
                            {title}
                        </DA_Title>
                    </div>
                    {!hideCloseButton && (
                        <button
                            aria-label={closeLabel}
                            type="button"
                            onClick={closeModal}
                            className={S.closeButton}
                        >
                            <DA_Icon name={DA_IconNames.Close} className={S.icon} />
                        </button>
                    )}
                </section>
            </header>
            <div className={S.body} ref={ref}>
                {children}
            </div>
        </DA_ContentBox>
    );
};
