import React from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_LinkProps } from "@danishagro/shared/src/interfaces/linkProps.interface";
import { useSiteHeader } from "@danishagro/shared/src/contexts/siteHeader.context";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_NavigationMobile } from "../../NavigationMobile.component";
import S from "./Trigger.module.scss";

interface DA_NavigationMobileTriggerProps {
    customerServiceLinks?: DA_LinkProps[];
}

export const DA_NavigationMobileTrigger = (props: DA_NavigationMobileTriggerProps) => {
    const { toggleMobileNav, showMobileNav } = useSiteHeader();
    const isActiveClass = showMobileNav === true ? S.isActive : undefined;
    const { getDictionaryString } = useTranslations();

    return (
        <>
            {/** Trigger */}
            <div className={cn(S.wrapper, isActiveClass)}>
                <button
                    type="button"
                    onClick={toggleMobileNav}
                    className={S.button}
                    aria-label={getDictionaryString("accessibilityMobileMenu")}
                >
                    <div className={S.burgerIcon}>
                        <span />
                        <span />
                        <span />
                    </div>
                </button>
            </div>

            {/** Portal: Navigation */}
            <DA_NavigationMobile {...props} isOpen={showMobileNav} />
        </>
    );
};
