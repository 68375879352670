import { format } from "date-fns";

const useFormattedCultureDate = (date) => {
    const dateCulture = globalThis.siteSettingsObj?.dateCulture;

    if (!date) return null; // Returner null

    // Use "dd.MM.yyyy" if dateCulture not used
    const dateFormat =
        dateCulture === "da-dk" ? "dd.MM.yyyy" : dateCulture ? "MM.dd.yyyy" : "dd.MM.yyyy";
    return format(new Date(date), dateFormat);
};

export default useFormattedCultureDate;
